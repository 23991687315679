import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getRtItemList } from '../../../../../ducks/actions'
import { editRtItem, addRtItem, removeRtItem, bulkRemoveRtItems } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues, clearErrors } = useForm();
  const rtItems = useSelector((state) => state.systems.rtItems);
  const meta = useSelector((state) => state.systems.rtItemsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const colName = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Remark',
      dataIndex: 'remark_code',
      key: 'remark_code',
      sorter: true,
      className: 'enable-click',
      render: (text, record) => <span>{text} {record.remark_desc}</span>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Remark Type Item"
          btnAction1={() => {
            setVisible(true);
            setValue('id', record.id);
            setValue('remark', record.remark_id ? { label: `${record.remark_code} ${record.remark_desc}`, value: record.remark_id } : '');
          }}
          onRemove={allowedDelete && onRemove}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'remark',
      label: 'Remark',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: _.map(meta?.remarks, (e) => ({ label: `${e.code} ${e.desc}`, value: e.id })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getRtItemList(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('remark', '');
    clearErrors();
  }

  const btnList = [
    {
      title: 'Are you sure to remove the selected RT Item(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Item',
      classes: 'attendance-success',
      action: () => addNew(),
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getRtItemList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getRtItemList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        remark_code: search?.remark_code
      };
      setSearchVal(searching);
      dispatch(getRtItemList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getRtItemList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      remark_id: val?.remark?.value,
      remark_type_item_id: val?.id,
      remark_type_id: id
    }
    await (val?.id ? editRtItem : addRtItem)(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`RT Item ${val?.id ? 'Updated' : 'Added'} Successfully!`);
        setVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getRtItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onRemove = async (recordId) => {
    setLoad(true);

    const payload = {
      remark_type_item_id: recordId,
      remark_type_id: id,
      status_event: 'remove'
    }
    await removeRtItem(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`RT Item Removed Successfully!`);
        setVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getRtItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'Add'} RT Item
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('remark', '');
              clearErrors();
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('remark', '');
      clearErrors();
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setValue('id', record.id);
          setValue('remark', record.remark_id ? { label: `${record.remark_code} ${record.remark_desc}`, value: record.remark_id } : '');
          setVisible(true);
        }
      },
    };
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      remark_type_id: id,
      rt_items: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveRtItems(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Remark Type Items has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getRtItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={''} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onSearch={onSearch}
            Search={Search}
            onRow={onClickRow}
            rowSelection={allowedEdit && rowSelection}
            ListData={rtItems?.list?.map((x, idx) => ({ ...x, key: x.id, index: idx + 1 }))}
            onChange={onTableChange}
            ListCol={colName}
            pagination={{
              total: rtItems?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}