import * as action_types from './constants';

const initialState = {
  userProfile: {}
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.USER_PROFILE:
      return { ...state, userProfile: data };
    

    default:
      return state;
  }
};
