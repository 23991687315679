import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { getCurrencyDetail } from "../../ducks/actions";
import { editCurrency } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm();
  const data = useSelector((state) => state.systems.currency);
  const allowedEdit = allowed([AllRoles.SYSTEM.CURRENCY], 'write');
  const allowedDelete = allowed([AllRoles.SYSTEM.CURRENCY], 'delete');

  useEffect(() => {
    dispatch(getCurrencyDetail(id, props.setLoading));
  }, []);

  useEffect(() => {
    if(data && 'id' in data) {
      setValue('code', data.code);
      setValue('symbol', data.symbol);
      setValue('reference', data.reference);
    }
  }, [data])

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      code: val.code,
      symbol: val.symbol,
      currency_id: id
    }
    await editCurrency(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Currency updated successfully!");
        setTimeout(() => navigate("/currency"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>Edit Currency - {data?.code}</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                {
                  allowedEdit &&
                  <Button size="large" htmlType='submit' className='green-btn'>Save</Button>
                }
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/currency')}>{'<'} Back</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <InputForm
          control={control}
          errors={errors}
          allowedEdit={allowedEdit}
          allowedDelete={allowedDelete}
        />
      </Row>
    </Form>
  )
}