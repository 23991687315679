import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getVendors = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/vendors/list`, {
      q: JSON.stringify({
        code_or_name_cont: search?.code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.VENDOR_LIST,
      data: data
    });
  };
};

export const getVendor = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/vendors/get`, {
      vendor_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.VENDOR_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getVendorOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/vendors/option`);
    load && load(false);
    dispatch({
      type: action_types.VENDOR_OPTION,
      data: data
    });
  };
};

export const getPurchaseOrderList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/purchase-orders/list`, {
      q: JSON.stringify({
        reference_cont: search?.reference,
        location_id_eq: search?.outlet,
        status_eq: search?.status,
        vendor_id_eq: search?.vendor,
        date_eq: search?.date,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.PURCHASE_ORDER_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getPurchaseOrder = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/purchase-orders/get`, {
      purchase_order_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.PURCHASE_ORDER_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const reloadPurchaseOrder = (data, meta) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.PURCHASE_ORDER_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getPurchaseOrderOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/purchase-orders/option`);
    load && load(false);
    dispatch({
      type: action_types.PURCHASE_ORDER_OPTION,
      data: data
    });
  };
};

export const getVendorProducts = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/vendors/vendor-product-list`, {
      vendor_id: id,
      q: JSON.stringify({
        product_code_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.SUPPLIED_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getPoItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/purchase-orders/purchase-order-item-list`, {
      purchase_order_id: id,
      q: JSON.stringify({
        name_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.PO_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getGoodReceiveList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-ins/list`, {
      q: JSON.stringify({
        reference_or_source_desc_cont: search?.reference,
        location_id_eq: search?.outlet,
        status_eq: search?.status,
        date_eq: search?.date,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.GOOD_RECEIVE_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getGoodReceiveDetail = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-ins/get`, {
      stock_in_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.GOOD_RECEIVE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getGoodReceiveOption = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/stock-ins/option`);
    load && load(false);
    dispatch({
      type: action_types.GOOD_RECEIVE_OPTION,
      data: data
    });
  };
};

export const getGrItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-ins/stock-in-item-list`, {
      stock_in_id: id,
      q: JSON.stringify({
        source_name_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.GR_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getTransferOutList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/transfer-outs/list`, {
      q: JSON.stringify({
        reference_cont: search?.reference,
        origin_id_or_destination_id_eq: search?.outlet,
        status_eq: search?.status,
        date_eq: search?.date,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.TRANSFER_OUT_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getTransferOut = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/transfer-outs/get`, {
      transfer_out_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.TRANSFER_OUT_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const reloadTransferOut = (data, meta) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.TRANSFER_OUT_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getTransferOutOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/transfer-outs/option`);
    load && load(false);
    dispatch({
      type: action_types.TRANSFER_OUT_OPTION,
      data: data
    });
  };
};

export const getToItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/transfer-outs/transfer-out-item-list`, {
      transfer_out_id: id,
      q: JSON.stringify({
        code_or_desc_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.TO_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const patchToItemList = (response, data, meta) => {
  let newItems = patchItems(response, data);
  return async (dispatch) => {
    dispatch({
      type: action_types.TO_ITEM_LIST,
      data: newItems,
      meta: meta
    });
  };
};

export const getTransferInList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/transfer-ins/list`, {
      q: JSON.stringify({
        reference_cont: search?.reference,
        origin_id_or_destination_id_eq: search?.outlet,
        status_eq: search?.status,
        date_eq: search?.date,
        receive_date_eq: search?.receive_date,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.TRANSFER_IN_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getTransferIn = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/transfer-ins/get`, {
      transfer_in_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.TRANSFER_IN_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const reloadTransferIn = (data, meta) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.TRANSFER_IN_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getTransferInOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/transfer-ins/option`);
    load && load(false);
    dispatch({
      type: action_types.TRANSFER_IN_OPTION,
      data: data
    });
  };
};

export const getTrItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/transfer-ins/transfer-in-item-list`, {
      transfer_in_id: id,
      q: JSON.stringify({
        code_or_desc_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.TR_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getStockWastageList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/written-offs/list`, {
      q: JSON.stringify({
        reference_cont: search?.reference,
        location_id_eq: search?.outlet,
        status_eq: search?.status,
        date_eq: search?.date,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_WASTAGE_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getStockWastage = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/written-offs/get`, {
      written_off_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_WASTAGE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const reloadStockWastage = (data, meta) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.STOCK_WASTAGE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getStockWastageOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/written-offs/option`);
    load && load(false);
    dispatch({
      type: action_types.STOCK_WASTAGE_OPTION,
      data: data
    });
  };
};

export const getSwItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/written-offs/written-off-item-list`, {
      written_off_id: id,
      q: JSON.stringify({
        code_or_desc_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.SW_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const patchSwItemList = (response, data, meta) => {
  let newItems = patchItems(response, data);
  return async (dispatch) => {
    dispatch({
      type: action_types.SW_ITEM_LIST,
      data: newItems,
      meta: meta
    });
  };
};

export const getStockAdjustmentList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/adjustments/list`, {
      q: JSON.stringify({
        reference_cont: search?.reference,
        location_id_eq: search?.outlet,
        status_eq: search?.status,
        date_eq: search?.date,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_ADJUSTMENT_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getStockAdjustment = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/adjustments/get`, {
      adjustment_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_ADJUSTMENT_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const reloadStockAdjustment = (data, meta) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.STOCK_ADJUSTMENT_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getStockAdjustmentOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/adjustments/option`);
    load && load(false);
    dispatch({
      type: action_types.STOCK_ADJUSTMENT_OPTION,
      data: data
    });
  };
};

export const getSaItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/adjustments/adjustment-item-list`, {
      adjustment_id: id,
      q: JSON.stringify({
        code_or_desc_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.SA_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const patchSaItemList = (response, data, meta) => {
  let newItems = patchItems(response, data);
  return async (dispatch) => {
    dispatch({
      type: action_types.SA_ITEM_LIST,
      data: newItems,
      meta: meta
    });
  };
};

export const getStockTemplateList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-templates/list`, {
      q: JSON.stringify({
        code_or_desc_cont: search?.code,
        stock_template_type_eq: search?.stock_template_type,
        status_eq: search?.status,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_TEMPLATE_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getStockTemplate = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-templates/get`, {
      stock_template_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_TEMPLATE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getStockTemplateOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/stock-templates/option`);
    load && load(false);
    dispatch({
      type: action_types.STOCK_TEMPLATE_OPTION,
      data: data
    });
  };
};

export const reloadStockTemplate = (data, meta) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.STOCK_TEMPLATE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getStItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-templates/stock-template-item-list`, {
      stock_template_id: id,
      q: JSON.stringify({
        desc_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.ST_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getStockTakeList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-takes/list`, {
      q: JSON.stringify({
        reference_cont: search?.reference,
        location_id_eq: search?.outlet,
        status_eq: search?.status,
        date_eq: search?.date,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_TAKE_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getStockTake = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-takes/get`, {
      stock_take_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_TAKE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const reloadStockTake = (data, meta) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.STOCK_TAKE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getStockTakeOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/stock-takes/option`);
    load && load(false);
    dispatch({
      type: action_types.STOCK_TAKE_OPTION,
      data: data
    });
  };
};

export const getStakeItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-takes/stock-take-item-list`, {
      stock_take_id: id,
      q: JSON.stringify({
        desc_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_TAKE_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getStockConversionList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-conversions/list`, {
      q: JSON.stringify({
        reference_cont: search?.reference,
        location_id_eq: search?.outlet,
        status_eq: search?.status,
        date_eq: search?.date,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_CONVERSION_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getStockConversion = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-conversions/get`, {
      stock_conversion_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_CONVERSION_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const reloadStockConversion = (data, meta) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.STOCK_CONVERSION_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getStockConversionOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/stock-conversions/option`);
    load && load(false);
    dispatch({
      type: action_types.STOCK_CONVERSION_OPTION,
      data: data
    });
  };
};

export const getConversionItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/stock-conversions/stock-conversion-item-list`, {
      stock_conversion_id: id,
      q: JSON.stringify({
        desc_cont: search?.product_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_CONVERSION_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const patchConversionItemList = (response, data, meta) => {
  let newItems = patchItems(response, data);
  return async (dispatch) => {
    dispatch({
      type: action_types.STOCK_CONVERSION_ITEM_LIST,
      data: newItems,
      meta: meta
    });
  };
};

export const getStockMovementList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/inventory-entries/list`, {
      q: JSON.stringify({
        location_id_eq: search?.location,
        product_id_eq: search?.product,
        report_date_gteq: search?.start_date,
        report_date_lteq: search?.end_date,
        reference_cont: search?.reference,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.STOCK_MOVEMENT_LIST,
      data: data,
      meta: meta
    });
  };
};

function patchItems(response, data) {
  let temp = { ...response };
  let totalCount = response.total_count;
  let tempList = [...temp?.list];
  const index = tempList.findIndex((item) => data.id === item.id);
  if (index >= 0) {
    tempList[index] = data;
  } else {
    tempList.push(data);
    totalCount += 1;
  }

  let newItems = { ...response, list: tempList, total_count: totalCount };
  return newItems;
}