import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getStItemList } from '../../../../../ducks/actions'
import { editStItem, addStItem, removeStItem } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, data, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues, clearErrors } = useForm();
  const poItems = useSelector((state) => state.inventory.stItems);
  const meta = useSelector((state) => state.inventory.stItemsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const [uoms, setUoms] = useState([]);

  const colName = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Item',
      dataIndex: 'desc',
      key: 'desc',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'UOM',
      dataIndex: 'uom_code',
      key: 'uom_code',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Stock Template Item"
          btnAction1={() => {
            setVisible(true);
            setValue('id', record.id);
            setValue('item', record.product_id ? { label: record?.desc, value: record.product_id } : '');
            setValue('uom', record.uom_id ? { label: record?.uom_code, value: record.uom_id } : '');
            let product = meta?.products?.find(x => x.id === record.product_id);
            setUoms(product?.product_uoms?.map(r => ({ label: r.code, value: r.id })));
          }}
          onRemove={allowedDelete && onRemove}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'item',
      label: 'Item',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: _.map(meta?.products, (e) => ({ label: `${e.code} ${e.name}`, value: e.id, product_uoms: e.product_uoms })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit,
      onChange: (e) => {
        if(data?.stock_template_type === "stock_wastage") {
          let baseUom = e.product_uoms?.find(x => x.is_base_uom === "1");
          setValue('uom', baseUom ? { label: baseUom?.code, value: baseUom?.id } : '');
        } else if (data?.stock_template_type === "stock_take") {
          let baseUom = e.product_uoms?.find(x => x.is_inventory_uom === "1");
          setValue('uom', baseUom ? { label: baseUom?.code, value: baseUom?.id } : '');
        }
        setUoms(e.product_uoms?.map(r => ({ label: r.code, value: r.id })));
      }
    },
    {
      name: 'uom',
      label: 'UOM',
      req: true,
      placeholder: 'Please select',
      class: 'default-select',
      type: 'select',
      options: uoms,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getStItemList(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('item', '');
    setValue('uom', '');
    setUoms();
    clearErrors();
  }

  const btnList = [
    {
      text: '+ New Item',
      classes: 'attendance-success',
      action: () => addNew(),
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getStItemList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getStItemList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }
  
  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        product_code: search?.product_code
      };
      setSearchVal(searching);
      dispatch(getStItemList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getStItemList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      uom_id: val?.uom?.value,
      product_id: val?.item?.value,
      stock_template_item_id: val?.id,
      stock_template_id: id
    }
    await (val?.id ? editStItem : addStItem)(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(`ST Item ${val?.id ? 'Updated' : 'Added'} Successfully!`);
        setVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getStItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onRemove = async (recordId) => {
    setLoad(true);

    const payload = {
      stock_template_item_id: recordId,
      stock_template_id: id,
      status_event: 'remove'
    }
    await removeStItem(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(`Stock Template Item Removed Successfully!`);
        setVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getStItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'Add'} ST Item
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('item', '');
              setValue('uom', '');
              setUoms();
              clearErrors();
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('uom', '');
      setValue('item', '');
      setUoms();
      clearErrors();
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")){
          setValue('id', record.id);
          setValue('item', record.product_id ? { label: record.desc, value: record.product_id } : '');
          setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : '');
          let product = meta?.products?.find(x => x.id === record.product_id);
          setUoms(product?.product_uoms?.map(r => ({ label: r.code, value: r.id })));
          setVisible(true);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={''} btnList={allowedEdit && btnList} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onSearch={onSearch}
            Search={Search}
            onRow={onClickRow}
            ListData={poItems?.list?.map((x, idx) => ({ ...x, key: x.id, index: idx + 1}))}
            onChange={onTableChange}
            ListCol={colName}
            pagination={{
              total: poItems?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}