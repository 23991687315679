import React from 'react';
import { Tabs } from 'antd';
import * as TabCards from './tabList';
import StickyBox from 'react-sticky-box';

export default (props) => {
  const { setActiveTab, oneTab, searchParams } = props;
  const defaultActive = searchParams?.get('t');

  const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox
      offsetTop={20}
      offsetBottom={20}
      style={{
        zIndex: 1,
      }}
    >
      <DefaultTabBar
        {...props}
        style={{
          backgroundColor: "#f5f5f5"
        }}
      />
    </StickyBox>
  );

  const tabs = [
    {
      name: "Details",
      Comp: 'Detail',
    },
    {
      name: "GR Item",
      Comp: 'GrItem',
    }
  ];

  return (
    <Tabs items={
        (oneTab ? [tabs[0]] : tabs).map((item, index) => {
          const Cardi = TabCards[item.Comp];
          return {
            key: (index + 1).toString(),
            label: item.name,
            forceRender: true,
            children: <Cardi {...props} />
          }
        })
    } defaultActiveKey={defaultActive || "1"} onChange={(activeKey) => setActiveTab(activeKey)} renderTabBar={renderTabBar}/>
  );
};
