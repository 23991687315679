import React, { useEffect, Fragment } from 'react';
import { Col, Button, Row, Form, message, Spin } from 'antd';
import FormGroup from 'Molecules/FormGroup';

const _ = require('lodash');

export default (props) => {
  const { id, errors, control, meta, data, setValue, allowedEdit, handleSubmit, editPosUserOC, reloadApi } = props;

  const formFields = [
    {
      name: 'oc_monthly_quota',
      label: 'Officer Check Monthly Quota',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      prefix: "RM" // or dynamic by currency
    },
    {
      name: 'ent_monthly_quota',
      label: 'Entertainment Monthly Quota',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      prefix: "RM" // or dynamic by currency
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('oc_monthly_quota', data.oc_monthly_quota);
      setValue('ent_monthly_quota', data.ent_monthly_quota);
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      id: id
    }

    await editPosUserOC(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Quota edited successfully!");
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}