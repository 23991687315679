import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getProducts = (page, limit, order, orderby, search = {}, load, sorter) => {
  load && load(true);
  let sort = "";
  if (Array.isArray(sorter)) {
    let temp = [];
    let ordering = '';
    sorter.forEach(d => {
      if (d.order == "ascend") {
        ordering = 'ASC'
      } else if (d.order == "descend") {
        ordering = 'DESC'
      }
      temp.push(`${d.columnKey} ${ordering}`);
    })
    sort = temp;
  } else {
    let ordering = '';
    if (order == "ascend") {
      ordering = 'ASC'
    } else if (order == "descend") {
      ordering = 'DESC'
    }
    sort = `${orderby} ${ordering}`;
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/products/list`, {
      q: JSON.stringify({
        code_or_name_cont: search?.code,
        status_eq: search?.status,
        s: sort
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getProduct = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/products/get`, {
      product_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getProductOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/products/option`);
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_OPTION,
      data: data
    });
  };
};

export const getBoms = (page, limit, order, orderby, load, product_id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/products/bom-list`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      product_id: product_id,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.BOM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getProductGroupOption = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/products/part-group-option`, {
      product_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_GROUP_OPTION,
      data: data,
    });
  };
}

export const getProductMenus = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/product-menus/list`, {
      q: JSON.stringify({
        desc_cont: search?.name,
        code_cont: search?.code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_MENU_LIST,
      data: data,
    });
  };
};

export const getProductMenu = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/product-menus/get`, {
      product_menu_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_MENU_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getOrderTypes = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/order-types/list`, {
      q: JSON.stringify({
        code_cont: search?.code,
        desc_cont: search?.name,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.ORDER_TYPE_LIST,
      data: data,
    });
  };
};

export const getOrderType = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/order-types/get`, {
      order_type_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.ORDER_TYPE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getOrderTypeOption = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/order-types/option`);
    load && load(false);
    dispatch({
      type: action_types.ORDER_TYPE_OPTION,
      data: data,
    });
  };
};

export const getIngredients = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/ingredients/list`, {
      q: JSON.stringify({
        code_or_name_cont: search?.code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.INGREDIENT_LIST,
      data: data,
    });
  };
};

export const getIngredient = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/ingredients/get`, {
      id: id
    });
    load && load(false);
    dispatch({
      type: action_types.INGREDIENT_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getAlacartes = (page, limit, order, orderby, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/ingredients/alacarte-list`, {
      id: id,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.ALACARTE_LIST,
      data: data,
    });
  };
};

export const getCategories = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/categories/list`, {
      q: JSON.stringify({
        code_or_name_cont: search?.code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.CATEGORY_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getCategory = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/categories/get`, {
      category_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.CATEGORY_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getSubCategories = (page, limit, order, orderby, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/categories/sub-category-list`, {
      category_id: id,
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.SUB_CATEGORY_LIST,
      data: data,
    });
  };
};

export const getFurtherSubCategories = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/categories/further-subcategory-list`, {
      category_id: id,
      q: JSON.stringify({
        sub_category_id_eq: search?.sub_category,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.FURTHER_SUBCATEGORY_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getMenuTypes = (page, limit, order, orderby, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/product-menus/menu-type-list`, {
      product_menu_id: id,
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_MENU_TYPE_LIST,
      data: data,
    });
  };
};

export const getMenuCategoriesOption = (type ,id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/product-menus/menu-categories-option`, {
      product_menu_id: id,
      is_kiosk: type
    });
    load && load(false);
    dispatch({
      type: action_types.MENU_CATEGORY_OPTION,
      data: data,
    });
  };
}

export const getMenuItems = (page, limit, order, orderby, search, id, cid, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/product-menus/menu-items-list`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      product_menu_id: id,
      menu_category_id: cid,
      search_term: search?.product,
      page: page,
      limit: limit,
    });
    load && load(false);
    dispatch({
      type: action_types.MENU_ITEM_LIST,
      data: data,
    });
  };
}

export const getAvailableProducts = (page, limit, order, orderby, search, id, cid, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/product-menus/available-product-list`, {
      menu_category_id: cid,
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      search_term: search?.product,
      page: page,
      limit: limit,
    });
    load && load(false);
    dispatch({
      type: action_types.AVAILABLE_PRODUCT_LIST,
      data: data,
    });
  };
}

export const reloadMenuItems = (status, data) => {
  return async (dispatch) => {
    dispatch({
      type: status === "active" ? action_types.MENU_ITEM_LIST : action_types.AVAILABLE_PRODUCT_LIST,
      data: data,
    });
  };
}

export const getUoms = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/uoms/list`, {
      q: JSON.stringify({
        code_or_desc_cont: search?.code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.UOM_LIST,
      data: data,
    });
  };
};

export const getUom = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/uoms/get`, {
      id: id
    });
    load && load(false);
    dispatch({
      type: action_types.UOM_DETAIL,
      data: data,
    });
  };
};

export const getAvailabilityList = (page, limit, order, orderby, search = null, load) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(
      `${externalBaseUrl}/work-schedules/list`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      name_cont: search?.name,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_AVAILABILITY_LIST,
      data: data,
    });
  };
};

export const getMenuCategory = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/product-menus/menu-category-get`, {
      menu_category_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.MENU_CATEGORY_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getProductAvailability = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/work-schedules/get`, {
      work_schedule_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_AVAILABILITY_DETAIL,
      data: data,
    });
  };
};

export const getProductUoms = (page, limit, order, orderby, load, ingredient_id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/ingredients/product-uom-list`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      id: ingredient_id,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.PRODUCT_UOM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getSelectedMenuType = (value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SELECTED_MENU_TYPE,
      data: value
    });
  };
};

export const getKioskValue = (value) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.KIOSK_VALUE,
      data: value
    });
  };
};

export const getMenuSyncLogs = (page, limit, order, orderby, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/product-menus/sync-logs`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit,
      product_menu_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.MENU_SYNC_LOGS,
      data: data,
      meta: meta
    });
  };
};
