import * as action_types from './constants';

const initialState = {
  users: {},
  user: {},
  option: {},
  userRoles: {},
  userRoleOption: {},
  posusers: {},
  posuser: {},
  posoption: {},
  posuserRoles: {},
  posuserRoleOption: {},
  currencies: {},
  currency: {},
  paymentTerms: {},
  paymentTerm: {},
  regions: {},
  region: {},
  taxConditions: {},
  taxCondition: {},
  taxConditionOption: {},
  tcItems: {},
  tcItemsOption: {},
  reasons: {},
  reason: {},
  reasonOption: {},
  remarks: {},
  remark: {},
  remarkTypes: {},
  remarkType: {},
  remarkTypeOption: {},
  rtItems: {},
  rtItemsOption: {},
  taxes: {},
  paymentMethods: {},
  paymentMethod: {},
  paymentMethodOption: {},
  paymentMethodTypes: {},
  paymentMethodType: {},
  paymentMethodTypeOption: {},
  entity: {},
  entityOption: {},
  adList: {},
  adListMeta: {},
  merchantList: {},
  merchantOption: {},
  merchantDetail: {},
  merchantDetailMeta: {},
  orderTypeGroups: {},
  orderTypeGroup: {},
  orderTypeGroupOption: {},
  entityUserList: {},
  entityUserListMeta: {},
  netsuiteSyncLogs: {},
  netsuiteSyncLogsMeta: {},
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.USER_LIST:
      return { ...state, users: data, option: meta };
    case action_types.USER_DETAIL:
      return { ...state, user: data, option: meta };
    case action_types.USER_OPTION:
      return { ...state, option: data };
    case action_types.USER_ROLES:
      return { ...state, userRoles: data };
    case action_types.ROLE_OPTION:
      return { ...state, userRoleOption: data };
    case action_types.POS_USER_LIST:
      return { ...state, posusers: data, posoption: meta };
    case action_types.POS_USER_DETAIL:
      return { ...state, posuser: data, posoption: meta };
    case action_types.POS_USER_OPTION:
      return { ...state, posoption: data };
    case action_types.POS_USER_ROLES:
      return { ...state, posuserRoles: data };
    case action_types.POS_ROLE_OPTION:
      return { ...state, posuserRoleOption: data };
    case action_types.CURRENCY_LIST:
      return { ...state, currencies: data };
    case action_types.CURRENCY_DETAIL:
      return { ...state, currency: data };
    case action_types.PAYMENT_TERM_LIST:
      return { ...state, paymentTerms: data };
    case action_types.PAYMENT_TERM_DETAIL:
      return { ...state, paymentTerm: data };
    case action_types.REGION_LIST:
      return { ...state, regions: data };
    case action_types.REGION_DETAIL:
      return { ...state, region: data };
    case action_types.TAX_CONDITION_LIST:
      return { ...state, taxConditions: data };
    case action_types.TAX_CONDITION_DETAIL:
      return { ...state, taxCondition: data, taxConditionOption: meta };
    case action_types.TAX_CONDITION_ITEM_LIST:
      return { ...state, tcItems: data, tcItemsOption: meta };
    case action_types.REASON_LIST:
      return { ...state, reasons: data, reasonOption: meta };
    case action_types.REASON_DETAIL:
      return { ...state, reason: data, reasonOption: meta };
    case action_types.REASON_OPTION:
      return { ...state, reasonOption: data };
    case action_types.REMARK_LIST:
      return { ...state, remarks: data };
    case action_types.REMARK_DETAIL:
      return { ...state, remark: data };
    case action_types.REMARK_TYPE_LIST:
      return { ...state, remarkTypes: data, remarkTypeOption: meta };
    case action_types.REMARK_TYPE_DETAIL:
      return { ...state, remarkType: data, remarkTypeOption: meta };
    case action_types.REMARK_TYPE_ITEM_LIST:
      return { ...state, rtItems: data, rtItemsOption: meta };
    case action_types.TAXES_LIST:
      return { ...state, taxes: data };
    case action_types.PAYMENT_METHOD_LIST:
      return { ...state, paymentMethods: data, paymentMethodOption: meta };
    case action_types.PAYMENT_METHOD_DETAIL:
      return { ...state, paymentMethod: data, paymentMethodOption: meta };
    case action_types.PAYMENT_METHOD_OPTION:
      return { ...state, paymentMethodOption: data };
    case action_types.PAYMENT_METHOD_TYPE_LIST:
      return { ...state, paymentMethodTypes: data, paymentMethodTypeOption: meta };
    case action_types.PAYMENT_METHOD_TYPE_DETAIL:
      return { ...state, paymentMethodType: data, paymentMethodTypeOption: meta };
    case action_types.PAYMENT_METHOD_TYPE_OPTION:
      return { ...state, paymentMethodTypeOption: data };
    case action_types.ENTITY_DETAIL:
      return { ...state, entity: data, entityOption: meta };
    case action_types.AD_LIST:
      return { ...state, adList: data, adListMeta: meta };
    case action_types.MERCHANT_LIST:
      return { ...state, merchantList: data };
    case action_types.MERCHANT_OPTION:
      return { ...state, merchantOptions: data };
    case action_types.MERCHANT_DETAIL:
      return { ...state, merchantDetail: data, merchantDetailMeta: meta };
    case action_types.ORDER_TYPE_GROUP_LIST:
      return { ...state, orderTypeGroups: data };
    case action_types.ORDER_TYPE_GROUP_DETAIL:
      return { ...state, orderTypeGroup: data, orderTypeGroupOption: meta };
    case action_types.ENTITY_USER_LIST:
      return { ...state, entityUserList: data, entityUserListMeta: meta };
    case action_types.NETSUITE_SYNC_LOGS:
      return { ...state, netsuiteSyncLogs: data, netsuiteSyncLogsMeta: meta };

    default:
      return state;
  }
};
