import React from "react";
import Icon from '@ant-design/icons';

const AdvancementSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
        <path d="M142.962,189.558l1.208-1.078v5.311a.849.849,0,0,1-.849.849H141.6a.583.583,0,0,1-.58-.59v-.16A5.8,5.8,0,0,1,142.962,189.558Zm17.548,1.952a5.76,5.76,0,0,0-1.44-1.96l-1.2-1.07v5.311a.849.849,0,0,0,.849.849h1.711a.583.583,0,0,0,.59-.58v-.17A5.846,5.846,0,0,0,160.51,191.51ZM147.922,197.9a.587.587,0,0,0,.586.586h5.021a.587.587,0,0,0,.586-.586v-.753h-6.193Zm6.348-16.626a7.43,7.43,0,0,1,1.1,3.89v8.61a.871.871,0,0,1-.87.87h-6.96a.871.871,0,0,1-.87-.87v-8.61a7.381,7.381,0,0,1,4.09-6.62.593.593,0,0,1,.52,0A7.491,7.491,0,0,1,154.27,181.27Zm-1.77,7.89a1.48,1.48,0,1,0-1.48,1.48A1.477,1.477,0,0,0,152.5,189.16Zm0-5.2a1.48,1.48,0,1,0-1.48,1.48A1.477,1.477,0,0,0,152.5,183.96Z" transform="translate(-141.02 -178.48)" />
    </svg>
  );

  const AdvancementIcon = props => <Icon component={AdvancementSvg} {...props} />;

  export default AdvancementIcon;