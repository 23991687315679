import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message, Upload, Space } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { id, editOrganization, reloadApi, allowedEdit, data } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      name: 'ready_to_pickup_timer',
      label: <><div style={{marginRight: "5px"}}>Ready To Pickup Timer</div><span className='c-gray'>in second(s)</span></>,
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      number: true,
      nostep: true,
      min: 0,
      tips: "Input 0 will turn off the timer.",
      static: !allowedEdit
    },
    {
      name: 'missed_collection_timer',
      label: <><div style={{marginRight: "5px"}}>Missed Collection Timer</div><span className='c-gray'>in second(s)</span></>,
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      number: true,
      nostep: true,
      min: 0,
      tips: "Input 0 will turn off the timer.",
      static: !allowedEdit
    },
    {
      name: 'close_order_timer',
      label: <><div style={{ marginRight: "5px" }}>Close Order Timer</div><span className='c-gray'>in second(s)</span></>,
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      number: true,
      min: 0,
      tips: "Input 0 will turn off the timer.",
      static: !allowedEdit
    },
    {
      name: 'fa_remark_char_limit',
      label: 'Kitchen Remark Character Limit',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      number: true,
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('ready_to_pickup_timer', data.ready_to_pickup_timer);
      setValue('missed_collection_timer', data.missed_collection_timer);
      setValue('close_order_timer', data.close_order_timer);
      setValue('fa_remark_char_limit', data.fa_remark_char_limit)
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      id: id,
      kds: 1,
      ready_to_pickup_timer: val?.ready_to_pickup_timer,
      missed_collection_timer: val?.missed_collection_timer,
      close_order_timer: val?.close_order_timer,
      fa_remark_char_limit: val?.fa_remark_char_limit
    }

    await editOrganization(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Receipt Setting Updated successfully!");
        reloadApi()
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}