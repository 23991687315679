import React from "react";
import Icon from '@ant-design/icons';

const GrantsSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
      <path id="Path_28821" d="M4.3,1.3C4.3,0.6,4.9,0,5.6,0l0,0h8.9c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3H14v6.2l0.8,1.3
	H5.2L6,8.7V2.5H5.6C4.9,2.5,4.3,1.9,4.3,1.3C4.3,1.3,4.3,1.3,4.3,1.3z M15.5,20h-11c-1.7,0-3-1.3-3-3c0-0.5,0.1-1.1,0.4-1.5l1.8-3
	h7.2c0,0.1,0,0.1,0,0.2c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8c0-0.1,0-0.1,0-0.2h1.9l1.8,3c0.9,1.4,0.4,3.3-1,4.1
	C16.6,19.9,16,20,15.5,20L15.5,20z M8.2,15c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0,0,0,0,0,0
	C7.6,16.4,8.2,15.8,8.2,15L8.2,15z M10.6,17.8c0-0.6-0.5-1-1-1s-1,0.5-1,1c0,0.6,0.5,1,1,1c0,0,0,0,0,0
	C10.1,18.8,10.6,18.3,10.6,17.8C10.6,17.8,10.6,17.8,10.6,17.8L10.6,17.8z"/>
    </svg>
  )

  const GrantsIcon = props => <Icon component={GrantsSVG} {...props} />;

  export default GrantsIcon;