import React, { useEffect, Fragment } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { id, data, addProductMenu, updateProductMenu, reloadApi, mode, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      name: 'code',
      label: 'Product Menu Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'desc',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'hide_order_type_image',
      label: 'Hide Order Type Image?',
      placeholder: 'Please select',
      type: 'switch',
      twocol: false,
      colWidth: '0 1 33.33%',
      static: !allowedEdit
    },
    {
      name: 'hide_menu_category_image',
      label: 'Hide Menu Category Image?',
      placeholder: 'Please select',
      type: 'switch',
      twocol: false,
      colWidth: '0 1 33.33%',
      static: !allowedEdit
    },
    {
      name: 'hide_menu_item_image',
      label: 'Hide Menu Item Image?',
      placeholder: 'Please select',
      type: 'switch',
      twocol: false,
      colWidth: '0 1 33.33%',
      static: !allowedEdit
    },
    {
      name: 'hide_order_screen_image',
      label: 'Hide Order Screen Image?',
      placeholder: 'Please select',
      type: 'switch',
      twocol: false,
      colWidth: '0 1 33.33%',
      static: !allowedEdit
    },
    {
      name: 'hide_checkout_screen_image',
      label: 'Hide Checkout Screen Image?',
      placeholder: 'Please select',
      type: 'switch',
      twocol: false,
      colWidth: '0 1 33.33%',
      static: !allowedEdit
    },
    {
      name: 'hide_promotion_image',
      label: 'Hide Promotion Image?',
      placeholder: 'Please select',
      type: 'switch',
      twocol: false,
      colWidth: '0 1 33.33%',
      static: !allowedEdit
    },
    {
      name: 'hide_payment_method_image',
      label: 'Hide Payment Method Image?',
      placeholder: 'Please select',
      type: 'switch',
      twocol: false,
      colWidth: '0 1 33.33%',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('code', data.code);
      setValue('desc', data.desc);
      setValue('hide_order_type_image', data.hide_order_type_image === "1");
      setValue('hide_menu_category_image', data.hide_menu_category_image === "1");
      setValue('hide_menu_item_image', data.hide_menu_item_image === "1");
      setValue('hide_order_screen_image', data.hide_order_screen_image === "1");
      setValue('hide_checkout_screen_image', data.hide_checkout_screen_image === "1");
      setValue('hide_promotion_image', data.hide_promotion_image === "1");
      setValue('hide_payment_method_image', data.hide_payment_method_image === "1");
    }
  }, [data]);

  const onFinish = async (val) => {
    if(mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      hide_order_type_image: val?.hide_order_type_image ? 1 : 0,
      hide_menu_category_image: val?.hide_menu_category_image ? 1 : 0,
      hide_menu_item_image: val?.hide_menu_item_image ? 1 : 0,
      hide_order_screen_image: val?.hide_order_screen_image ? 1 : 0,
      hide_checkout_screen_image: val?.hide_checkout_screen_image ? 1 : 0,
      hide_promotion_image: val?.hide_promotion_image ? 1 : 0,
      hide_payment_method_image: val?.hide_payment_method_image ? 1 : 0,
      product_menu_id: id
    }

    await updateProductMenu(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Updated Product Menu successfully!");
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    const payload = {
      code: val.code,
      desc: val.desc,
      hide_order_type_image: val?.hide_order_type_image ? 1 : 0,
      hide_menu_category_image: val?.hide_menu_category_image ? 1 : 0,
      hide_menu_item_image: val?.hide_menu_item_image ? 1 : 0,
      hide_order_screen_image: val?.hide_order_screen_image ? 1 : 0,
      hide_checkout_screen_image: val?.hide_checkout_screen_image ? 1 : 0,
      hide_promotion_image: val?.hide_promotion_image ? 1 : 0,
      hide_payment_method_image: val?.hide_payment_method_image ? 1 : 0,
    }
    await addProductMenu(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Product Menu created successfully!");
        setTimeout(() => reloadApi(res.data.data), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}