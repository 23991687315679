import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import Terminal from 'Modules/Pos/Terminal';
import AddTerminal from 'Modules/Pos/Terminal/AddTerminal';
import EditTerminal from 'Modules/Pos/Terminal/EditTerminal';
import VoucherType from 'Modules/Pos/VoucherType';
import AddVoucherType from 'Modules/Pos/VoucherType/AddVoucherType';
import EditVoucherType from 'Modules/Pos/VoucherType/EditVoucherType';
import VoucherGenerator from 'Modules/Pos/VoucherGenerator';
import Vouchers from 'Modules/Pos/Vouchers';
import EditVoucher from 'Modules/Pos/Vouchers/EditVoucher';
import KitchenDisplay from 'Modules/Pos/KitchenDisplay';
import AddKitchenDisplay from 'Modules/Pos/KitchenDisplay/AddKitchenDisplay';
import EditKitchenDisplay from 'Modules/Pos/KitchenDisplay/EditKitchenDisplay';
import KitchenQueue from 'Modules/Pos/KitchenQueue';
import AddKitchenQueue from 'Modules/Pos/KitchenQueue/AddKitchenQueue';
import EditKitchenQueue from 'Modules/Pos/KitchenQueue/EditKitchenQueue';
import Discount from 'Modules/Pos/Discount';
import AddDiscount from 'Modules/Pos/Discount/AddDiscount';
import EditDiscount from 'Modules/Pos/Discount/EditDiscount';
import Printer from 'Modules/Pos/Printer';
import AddPrinter from 'Modules/Pos/Printer/AddPrinter';
import EditPrinter from 'Modules/Pos/Printer/EditPrinter';
import AuditLog from 'Modules/Pos/AuditLog';

const Components = {
  Terminal,
  AddTerminal,
  EditTerminal,
  VoucherType,
  AddVoucherType,
  EditVoucherType,
  VoucherGenerator,
  Vouchers,
  EditVoucher,
  KitchenDisplay,
  AddKitchenDisplay,
  EditKitchenDisplay,
  KitchenQueue,
  AddKitchenQueue,
  EditKitchenQueue,
  Discount,
  AddDiscount,
  EditDiscount,
  Printer,
  AddPrinter,
  EditPrinter,
  AuditLog
};

export default (props) => {
  const PosComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <PosComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  );
};