import React from 'react';
import { Tabs } from 'antd';
import * as TabCards from './tabList';

export default (props) => {
  const tabs = [
    {
      name: "Order Screen",
      Comp: 'OrderScreen',
    },
    {
      name: "Bill Function Screen",
      Comp: 'BillScreen',
    },
    {
      name: "Checkout Screen",
      Comp: 'CheckoutScreen',
    },
    {
      name: "Promotion Screen",
      Comp: 'PromotionScreen',
    }
  ];

  return (
    <Tabs items={
      tabs.map((item, index) => {
        const Cardi = TabCards[item.Comp];
        return {
          key: (index + 1).toString(),
          label: item.name,
          forceRender: true,
          children: <Cardi {...props} />
        }
      })
    } defaultActiveKey={"1"} />
  );
};
