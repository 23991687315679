import React, { useState } from 'react';
import { Button, Row, Col, Typography, Form, message, Spin, Space } from 'antd';
import { useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import { addAvailability } from '../../ducks/services';
import dayjs from 'dayjs';
import AddEditAvailability from '../Components/AddEditAvailability';
import { useNavigate } from 'react-router-dom';

const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const navigate = useNavigate();
  const { Title } = Typography;
  const [load, setLoad] = useState(false);
  const { control, formState: { errors }, setValue, reset, handleSubmit, getValues, clearErrors } = useForm();

  const onFinish = async (val) => {
    setLoad(true);
    let payload = {
      name: val?.template_name,
      shifts: val?.work_hours && val?.work_hours.length > 0 ? JSON.stringify(val?.work_hours.map((value) => ({
        dow: value.day,
        shift_type: value.work_hour_type.value,
        start_time: value?.start_time ? dayjs(value?.start_time).format("HH:mm") : "",
        end_time: value?.end_time ? dayjs(value?.end_time).format("HH:mm") : ""
      }))) : "[]",
    };

      addAvailability(payload)
        .then((response) => {
          setLoad(false);
          if (response.data.code === 200) {
            message.success("Product Availability Added Successfully");
            navigate("/product/availability")
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoad(false);
          message.error(error?.message ?? 'Something went wront');
        })
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Form scrollToFirstError layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[10, 20]}>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'}>
              <Col flex='auto'>
                <Title level={3} className='mb-0 mt-0'>New Product Availability</Title>
              </Col>
              <Col flex='auto' className='text-right'>
                <Space size={10}>
                  <Button size="large" htmlType='submit' className='green-btn'>Save</Button>
                  <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/product/availability')}>{'<'} Back</Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <AddEditAvailability
              setLoading={setLoad}
              errors={errors}
              control={control}
              setValue={setValue}
              reset={reset}
              mode={'add'}
              navigate={navigate}
              getValues={getValues}
              clearErrors={clearErrors}
              allowedEdit={true}
            />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};