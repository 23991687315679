import React, { useEffect, useState } from 'react';
import { Row, Col, message, Button, Form, Spin, Space, Typography } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getOutletPosUserList } from '../../../../ducks/actions'
import { removeOutletUser, bulkRemoveOutletUsers, addOutletPosUser } from '../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'
// import Roles from '../../../../routing/config/Roles';
// import { allowed } from '../../../../routing/config/utils';
import { Popup } from 'Atoms/Popup';
import { SelectField } from 'Atoms/FormElement';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const users = useSelector((state) => state.outlet.posUsers);
  const meta = useSelector((state) => state.outlet.posUsersMeta);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (activeTab === "4")
      dispatch(getOutletPosUserList(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);

  const colName = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Name',
      dataIndex: 'user_name',
      key: 'user_name',
      className: 'enable-click',
      sorter: true
    },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    //   key: 'email',
    //   className: 'enable-click',
    //   sorter: true
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="POS User"
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
          // allowedDelete={allowed([Roles.USERS], 'delete')}
        />
    }
  ];

  const addNew = () => {
    setVisible(true);
    setValue('user', '');
  }

  const btnList = [
    {
      title: 'Are you sure to remove the selected user(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New POS User',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  const onSearch = (val) => {
    setPage(1);
    if (val) {
      let searching = {
        name: val?.name,
        email: val?.email
      }
      setSearchVal(searching)
      dispatch(getOutletPosUserList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal();
      dispatch(getOutletPosUserList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onAction = async (locUserId, status) => {
    props.setLoading(true);

    const payload = {
      id: id,
      location_user_id: locUserId,
      status_event: status === 'Active' ? 'deactivate' : 'activate'
    }

    await removeOutletUser(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("User status has been updated successfully!");
        setTimeout(() => dispatch(getOutletPosUserList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getOutletPosUserList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getOutletPosUserList(pagination.current, pagination.pageSize, '', '', null, props.setLoading, id));
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (posUserId) => {
    props.setLoading(true);

    const payload = {
      id: id,
      location_user_id: posUserId,
      status_event: 'remove'
    }

    await removeOutletUser(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("POS User has been removed successfully!");
        setTimeout(() => dispatch(getOutletPosUserList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      users: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveOutletUsers(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("POS Users has been removed successfully!");
        setTimeout(() => dispatch(getOutletPosUserList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      id: id,
      location_user_id: val?.user?.value
    }

    await addOutletPosUser(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200){
        message.success("POS User added successfully!");
        setVisible(false);
        dispatch(getOutletPosUserList(1, limit, '', '', searchVal, props.setLoading, id));
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message);
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Add POS User
          </Title>
          <SelectField
            isRequired={visible}
            fieldname='user'
            label='User'
            class='mb-1 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Please select' }}
            selectOption={meta?.pos_users?.map(x => ({label: x.name, value: x.id}))}
            rules={{
              required: 'User is required',
            }}
            validate={errors.user && 'error'}
            validMessage={errors.user && errors.user.message}
          />
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('user', '');
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('user', '');
    },
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {/* <HeadingChip title={"POS Users"} btnList={allowed([Roles.USERS], 'write') && btnList} btnHidden={selectedRowKeys.length <= 0 || !allowed([Roles.USERS], 'delete')} /> */}
          <HeadingChip title={"POS Users"} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            ListData={users?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            rowSelection={rowSelection}
            pagination={{
              total: users?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}