import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getVendorProducts } from '../../../../../ducks/actions'
import { removeVendorProduct, bulkRemoveVendorProducts, addVendorProduct, editVendorProduct } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';
import dayjs from 'dayjs';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const products = useSelector((state) => state.inventory.suppliedItems);
  const meta = useSelector((state) => state.inventory.suppliedItemsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const [uoms, setUoms] = useState([]);

  const colName = [
    {
      title: 'Product Code',
      dataIndex: 'product_code',
      key: 'product_code',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Effective Date',
      dataIndex: 'eff_date',
      key: 'eff_date',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'UOM',
      dataIndex: 'uom_code',
      key: 'uom_code',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Region(s)',
      dataIndex: 'regions',
      key: 'regions',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text?.map((x) => x.code).join(", ") || "-"}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Supplied Item"
          btnAction1={() => {
            setVisible(true);
            setValue('id', record.id);
            let temp = [];
            record?.regions?.map(region => {
              temp.push({
                label: region.code,
                value: region.id
              })
            })
            setValue('regions', temp);
            setValue('product', record.product_id ? {label: record.product_code, value: record.product_id} : '');
            setValue('eff_date', record.eff_date ? dayjs(record.eff_date) : '');
            setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : '');
            setValue('price', record.price);
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={setLoad}
          loading={load}
        />
    }
  ];

  const formFields = [
    {
      name: 'product',
      label: 'Supplied Item',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: _.map(meta?.ingredients, (e) => ({ label: `${e.code} ${e.name}`, value: e.id, product_uoms: e.product_uoms })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit,
      onChange: (e) => {
        setUoms(e.product_uoms);
      }
    },
    {
      name: 'eff_date',
      label: 'Effective Date',
      req: true,
      placeholder: 'Please select',
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit,
    },
    {
      name: 'uom',
      label: 'UOM',
      req: true,
      placeholder: 'Please select',
      class: 'default-select',
      type: 'select',
      options: _.map(uoms, (e) => ({ label: e.code, value: e.id })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit,
    },
    {
      type: 'input',
      name: 'price',
      label: 'Price',
      placeholder: 'Please enter',
      number: true,
      req: true,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit,
    },
    {
      type: 'select',
      label: 'Regions',
      name: 'regions',
      twocol: false,
      req: false,
      multiple: true,
      options: meta?.regions?.map((x) => ({ label: x.code, value: x.id })),
      placeholder: 'Please select',
      class: "default-select",
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getVendorProducts(1, limit, '', '', null, setLoad, id));
  }, [activeTab]);

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('product', '');
    setValue('eff_date', '');
    setValue('uom', '');
    setValue('price', '');
    setValue('regions', '');
  }

  const btnList = [
    {
      title: 'Are you sure to remove the selected Supplied Item(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      notooltip: true,
      action: () => onBulkRemove()
    },
    {
      text: '+ New Item',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getVendorProducts(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, setLoad, id));
    } else {
      dispatch(getVendorProducts(pagination.current, pagination.pageSize, '', '', searchVal, setLoad, id));
    }
  }
  
  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        product_code: search?.product_code
      };
      setSearchVal(searching);
      dispatch(getVendorProducts(1, limit, '', '', searching, setLoad, id));
    } else {
      setSearchVal(null);
      dispatch(getVendorProducts(1, limit, '', '', null, setLoad, id));
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAction = async (prodId, status) => {
    setLoad(true);

    const payload = {
      vendor_id: id,
      vendor_product_id: prodId,
      status_event: status === 'Active' ? 'deactivate' : 'activate'
    }

    await removeVendorProduct(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success("Supplied Item status has been updated successfully!");
        setTimeout(() => dispatch(getVendorProducts(page, limit, '', '', searchVal, setLoad, id)), 500);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (prodId) => {
    setLoad(true);

    const payload = {
      vendor_id: id,
      vendor_product_id: prodId,
      status_event: 'remove'
    }

    await removeVendorProduct(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success("Supplied Item has been removed successfully!");
        setTimeout(() => dispatch(getVendorProducts(page, limit, '', '', searchVal, setLoad, id)), 500);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    setLoad(true);

    const payload = {
      vendor_id: id,
      vendor_products: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveVendorProducts(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Supplied Items Removed Successfully!");
        setSearchVal();
        setTimeout(() => dispatch(getVendorProducts(1, limit, '', '', null, setLoad, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      uom_id: val?.uom?.value,
      eff_date: val?.eff_date ? dayjs(val?.eff_date).format("YYYY-MM-DD") : null,
      product_id: val?.product?.value,
      price: val?.price,
      regions: val?.regions ? JSON.stringify(val?.regions?.map(x => ({ id: x.value }))) : "[]",
      vendor_id: id,
      vendor_product_id: val?.id
    }

    await (val?.id ? editVendorProduct : addVendorProduct)(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(`Supplied Item ${val?.id ? "Edited" : "Added"} Successfully!`);
        setVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getVendorProducts(1, limit, '', '', searchVal, setLoad, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Add Supplied Item
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('product', '');
              setValue('uom', '');
              setValue('eff_date', '');
              setValue('price', '');
              setValue('regions', '');
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('product', '');
      setValue('uom', '');
      setValue('eff_date', '');
      setValue('price', '');
      setValue('regions', '');
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setValue('id', record.id);
          let temp = [];
          record?.regions?.map(region => {
            temp.push({
              label: region.code,
              value: region.id
            })
          })
          setValue('regions', temp);
          setValue('product', record.product_id ? { label: record.product_code, value: record.product_id } : '');
          setValue('eff_date', record.eff_date ? dayjs(record.eff_date) : '');
          setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : '');
          setValue('price', record.price);
          setVisible(true);
        }
      },
    };
  }

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit && btnList} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onSearch={onSearch}
            Search={Search}
            onRow={onClickRow}
            ListData={products?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            pagination={{
              total: products?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </Spin>
  )
}