import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

const _ = require('lodash');

export default (props) => {
  const { id, data, meta, addTransferIn, editTransferIn, reloadApi, mode, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [editable, setEditable] = useState(false);

  const formFields = [
    {
      name: 'reference',
      label: 'TR No.',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable
    },
    {
      name: 'date',
      label: 'Date',
      req: true,
      placeholder: 'Please state',
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable
    },
    // {
    //   name: 'receive_date',
    //   label: 'TR Date',
    //   req: true,
    //   placeholder: 'Please state',
    //   type: 'date',
    //   format: 'YYYY-MM-DD',
    //   twocol: false,
    //   colWidth: '0 1 50%',
    //   reqmessage: 'Required',
    //   static: !editable
    // },
    {
      type: 'select',
      label: 'Origin Location Code',
      name: 'origin',
      twocol: false,
      // options: meta?.locations?.map((x) => ({ label: x.code, value: x.id })),
      options: [],
      req: true,
      placeholder: 'Please select',
      reqmessage: 'Origin Location Required',
      colWidth: '0 1 50%',
      static: !editable
    },
    {
      type: 'select',
      label: 'Destination Location Code',
      name: 'destination',
      twocol: false,
      // options: meta?.locations?.map((x) => ({ label: x.code, value: x.id })),
      options: [],
      req: true,
      placeholder: 'Please select',
      reqmessage: 'Destination Location Required',
      colWidth: '0 1 50%',
      static: !editable
    },
    {
      name: 'remarks',
      label: 'Remarks',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable
    },
    {
      name: 'cancellation_remarks',
      label: 'Cancellation Remarks',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable,
      hidden: data && data?.cancellation_remarks ? false : true
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('reference', data.reference);
      setValue('date', data?.date ? dayjs(data?.date) : '');
      setValue('receive_date', data?.receive_date ? dayjs(data?.receive_date) : '');
      setValue('origin', data?.origin_id ? { label: `${data?.origin_code} ${data?.origin_desc}`, value: data?.origin_id } : '');
      setValue('destination', data?.destination_id ? { label: `${data?.destination_code} ${data?.destination_desc}`, value: data?.destination_id } : '');
      setValue('remarks', data?.remarks);
      setValue('cancellation_remarks', data?.cancellation_remarks);
      // setEditable(data.status === "pending" && allowedEdit);
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      origin_id: val?.origin?.value,
      destination_id: val?.destination?.value,
      date: val?.date ? dayjs(val?.date).format("YYYY-MM-DD") : '',
      transfer_in_id: id,
    }

    await (mode === "add" ? addTransferIn : editTransferIn)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(`Transfer Receive ${mode === "add" ? "created" : "updated"} successfully!`);
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          editable &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}