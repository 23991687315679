import * as action_types from './constants';

const initialState = {
  terminals: {},
  terminal: {},
  option: {},
  voucherTypes: {},
  voucherType: {},
  vtOption: {},
  generatorOption: {},
  vouchers: {},
  voucher: {},
  voucherOption: {},
  voucherItems: {},
  kitchenDisplays: {},
  kitchenDisplay: {},
  kitchenDisplayOption: {},
  kitchenQueues: {},
  kitchenQueue: {},
  kitchenQueueOption: {},
  discounts: {},
  discount: {},
  discountOption: {},
  discountAvailabilities: {},
  discountAvailabilitiesOption: {},
  discountAvProducts: {},
  printers: {},
  printer: {},
  printerOption: {},
  activities: {},
  allActivities: {},
  allActivitiesMeta: {},
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.POS_TERMINAL_LIST:
      return { ...state, terminals: data, option: meta };
    case action_types.POS_TERMINAL_DETAIL:
      return { ...state, terminal: data, option: meta };
    case action_types.POS_TERMINAL_OPTION:
      return { ...state, option: data };
    case action_types.VOUCHER_TYPE_LIST:
      return { ...state, voucherTypes: data, vtOption: meta };
    case action_types.VOUCHER_TYPE_DETAIL:
      return { ...state, voucherType: data, vtOption: meta };
    case action_types.VOUCHER_TYPE_OPTION:
      return { ...state, vtOption: data };
    case action_types.GENERATOR_OPTION:
      return { ...state, generatorOption: data };
    case action_types.VOUCHERS_LIST:
      return { ...state, vouchers: data, voucherOption: meta };
    case action_types.VOUCHERS_DETAIL:
      return { ...state, voucher: data, voucherOption: meta };
    case action_types.VOUCHER_ITEM_LIST:
      return { ...state, voucherItems: data };
    case action_types.KITCHEN_DISPLAY_LIST:
      return { ...state, kitchenDisplays: data };
    case action_types.KITCHEN_DISPLAY_DETAIL:
      return { ...state, kitchenDisplay: data, kitchenDisplayOption: meta };
    case action_types.KITCHEN_DISPLAY_OPTION:
      return { ...state, kitchenDisplayOption: data };
    case action_types.KITCHEN_QUEUE_LIST:
      return { ...state, kitchenQueues: data };
    case action_types.KITCHEN_QUEUE_DETAIL:
      return { ...state, kitchenQueue: data, kitchenQueueOption: meta };
    case action_types.KITCHEN_QUEUE_OPTION:
      return { ...state, kitchenQueueOption: data };
    case action_types.DISCOUNT_LIST:
      return { ...state, discounts: data };
    case action_types.DISCOUNT_DETAIL:
      return { ...state, discount: data, discountOption: meta };
    case action_types.DISCOUNT_OPTION:
      return { ...state, discountOption: data };
    case action_types.DISCOUNT_AVAILABILITY_LIST:
      return { ...state, discountAvailabilities: data, discountAvailabilitiesOption: meta };
    case action_types.DISCOUNT_AVAILABLE_PRODUCT_LIST:
      return { ...state, discountAvProducts: data };
    case action_types.PRINTER_LIST:
      return { ...state, printers: data };
    case action_types.PRINTER_DETAIL:
      return { ...state, printer: data, printerOption: meta };
    case action_types.PRINTER_OPTION:
      return { ...state, printerOption: data };
    case action_types.TERMINAL_ACTIVITY_LIST:
      return { ...state, activities: data };
    case action_types.ALL_ACTIVITY_LIST:
      return { ...state, allActivities: data, allActivitiesMeta: meta };
    
    default:
      return state;
  }
};
