import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message, Spin, Space, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FormGroup from 'Molecules/FormGroup';
import { InputField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

const _ = require('lodash');

export default (props) => {
  const { id, data, editPosTerminalPassword, logoutUser, reloadApi, mode, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(false);

  const formFields = [
    {
      name: 'login_password',
      type: 'password',
      // static: true,
      twocol: false,
      colWidth: "0 1 50%",
      onBtnClick: () => { 
        setVisible(true);
        setValue('new_password', '');
      },
      addOnButton: "Edit",
      readOnly: true,
      class: "readonly",
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('login_password', data.login_password);
    }
  }, [data]);

  const onFinish = async (val) => {
    setLoad(true);
    const payload = {
      login_password: val?.new_password,
      id: id
    }

    await editPosTerminalPassword(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`Login Password Updated successfully!`);
        reloadApi();
        setVisible(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onLogout = async () => {
    setLoad(true);
    const payload = {
      id: id
    }

    await logoutUser(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`Logout successfully!`);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Edit Password
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24} className='text-center'>
              <InputField
                isRequired={true}
                fieldname="new_password"
                label="Login Password"
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  type: 'number',
                  disabled: !allowedEdit
                }}
                rules={{
                  required: 'Please enter password',
                  pattern: {
                    value: /^\d{6}$/,
                    message: 'Password must be 6 numbers',
                  },
                  validate: (e) => e !== data?.login_password || "Password cannot be the same"
                }}
                validate={errors.new_password && 'error'}
                validMessage={errors.new_password && errors.new_password.message}
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
    },
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
        </Row>
        <Row>
          {
            allowedEdit &&
            <Button type="primary" size="large" className="blue-btn" htmlType="button" onClick={onLogout}>
              Logout from all devices
            </Button>
          }
        </Row>
      </Form>
      <Popup {...popup} />
    </>
  )
}