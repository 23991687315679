import React, { useEffect, useState } from 'react';
import LoginTemplate from '../../templates/Login';
import Login from '../../modules/Login';
import ForgotPassword from '../../modules/ForgotPassword';
import OptpCode from '../../modules/OPTPCode';
import Country from '../../modules/Country';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../../routing/config/utils';

export default (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [flexCol, setFlexcol] = useState("0 0 536px")
  const auth = isLoggedIn();
  const isCountry = props?.Comp == "Country";

  useEffect(() => {
    if (auth && !isCountry) {
      navigate('/outlets');
    }
  }, []);

  return (
    <LoginTemplate load={loading} flexCol={flexCol} isCountry={isCountry}>
      {props.Comp == 'Login' && <Login setLoading={setLoading} />}
      {props.Comp == 'ForgotPassword' && <ForgotPassword load={setLoading} />}
      {props.Comp == 'OPTPCode' && <OptpCode load={setLoading} />}
      {props.Comp == 'Country' && <Country load={setLoading} setFlexcol={setFlexcol} />}
    </LoginTemplate>
  );
};
