import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Button, Form, Spin, Space, Typography } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountAvailabilities } from '../../../../../ducks/actions'
import { 
  removeDiscountAvailability,
  bulkRemoveDiscountAvailabilities,
  addDiscountAvailability,
  editDiscountAvailability
} from '../../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import FormGroup from 'Molecules/FormGroup';
import dayjs from 'dayjs';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues, clearErrors } = useForm();
  const data = useSelector((state) => state.pos.discountAvailabilities);
  const meta = useSelector((state) => state.pos.discountAvailabilitiesOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getDiscountAvailabilities(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);

  const colName = [
    {
      title: 'Access Code',
      dataIndex: 'access_code',
      key: 'access_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Valid Weekly Schedule',
      dataIndex: 'work_schedule_name',
      key: 'work_schedule_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Valid Start Date',
      dataIndex: 'valid_from',
      key: 'valid_from',
      className: 'enable-click',
      sorter: true,
      render: (text, record) => <span>{text} {record.valid_from_time}</span>
    },
    {
      title: 'Valid End Date',
      dataIndex: 'valid_to',
      key: 'valid_to',
      className: 'enable-click',
      sorter: true,
      render: (text, record) => <span>{text} {record.valid_to_time}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="Discount Availability"
          btnAction1={() => {
            clearErrors();
            setValue('id', record.id);
            setValue('access_code', record.access_code);
            setValue('work_schedule', record.work_schedule_id ? { label: record.work_schedule_name, value: record.work_schedule_id } : '');
            setValue('valid_from', record.valid_from ? dayjs(record.valid_from, "YYYY-MM-DD") : '');
            setValue('valid_to', record.valid_to ? dayjs(record.valid_to, "YYYY-MM-DD") : '');
            setValue('valid_from_time', record.valid_from_time ? dayjs(record.valid_from_time, "HH:mm") : '');
            setValue('valid_to_time', record.valid_to_time ? dayjs(record.valid_to_time, "HH:mm") : '');
            setVisible(true);
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        // allowedDelete={allowed([Roles.USERS], 'delete')}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'access_code',
      label: 'Access Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'work_schedule',
      label: 'Valid Weekly Schedule',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: _.map(meta?.work_schedules, (e) => ({ label: e.name, value: e.id })),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'valid_from',
      label: 'Valid Start Date',
      placeholder: 'Please select',
      req: true,
      type: 'date',
      reqmessage: 'Date required',
      twocol: false,
      format: 'YYYY-MM-DD',
      static: !allowedEdit,
      colWidth: '1 0 50%'
    },
    {
      name: 'valid_from_time',
      label: 'Valid Start Time',
      placeholder: 'Please Select',
      req: true,
      type: 'time',
      reqmessage: 'Time required',
      twocol: false,
      format: 'HH:mm',
      colWidth: '1 0 50%',
      static: !allowedEdit,
    },
    {
      name: 'valid_to',
      label: 'Valid End Date',
      placeholder: 'Please select',
      req: false,
      type: 'date',
      reqmessage: 'Date required',
      twocol: false,
      format: 'YYYY-MM-DD',
      colWidth: '1 0 50%',
      static: !allowedEdit,
    },
    {
      name: 'valid_to_time',
      label: 'Valid End Time',
      placeholder: 'Please select',
      req: false,
      type: 'time',
      reqmessage: 'Time required',
      twocol: false,
      format: 'HH:mm',
      colWidth: '1 0 50%',
      static: !allowedEdit,
    },
  ];

  const addNew = () => {
    setVisible(true);
    setValue('access_code', '');
    setValue('work_schedule', '');
    setValue('valid_from', '');
    setValue('valid_to', '');
    setValue('valid_from_time', '');
    setValue('valid_to_time', '');
    clearErrors();
  }

  const btnList = [
    {
      title: 'Are you sure to remove the selected Availabilies?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Availability',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  const onAction = async (recordId, status) => {
    props.setLoading(true);

    const payload = {
      discount_id: id,
      discount_availability_id: recordId,
      status_event: status === 'Active' ? 'deactivate' : 'activate'
    }

    await removeDiscountAvailability(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Discount Availability status has been updated successfully!");
        setTimeout(() => dispatch(getDiscountAvailabilities(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getDiscountAvailabilities(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getDiscountAvailabilities(pagination.current, pagination.pageSize, '', '', null, props.setLoading, id));
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (recordId) => {
    props.setLoading(true);

    const payload = {
      discount_id: id,
      discount_availability_id: recordId,
      status_event: 'remove'
    }

    await removeDiscountAvailability(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Discount Availability has been removed successfully!");
        setTimeout(() => dispatch(getDiscountAvailabilities(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      discount_id: id,
      discount_availabilities: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveDiscountAvailabilities(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Discount Availabilities has been removed successfully!");
        setTimeout(() => dispatch(getDiscountAvailabilities(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      discount_id: id,
      valid_from: dayjs(val?.valid_from).format("YYYY-MM-DD") + " " + dayjs(val?.valid_from_time).format("HH:mm"),
      valid_to: dayjs(val?.valid_to).format("YYYY-MM-DD") + " " + dayjs(val?.valid_to_time).format("HH:mm"),
      work_schedule_id: val?.work_schedule?.value,
      access_code: val?.access_code,
      discount_availability_id: val?.id
    }

    await (val?.id ? editDiscountAvailability : addDiscountAvailability)(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success("Discount Availability added successfully!");
        setVisible(false);
        dispatch(getDiscountAvailabilities(1, limit, '', '', searchVal, props.setLoading, id));
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message);
    })
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'Add'} Discount Availability
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('access_code', '');
              setValue('work_schedule', '');
              setValue('valid_from', '');
              setValue('valid_to', '');
              setValue('valid_from_time', '');
              setValue('valid_to_time', '');
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 625,
    onCancel: () => {
      setVisible(false);
      setValue('access_code', '');
      setValue('work_schedule', '');
      setValue('valid_from', '');
      setValue('valid_to', '');
      setValue('valid_from_time', '');
      setValue('valid_to_time', '');
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          clearErrors();
          setValue('id', record.id);
          setValue('access_code', record.access_code);
          setValue('work_schedule', record.work_schedule_id ? {label: record.work_schedule_name, value: record.work_schedule_id} : '');
          setValue('valid_from', record.valid_from ? dayjs(record.valid_from, "YYYY-MM-DD") : '');
          setValue('valid_to', record.valid_to ? dayjs(record.valid_to, "YYYY-MM-DD") : '');
          setValue('valid_from_time', record.valid_from_time ? dayjs(record.valid_from_time, "HH:mm") : '');
          setValue('valid_to_time', record.valid_to_time ? dayjs(record.valid_to_time, "HH:mm") : '');
          setVisible(true);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {/* <HeadingChip title={"Discount Availabilitys"} btnList={allowed([Roles.USERS], 'write') && btnList} btnHidden={selectedRowKeys.length <= 0 || !allowed([Roles.USERS], 'delete')} /> */}
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            ListData={data?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            pagination={{
              total: data?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}