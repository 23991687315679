import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Space, Button, Typography, message } from 'antd';
import Search from '../Search';
import ListCard from 'Molecules/ListCard';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

const addBlockItemCol = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		className: 'enable-click',
		sorter: (a,b) => a.name.localeCompare(b.name)
	},
	{
		title: 'Code',
		dataIndex: 'code',
		key: 'code',
		className: 'enable-click',
		sorter: (a,b) => a.code.localeCompare(b.code)
	},
];

export default (props) => {

	const { id, metalist, datalist, addBlockedProduct, setVisible, reloadApi, allowedEdit } = props;
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [data, setData] = useState([]);
	const [load, setLoad] = useState(false);

	const rowClassName = (record, index) => {
		return datalist?.product_ids?.some(x => x === record?.id) ? 'highlight-row' : '';
	};

	const onSelectChange = (newSelectedRowKeys) => {
			setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
			selectedRowKeys,
			onChange: onSelectChange,
	};

	const onSearch = (search) => {
		let searchInput = search.name;
		let temp = metalist?.products?.filter(x => x.name.toLowerCase().includes(searchInput.toLowerCase()) || x.code.toLowerCase().includes(searchInput.toLowerCase()));
		setData(temp);
	};

	useEffect(() => {
		if(metalist && 'products' in metalist) {
				setData(metalist.products);
			}
	}, [metalist]);

	const onFinish = async () => {
		setLoad(true);

		const payload = {
			id: id,
			product_ids: JSON.stringify(selectedRowKeys)
		}

		await addBlockedProduct(payload).then(res => {
		setLoad(false);
		if(res.data.code === 200) {
			message.success("Blocked Item added successfully.");
			setVisible(false);
			rowSelection.onChange([]);
			reloadApi();
		}else {
			message.error(res.data.message);
		}
		}).catch(e =>{
			setLoad(false);
			message.error(e.message ?? "Something went wrong");
		});
	}

	return (
		<Spin indicator={antIcon} size="large" spinning={load}>
			<Space direction='vertical' size={20} className='w-100'>
				<Title level={4} className='m-0'>
					Blocked Products
				</Title>
				<Row gutter={[10, 10]}>
					<Col span={24} className="clickRow">
						<ListCard
							listClass="nopad"
							ListData={data?.map(x => ({ ...x, key: x.id }))}
							ListCol={addBlockItemCol}
							rowSelection={rowSelection}
							pagination={true}
							scrolling={500}
							Search={Search}
							onSearch={onSearch}
							rowClassName={rowClassName}
						/>
					</Col>
				</Row>
				<Row gutter={10} justify={'center'}>
					<Col span={selectedRowKeys.length > 0 ? 12 : 24}><Button size='large' danger type="primary" className='w-100' onClick={() => {
						setVisible(false);
						rowSelection.onChange([]);
					}}>Cancel</Button></Col>
					{selectedRowKeys.length > 0 && allowedEdit && <Col span={12}><Button size='large' type="primary" className='green-btn w-100' onClick={() => onFinish()}>Confirm</Button></Col>}
				</Row>
			</Space>
		</Spin>
	)
}