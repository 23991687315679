import React, { Fragment } from 'react';

export default (props) => {
  const { columns, data } = props;

  return (
    <div>
      <div className='inventory-table-container'></div>
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.closing_stock_listing?.list?.map(item => 
                <th 
                  rowSpan={item?.children ? '' : 2} 
                  colSpan={item?.children ? item.children.length : ''} 
                  className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} 
                  key={`Title ${item.title}`}
                >
                  {item.title}
                </th>
              )}
            </tr>
            {columns?.closing_stock_listing?.list?.some(item => "children" in item) && 
              <tr>
                {columns?.closing_stock_listing?.list?.map(item => 
                  item?.children?.length > 0 ?
                    item.children.map(childItem => <th key={`${item.title} ${childItem.title}`}>{childItem.title}</th>)
                  : ''
                )}
              </tr>
            }
          </thead>
          <tbody>
            {data?.list?.map((x, listItemIndex) => 
              <Fragment key={`Child List Item ${listItemIndex}`}>
                {
                  x.list?.map((y, yIndex) => {
                    return (
                      <tr key={`Child Y Item ${yIndex}`}>
                        <td>{y.date}</td>
                        <td>{y.item}</td>
                        <td>{y.uom}</td>
                        <td align='right'>{y.closing_quantity}</td>
                        <td align='right'>{parseFloat(y.closing_cost).toFixed(8)}</td>
                        <td align='right'>{parseFloat(y.closing_unit_cost).toFixed(8)}</td>
                        <td align='right'>{parseFloat(y.closing_adjustment_cost).toFixed(8)}</td>
                        <td align='right'>{y.closing_adjustment_cost_perc}</td>
                      </tr>
                    )
                  })
                }
                {
                  x.list?.length > 1 &&
                  <tr className='bg-gray text-white fwbold'>
                    <td colSpan={2}></td>
                    <td>SubTotal</td>
                    {x?.sub_totals?.map((z, zIndex) => (
                      <td align='right' key={zIndex}>{z}</td>
                    ))}
                  </tr>
                }
              </Fragment>
            )}

            <tr className='bg-gray text-white'>
              <td colSpan={2}></td>
              <td>Grand Total</td>
              {data?.grand_total?.map((item, index) => 
                <td key={`Grand Total ${item} ${index}`} align='right'>{item}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}