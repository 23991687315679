import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Upload, Empty } from 'antd';
import BlockProduct from './components/BlockProduct';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from 'Molecules/FormGroup';
import { getBlockedProductList } from '../../../../ducks/actions'
import { addBlockedProduct, removeBlockedProduct, bulkRemoveBlockedProduct, editBlockedProduct } from '../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, meta, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const blockedProductListData = useSelector((state) => state.outlet.blockedProductListData);
  const blockedProductListMeta = useSelector((state) => state.outlet.blockedProductListMeta);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [load, setLoad] = useState(false);

  const colName = [
    {
      title: 'Product Code',
      dataIndex: 'product_code',
      key: 'product_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Product Desc',
      dataIndex: 'product_desc',
      key: 'product_desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Order Types',
      dataIndex: 'order_types',
      key: 'order_types',
      className: 'enable-click',
      // render: (text) => <span>{text?.map((x) => `${x.order_type_code} ${x.order_type_desc}`).join(", ") || "-"}</span>
      render: (text, record) => <span className='listing-pill'>{text?.map((item, index) => <span className='pill-item' key={`${record.id} ${index}`}>{item.order_type_code} {item.order_type_desc}</span>)}</span>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (text, record) =>
        <ActionButton
          title="Blocked Item"
          btnAction1={() => {
            setValue("id", record.id);
            setValue("name", record.product_desc);
            setValue("code", record.product_code);
            let temp = [];
            record?.order_types?.map(order_type => {
              temp.push({
                label: `${order_type.order_type_code} ${order_type.order_type_desc}`,
                value: order_type.order_type_id
              })
            })
            setValue('order_types', temp);
            setEditVisible(true);
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'code',
      label: 'Product Code',
      static: true,
      placeholder: 'Product Code..',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%'
    },
    {
      name: 'name',
      label: 'Product Desc',
      static: true,
      placeholder: 'Product Desc..',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%'
    },
    {
      type: 'select',
      label: 'Order Types',
      name: 'order_types',
      twocol: false,
      req: false,
      multiple: true,
      options: blockedProductListMeta?.order_types?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id })),
      placeholder: 'Please select',
      class: "default-select",
      colWidth: '0 1 100%',
      tips: "If blank input, the blocked item will be applied to all the order types",
      static: !allowedEdit
    },
  ]

  const addNew = () => { 
    setVisible(true);
  }

  const btnList = [
    {
      title: 'Are you sure to remove the selected Block Item(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Blocked Item',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if (activeTab === "6")
    dispatch(getBlockedProductList(1, limit, '', '', props.setLoading, id));
  }, [activeTab]);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getBlockedProductList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, props.setLoading, id));
    } else {
      dispatch(getBlockedProductList(pagination.current, pagination.pageSize, '', '', props.setLoading, id));
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const onRemove = async (recId) => {
    props.setLoading(true);

    const payload = {
      id: id,
      blocked_product_id: recId,
      status_event: 'remove'
    }

    await removeBlockedProduct(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Blocked Item has been removed successfully!");
        setTimeout(() => dispatch(getBlockedProductList(1, limit, '', '', props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      id: id,
      blocked_products: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveBlockedProduct(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Blocked Items has been removed successfully!");
        setTimeout(() => dispatch(getBlockedProductList(1, limit, '', '', props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const reloadApi = () => {
    dispatch(getBlockedProductList(1, limit, '', '', props.setLoading, id));
  }

  const onFinish = async (val) => {
    setLoad(true);
    const payload = {
      order_types: val?.order_types ? JSON.stringify(val?.order_types?.map(x => ({ id: x.value }))) : "[]",
      blocked_product_id: val?.id,
      id: id
    }
    await editBlockedProduct(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        setEditVisible(false);
        reloadApi();
        message.success("Blocked Item Edited Successfully.");
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? "Something went wrong")
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <BlockProduct allowedEdit={allowedEdit} reloadApi={reloadApi} setVisible={setVisible} id={id} addBlockedProduct={addBlockedProduct} datalist={blockedProductListData} metalist={blockedProductListMeta} />,
    width: 1000,
    onCancel: () => {
      setVisible(false);
    },
  };

  const popup2 = {
    closable: false,
    visibility: editVisible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Edit Blocked Item
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setEditVisible(false);
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setEditVisible(false);
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setValue("id", record.id);
          setValue("name", record.product_desc);
          setValue("code", record.product_code);
          let temp = [];
          record?.order_types?.map(order_type => {
            temp.push({
              label: `${order_type.order_type_code} ${order_type.order_type_desc}`,
              value: order_type.order_type_id
            })
          })
          setValue('order_types', temp);
          setEditVisible(true);
        }
      },
    };
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            listClass="nopad"
            ListData={blockedProductListData?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            onRow={onClickRow}
            ListCol={colName}
            rowSelection={rowSelection}
            pagination={{
              total: blockedProductListData?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
      <Popup {...popup2} />
    </>
  )
}