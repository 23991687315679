import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Button, message, Space, Spin, Form, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getIngredients } from '../ducks/actions'
import { removeIngredient, bulkRemoveIngredients, syncAllUomFromNetsuite } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import FormGroup from 'Molecules/FormGroup';
import dayjs from 'dayjs';

const antIcon = <LoadingOutlined spin />;
const { Title, Text } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ingredients = useSelector((state) => state.product.ingredients);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const allowedEdit = allowed([AllRoles.PRODUCT.INGREDIENT], 'write');
  const allowedDelete = allowed([AllRoles.PRODUCT.INGREDIENT], 'delete');
  const globalListQuery = useSelector((state) => state.global.listQueryItems);
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const colName = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'enable-click',
      sorter: true,
      render: (text, record) => record.is_semi_product !== 'No' ? `${text} - SEMI PRODUCT` : text
    },
    {
      title: 'Default Inv. Unit Cost',
      dataIndex: 'unit_cost',
      key: 'unit_cost',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Base UOM Code',
      dataIndex: 'base_uom_code',
      key: 'base_uom_code',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Inventory UOM Code',
      dataIndex: 'inventory_uom_code',
      key: 'inventory_uom_code',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Purchase UOM Code',
      dataIndex: 'purchase_uom_code',
      key: 'purchase_uom_code',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Ingredient"
          btnAction1={() => navigate(`/product/ingredient/${record.id}`)}
          recordId={record.id}
          onRemove={allowedDelete && record.editable == "1" && onRemove}
          setLoading={setLoading}
          loading={loading}
        />
    }
  ];

  const disabledDates = (current) => {
    return current && current > dayjs().endOf('day');
  }

  const formFields = [
    {
      name: 'date_from',
      label: 'Date From',
      req: true,
      placeholder: 'Please select',
      type: 'date',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Date From is Required'
    },
    {
      name: 'date_to',
      label: 'Date To',
      req: true,
      placeholder: 'Please select',
      type: 'date',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Date To is Required',
      disabledDate: disabledDates
    },
  ];

  const onSyncAll = async (val) => {
    setLoading(true);

    const payload = {
      date_from: val?.date_from ? dayjs(val?.date_from).format("MM/DD/YYYY") : "",
      date_to: val?.date_to ? dayjs(val?.date_to).format("MM/DD/YYYY") : ""
    }

    await syncAllUomFromNetsuite(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        setVisible(false);
        message.success(res.data.message);
      } else {
        message.error(res.data.message)
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message ?? "Something went wrong")
    })
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" onFinish={handleSubmit(onSyncAll)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Select Date Range
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => setVisible(false)}>Cancel</Button></Col>
            <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('date_from', '');
      setValue('date_to', '');
    },
  };

  const addNew = () => navigate('/product/ingredient/add');

  const btnList = [
    {
      title: 'Are you sure to remove the selected Ingredient(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: 'Sync All Ingredient UOM',
      classes: 'green-btn',
      action: () => {
        setValue("date_from", '');
        setValue("date_to", dayjs());
        setVisible(true);
      }
    },
    {
      text: '+ New Ingredient',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.ingredientListQuery) {
      dispatch(getIngredients(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('ingredientListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.ingredientListQuery
      dispatch(getIngredients(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      id: id,
      status_event: 'remove'
    }

    await removeIngredient(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Ingredient has been removed successfully!");
        setTimeout(() => dispatch(getIngredients(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('ingredientListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      ingredients: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveIngredients(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Ingredients has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getIngredients(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('ingredientListQuery', 1, limit, '', '', null, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getIngredients(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('ingredientListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getIngredients(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('ingredientListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        code: search.code
      };
      setSearchVal(searching);
      dispatch(getIngredients(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('ingredientListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getIngredients(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('ingredientListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
        navigate(`/product/ingredient/${record.id}`)
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Ingredients"} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.ingredientListQuery?.searchValue}
            onRow={onClickRow}
            // listClass="nopad"
            ListData={ingredients?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            onChange={onTableChange}
            pagination={{
              total: ingredients?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}