import React from 'react';
import { Tabs } from 'antd';
import * as TabCards from './tabList';

export default (props) => {
  const { setActiveTab, oneTab, searchParams } = props;
  const defaultActive = searchParams?.get('t');

  const tabs = [
    {
      name: "Major Group",
      Comp: 'Details',
    },
    {
      name: "Family Group",
      Comp: 'SubCategory',
    },
    {
      name: "Retail Group",
      Comp: 'FSubCategory',
    }
  ];

  return (
    <Tabs items={
      (oneTab ? [tabs[0]] : tabs).map((item, index) => {
        const Cardi = TabCards[item.Comp];
        return {
          key: (index + 1).toString(),
          label: item.name,
          forceRender: true,
          children: <Cardi {...props} />
        }
      })
    } defaultActiveKey={defaultActive || "1"} onChange={(activeKey) => setActiveTab(activeKey)} />
  );
};
