import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import Application from 'Modules/Application';
import NotFound from 'Modules/Application/NotFound';
import Denied from 'Modules/Application/Denied';
import Profile from 'Modules/Profile';

const Components = {
  Application,
  NotFound,
  Profile,
  Denied
};

export default (props) => {
  const DashboardComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <DashboardComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  )
};