import React from "react";
import Icon from '@ant-design/icons';

const DollerSVG = () => (
    <svg id="Group_20132" data-name="Group 20132" xmlns="http://www.w3.org/2000/svg" width="10.49" height="18.775" viewBox="0 0 10.49 18.775" fill="currentColor">
      <g id="Icons">
        <path id="Path_21495" data-name="Path 21495" d="M118.188,7.69c-1.93-.716-2.581-1.148-2.581-1.714,0-.661.612-1.025,1.725-1.025a6.85,6.85,0,0,1,2,.291,1.5,1.5,0,0,0,1.143-.119,1.452,1.452,0,0,0,.7-.9l.021-.081a1.541,1.541,0,0,0-1.114-1.871,9.98,9.98,0,0,0-1.884-.286V1.331a1.351,1.351,0,0,0-2.7,0v.845c-2.379.526-3.848,2.115-3.848,4.182,0,2.583,2.276,3.669,4.258,4.326,1.623.54,2.255,1.026,2.255,1.733,0,.727-.73,1.16-1.952,1.16a7.527,7.527,0,0,1-2.5-.444,1.457,1.457,0,0,0-1.146.081,1.408,1.408,0,0,0-.711.889l-.045.176a1.573,1.573,0,0,0,1.061,1.89,10.924,10.924,0,0,0,2.513.461v.814a1.351,1.351,0,0,0,2.7,0v-1c2.47-.493,4.054-2.181,4.054-4.344C122.144,9.974,120.959,8.653,118.188,7.69Z" transform="translate(-111.654)"/>
      </g>
    </svg>
  );

  const DollerIcon = props => <Icon component={DollerSVG} {...props} />;
  export default DollerIcon;

  
