import React from "react";
import Icon from '@ant-design/icons';

const ProgramSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.001" height="20" viewBox="0 0 20.001 20" fill="currentColor">
      <path d="M223.07,274.25a3.035,3.035,0,0,1,.45-.92,2.632,2.632,0,0,0,.61-2.17c-.29-.82-1.16-1.16-1.87-1.45a2.8,2.8,0,0,1-.89-.45,2.742,2.742,0,0,1-.19-.96,2.61,2.61,0,0,0-.85-2.13,2.937,2.937,0,0,0-2.36-.14,2.836,2.836,0,0,1-1.03.14,2.745,2.745,0,0,1-.7-.66,2.29,2.29,0,0,0-4.08,0,2.592,2.592,0,0,1-.7.66,2.861,2.861,0,0,1-1.03-.14,2.939,2.939,0,0,0-2.36.14,1.844,1.844,0,0,0-.71,1.09,7.619,7.619,0,0,0-.14,1.04,2.532,2.532,0,0,1-.19.96,2.841,2.841,0,0,1-.88.45c-.71.29-1.58.63-1.87,1.45a2.6,2.6,0,0,0,.61,2.17,3.215,3.215,0,0,1,.44.92,2.988,2.988,0,0,1-.44.92,2.608,2.608,0,0,0-.61,2.17,2.871,2.871,0,0,0,1.87,1.46,3.356,3.356,0,0,1,.88.45,2.532,2.532,0,0,1,.19.96,2.674,2.674,0,0,0,.85,2.13,2.9,2.9,0,0,0,2.36.13,2.61,2.61,0,0,1,1.03-.13,2.441,2.441,0,0,1,.7.66,2.281,2.281,0,0,0,4.08,0,1.172,1.172,0,0,1,1.73-.53,2.9,2.9,0,0,0,2.36-.13,1.772,1.772,0,0,0,.71-1.09,5.3,5.3,0,0,0,.14-1.04,2.742,2.742,0,0,1,.19-.96,3.287,3.287,0,0,1,.89-.45,2.871,2.871,0,0,0,1.87-1.46,2.641,2.641,0,0,0-.61-2.17A2.831,2.831,0,0,1,223.07,274.25Zm-3.74-1.87-.08.07-5.65,5.33a1.3,1.3,0,0,1-1.78.02l-.04-.02-2.63-2.48a1.152,1.152,0,0,1-.08-1.65l.08-.08a1.364,1.364,0,0,1,1.83,0l1.7,1.61,4.74-4.45a1.368,1.368,0,0,1,1.85,0A1.168,1.168,0,0,1,219.33,272.38Z" transform="translate(-204.204 -264.26)" />
    </svg>
  )

  const ProgramIcon = props => <Icon component={ProgramSVG} {...props} />;

  export default ProgramIcon;