import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Table, message, Empty } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryReportsMeta, getInventoryReports } from '../ducks/actions'
import { exportInventoryReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

// Tables
import {
  GoodReceiveDetails,
  GoodReceiveSummary,
  InventoryBalanceDaily,
  MaterialConsumption,
  ProductRecipe,
  PurchaseOrderSummary,
  PurchaseOrderDetails,
  StockAdjustmentDetails,
  StockMovement,
  StockTakeDetails,
  StockWastageDetails,
  StockWastageSummary,
  TransferDescrepancyDetails
} from './components/Tables';

const { Title, Text } = Typography;
const _ = require('lodash');

const reportTypeDropdown = [
  {
    label: "Material Consumption",
    value: 'material_consumption_listing',
    noStatus: true
  },
  {
    label: "Goods Receive Details",
    value: 'gr_details_listing',
  },
  {
    label: "Goods Receive Summary",
    value: 'gr_summary_listing',
  },
  {
    label: "Inventory Balance Daily (Closing Stock Listing)",
    value: 'closing_stock_listing',
    noStatus: true
  },
  {
    label: "Stock Adjustment Details",
    value: 'adj_details_listing',
  },
  {
    label: "Stock Movement",
    value: 'stock_movement_listing',
    noStatus: true
  },
  {
    label: "Stock Take Details",
    value: 'st_details_listing',
  },
  {
    label: "Stock Wastage Summary Listing",
    value: 'sw_summary_listing',
    isMultiOutlet: true
  },
  {
    label: "Transfer Descrepancy Details Listing",
    value: 'tr_descrepancy_details_listing',
  },
  {
    label: "Product Recipe",
    value: 'product_recipe_listing',
    noStatus: true
  },
  {
    label: "Stock Wastage Details Listing",
    value: 'sw_details_listing',
  },
  {
    label: "Purchase Order Summary Listing",
    value: 'po_summary_listing',
    isMultiOutlet: true
  },
  {
    label: "Purchase Order Details Listing",
    value: 'po_details_listing',
    isMultiOutlet: true,
    noStatus: true
  },
]

const columns = {
  material_consumption_listing: {
    list: [
      {
        title: 'Parent Item',
        dataIndex: 'item',
        align: 'left'
      },
      {
        title: 'Item Qty.',
        dataIndex: 'qty',
        align: 'right'
      },
      {
        title: 'Gross Sales',
        dataIndex: 'gross_sales',
        align: 'right'
      },
      {
        title: 'Gross Amount',
        dataIndex: 'gross_amount',
        align: 'right'
      },
      {
        title: 'Discount',
        dataIndex: 'discount_amount',
        align: 'right'
      },
      {
        title: 'Tax',
        dataIndex: 'tax_amount',
        align: 'right'
      },
      {
        title: 'Net Sales',
        dataIndex: 'net_sales',
        align: 'right'
      },
      {
        title: 'Net Amount',
        dataIndex: 'net_amount',
        align: 'right'
      },
      {
        title: 'Inv. Cost',
        dataIndex: 'cost',
        align: 'right'
      },
    ],
    nestedList: [
      {
        title: 'Item',
        dataIndex: 'item',
        align: "left"
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        align: "left"
      },
      {
        title: 'Tran Qty',
        dataIndex: 'qty',
        align: "right"
      },
      {
        title: 'Total Inv Cost',
        dataIndex: 'inv_cost',
        align: "right"
      },
    ],
    // isDetail: false,
    isNested: true,
    totalColSpan: 0,
    nestedColSpan: 4,
  },
  gr_details_listing: {
    list: [
      {
        title: 'Invoice No',
        dataIndex: 'stock_in_reference',
        longCol: false,
        align: "left"
      },
      {
        title: 'PO No',
        dataIndex: 'po_reference',
        longCol: false,
        align: "left"
      },
      {
        title: 'Supplier',
        dataIndex: 'supplier',
        longCol: true,
        align: "left"
      },
      {
        title: 'Status',
        dataIndex: 'status',
        longCol: false,
        align: "left"
      },
      {
        title: 'Item',
        dataIndex: 'item',
        longCol: true,
        align: "left"
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        longCol: false,
        align: "left"
      },
      {
        title: 'Doc Qty',
        dataIndex: 'qty',
        longCol: false,
        align: "right"
      },
      {
        title: 'Discrepancy Qty',
        dataIndex: 'discrepancy_qty',
        longCol: false,
        align: "right"
      },
      {
        title: 'Gross Amount',
        dataIndex: 'gross_amount',
        longCol: false,
        align: "right"
      },
      {
        title: 'Disc. Amount',
        dataIndex: 'discount_amount',
        longCol: false,
        align: "right"
      },
      {
        title: 'Total Amount',
        dataIndex: 'total_amount',
        longCol: false,
        align: "right"
      },
      {
        title: 'Total Inv. Cost',
        dataIndex: 'total_inv_cost',
        longCol: false,
        align: "right"
      },
      {
        title: 'Unit Cost',
        dataIndex: 'unit_cost',
        longCol: false,
        align: "right"
      },
    ],
    totalColSpan: 5,
    isDetail: true
  },
  gr_summary_listing: {
    list: [
      {
        title: 'Outlet',
        dataIndex: 'location_code',
        align: "left"
      },
      {
        title: 'GRN Document Date',
        dataIndex: 'date',
        align: "left"
      },
      {
        title: 'GRN Document No.',
        dataIndex: 'reference',
        align: "left"
      },
      {
        title: 'Supplier',
        dataIndex: 'supplier',
        align: "left"
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: "left"
      },
      {
        title: 'Doc. Qty',
        dataIndex: 'qty',
        align: "right"
      },
      {
        title: 'Gross Amount',
        dataIndex: 'gross_amount',
        align: "right"
      },
      {
        title: 'Discount Amount',
        dataIndex: 'discount_amount',
        align: "right"
      },
      {
        title: 'Net Amount',
        dataIndex: 'net_amount',
        align: "right"
      },
      {
        title: 'Total Inv. Cost',
        dataIndex: 'total_inv_cost',
        align: "right"
      },
    ],
    totalColSpan: 4,
    isDetail: false
  },
  closing_stock_listing: {
    // “Item”, “UOM”, “Bal. Qty”, “Bal. Inv. Cost”, “Unit Cost”, “Closing Margin (Inv. Cost)“, “Closing Margin % (Inv. Cost)”
    list: [
      {
        title: 'Date',
        dataIndex: 'date',
        align: 'left'
      },
      {
        title: 'Item',
        dataIndex: 'item',
        align: 'left'
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        align: 'left'
      },
      {
        title: 'Bal. Qty',
        dataIndex: 'closing_quantity',
        align: 'right'
      },
      {
        title: 'Bal. Inv. Cost',
        dataIndex: 'closing_cost',
        align: 'right'
      },
      {
        title: 'Unit Cost',
        dataIndex: 'closing_unit_cost',
        align: 'right'
      },
      {
        title: 'Closing Margin (Inv. Cost)',
        dataIndex: 'closing_adjustment_cost',
        align: 'right'
      },
      {
        title: 'Closing Margin % (Inv. Cost)',
        dataIndex: 'closing_adjustment_cost_perc',
        align: 'right'
      },
    ]
  },
  adj_details_listing: {
    list: [
      {
        title: 'Adj Date',
        dataIndex: 'date',
        align: 'left'
      },
      {
        title: 'Adj Document No.',
        dataIndex: 'reference',
        align: 'left'
      },
      {
        title: 'Item',
        dataIndex: 'item',
        align: 'left'
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        align: 'left'
      },
      {
        title: 'Adj. Qty',
        dataIndex: 'qty',
        align: 'right'
      },
      {
        title: 'Adj. Unit Cost',
        dataIndex: 'unit_cost',
        align: 'right'
      },
      {
        title: 'Adj Inv Cost',
        dataIndex: 'inv_cost',
        align: 'right'
      },
    ],
    isDetail: true,
    totalColSpan: 1,
    isNestedList: true
  },
  stock_movement_listing: {
    list: [
      {
        title: 'Item',
        dataIndex: 'product_code',
        align: 'left'
      },
      {
        title: 'UOM',
        dataIndex: 'name',
        align: 'left'
      },
      {
        title: 'Doc Date',
        dataIndex: 'name',
        align: 'left'
      },
      {
        title: 'Ledger ID',
        dataIndex: 'name',
        align: 'left'
      },
      {
        title: 'Doc No',
        dataIndex: 'name',
        align: 'left'
      },
      {
        title: 'Transaction',
        dataIndex: 'name',
        align: 'left'
      },
      {
        title: 'Status',
        dataIndex: 'name',
        align: 'left'
      },
      {
        title: 'Qty',
        dataIndex: 'name',
        align: 'right'
      },
      {
        title: 'Running Bal',
        dataIndex: 'name',
        align: 'right'
      },
      {
        title: 'Inv Cost',
        dataIndex: 'name',
        align: 'right'
      },
      {
        title: 'Running Inv Cost',
        dataIndex: 'name',
        align: 'right'
      },
    ]
  },
  st_details_listing: {
    list: [
      {
        title: 'Doc Date',
        dataIndex: 'docdate',
        align: 'left'
      },
      {
        title: 'Stock Take Doc. No.',
        dataIndex: 'doc_no',
        align: 'left'
      },
      {
        title: 'Item',
        dataIndex: 'item',
        align: 'left'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'left'
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        align: 'left'
      },
      {
        title: 'Doc. Qty.',
        dataIndex: 'qty',
        align: 'right'
      },
      {
        title: 'System Quantity',
        dataIndex: 'system_qty',
        align: 'right'
      },
      {
        title: 'Discrepancy Quantity',
        dataIndex: 'discrepancy_qty',
        align: 'right'
      },
      {
        title: 'Unit Cost',
        dataIndex: 'unit_cost',
        align: 'right'
      },
      {
        title: 'Total Inv. Cost',
        dataIndex: 'inv_cost',
        align: 'right'
      },
    ],
    totalColSpan: 2,
    isNestedList: true
  },
  sw_summary_listing: {
    list: [
      {
        title: 'Doc No',
        dataIndex: 'reference',
        align: 'left'
      },
      {
        title: 'Outlet',
        dataIndex: 'location_code',
        align: 'left'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'left'
      },
      {
        title: 'Doc Qty',
        dataIndex: 'qty',
        align: 'right'
      },
      {
        title: 'Unit Cost',
        dataIndex: 'unit_cost',
        align: 'right'
      },
      {
        title: 'Total Inv Cost',
        dataIndex: 'inv_cost',
        align: 'right'
      },
    ],
    totalColSpan: 2,
    isDetail: true
  },
  tr_descrepancy_details_listing: {
    list: [
      {
        title: 'Transfer Doc No',
        dataIndex: 'reference',
        align: 'left'
      },
      {
        title: 'Origin',
        dataIndex: 'origin',
        align: 'left'
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
        align: 'left'
      },
      {
        title: 'Item',
        dataIndex: 'item',
        align: 'left'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'left'
      },
      {
        title: 'Doc Qty',
        dataIndex: 'qty',
        align: 'right'
      },
      {
        title: 'Confirmed Qty',
        dataIndex: 'confirm_qty',
        align: 'right'
      },
      {
        title: 'Total Inv Cost (Out)',
        dataIndex: 'out_inv_cost',
        align: 'right'
      },
      {
        title: 'Total Inv Cost (Receive)',
        dataIndex: 'in_inv_cost',
        align: 'right'
      },

    ],
    totalColSpan: 4,
    isDetail: true
  },
  product_recipe_listing: {
    list: [
      {
        title: 'Parent Item',
        dataIndex: 'product_code',
        align: "left"
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        align: "left"
      },
      {
        title: 'Item Count',
        dataIndex: 'item_count',
        align: "right"
      },
    ],
    nestedList: [
      {
        title: 'Item',
        dataIndex: 'item',
        align: "left"
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        align: "left"
      },
      {
        title: 'Recipe Rate',
        dataIndex: 'recipe_rate',
        align: "right"
      },
      {
        title: 'Yield Perc',
        dataIndex: 'yield_perc',
        align: "right"
      },
      {
        title: 'Item Qty',
        dataIndex: 'item_qty',
        align: "right"
      },
    ],
    totalColSpan: 1,
    nestedColSpan: 4,
    isNested: true
  },
  sw_details_listing: {
    list: [
      {
        title: 'Doc No',
        dataIndex: 'reference',
        align: 'left'
      },
      {
        title: 'Doc Date',
        dataIndex: 'date',
        align: 'left'
      },
      {
        title: 'Outlet',
        dataIndex: 'location_code',
        align: 'left'
      },
      {
        title: 'Item',
        dataIndex: 'item',
        align: 'left'
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        align: 'left'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'left'
      },
      {
        title: 'Doc Qty',
        dataIndex: 'qty',
        align: 'right'
      },
      {
        title: 'Unit Cost',
        dataIndex: 'unit_cost',
        align: 'right'
      },
      {
        title: 'Total Inv Cost',
        dataIndex: 'inv_cost',
        align: 'right'
      },
    ],
    totalColSpan: 5,
    isDetail: false,
  },
  po_summary_listing: {
    list: [
      {
        title: 'PO No',
        dataIndex: 'reference',
        align: 'left'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'left'
      },
      {
        title: 'Supplier',
        dataIndex: 'vendor_code',
        align: 'left'
      },
      {
        title: 'Order Qty',
        dataIndex: 'qty',
        align: 'right'
      },
      {
        title: 'Gross Amount',
        dataIndex: 'gross_amount',
        align: 'right'
      },
      {
        title: 'Disc. Amount',
        dataIndex: 'discount_amount',
      },
      {
        title: 'Tax Amount',
        dataIndex: 'tax_amount',
        align: 'right'
      },
      {
        title: 'Net Amount',
        dataIndex: 'amount',
        align: 'right'
      },
      {
        title: 'Total Inv Cost',
        dataIndex: 'inv_cost',
        align: 'right'
      },
      {
        title: 'Received Qty',
        dataIndex: 'received_qty',
        align: 'right'
      },
      {
        title: 'Outstanding Qty',
        dataIndex: 'outstanding_qty',
        align: 'right'
      },
    ],
    totalColSpan: 2,
    isDetail: true
  },
  po_details_listing: {
    list: [
      {
        title: 'Date',
        dataIndex: 'date',
        align: 'left'
      },
      {
        title: 'SKU',
        dataIndex: 'code',
        align: 'left'
      },
      {
        title: 'Month',
        dataIndex: 'month',
        align: 'left'
      },
      {
        title: 'Year',
        dataIndex: 'year',
        align: 'left'
      },
      {
        title: 'Store',
        dataIndex: 'store',
        align: 'left'
      },
      {
        title: 'Product Category',
        dataIndex: 'product_category',
        align: 'left'
      },
      {
        title: 'Purchase Order',
        dataIndex: 'reference',
        align: 'left'
      },
      {
        title: 'Vendor',
        dataIndex: 'vendor',
        align: 'left'
      },
      {
        title: 'Item',
        dataIndex: 'name',
        align: 'left'
      },
      {
        title: 'Base Unit',
        dataIndex: 'base_uom_desc',
        align: 'left'
      },
      {
        title: 'Unit',
        dataIndex: 'uom_desc',
        align: 'left'
      },
      {
        title: 'Cost/Unit',
        dataIndex: 'unit_cost',
        align: 'right'
      },
      {
        title: 'Total Quantity',
        dataIndex: 'quantity',
        align: 'right'
      },
    ]
  },
}

export default (props) => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reporting.inventoryReport);
  const meta = useSelector((state) => state.reporting.inventoryReportMeta);
  const [searchVal, setSearchVal] = useState(null);

  // Filter UI
  const [reportType, setReportType] = useState(null);
  const [isMultiOutlet, setIsMultiOutlet] = useState(false);
  const [dateFromOnly, setDateFromOnly] = useState(false);
  const [stockMovementFilter, setStockMovementFilter] = useState(false);
  const [trDescList, setTrDescList] = useState(false);
  const [productRecipeFilter, setProductRecipeFilter] = useState(false);
  const [materialCon, setMaterialCon] = useState(false);

  // Meta Dropdown data
  const [outlets, setOutlets] = useState([]);
  const [statuses, setStatuses] = useState(false);
  const [products, setProducts] = useState([]);
  const [ingredients, setIngredients] = useState([]);

  const inventoryReport = reports?.list || []

  useEffect(() => {
    dispatch(getInventoryReportsMeta());
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = meta?.locations.map(x => ({ label: `${x.code} ${x.desc}`, value: x.id }))

        setOutlets(temp);
      }

      if ("statuses" in meta) {
        let temp = meta?.statuses.map(x => ({ label: x, value: x }))
        temp = [{ label: "All Status", value: "" }, ...temp]

        setStatuses(temp);
      }

      if ("ingredients" in meta) {
        let temp = meta?.ingredients.map(x => ({ label: `${x.code} ${x.name}`, value: x.id }))

        setIngredients(temp);
      }

      if ("products" in meta) {
        let temp = meta?.products.map(x => ({ label: `${x.code} ${x.name}`, value: x.id }))

        setProducts(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    // if (search && 'outlets' in search) {
    let searching = {};

    searching = {
      report_type: search?.report_type.value,
      date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
      date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : null,
      status: search?.status?.value
    };

    if (search?.report_type.value === "stock_movement_listing" || search?.report_type.value === "po_details_listing") {
      let temp = [];
      if (search?.products?.length > 0) {
        search?.products?.map(x => {
          temp.push(x.value)
        });
      }
      searching["products"] = temp;
    }

    if (isMultiOutlet) {
      if (search?.outlet.length && search?.outlet.length > 0) searching.location_ids = JSON.stringify(search?.outlet?.map(x => x.value))
    } else {
      searching.location_id = search?.outlet?.value
    }

    if (search?.report_type.value === "tr_descrepancy_details_listing") {
      searching = {
        report_type: search?.report_type.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : null,
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : null,
      };
    }

    setSearchVal(searching);
    dispatch(getInventoryReports(searching, props.setLoading, isMultiOutlet, search?.report_type.value, message));
    setReportType(search?.report_type.value)
    // } else {
    //   setSearchVal(null);
    //   dispatch(getInventoryReports(null, props.setLoading));
    // }
  };

  const exportReport = async (search) => {
    if (reports?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    let searching = {};

    searching = {
      report_type: search?.report_type.value,
      date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
      date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : null,
      status: search?.status?.value
    };

    if (search?.report_type.value === "stock_movement_listing" || search?.report_type.value === "po_details_listing") {
      let temp = [];
      if (search?.products?.length > 0) {
        search?.products?.map(x => {
          temp.push(x.value)
        });
      }
      searching["products"] = temp;
    }

    if (isMultiOutlet) {
      if (search?.outlet.length && search?.outlet.length > 0) searching.location_ids = search?.outlet?.map(x => x.value)
    } else {
      searching.location_id = search?.outlet?.value
    }

    if (search?.report_type.value === "tr_descrepancy_details_listing") {
      searching = {
        report_type: search?.report_type.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : null,
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : null,
      };
    }

    setReportType(search?.report_type.value)
    props.setLoading(true);

    await exportInventoryReport(searching, reportType).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Inventory Report"} />
        </Col>

        <Col span={24}>
          <Card bordered={false}>
            <Search
              field1={reportTypeDropdown}
              field2={outlets}
              field3={statuses}
              field4={products}
              field5={ingredients}
              meta={meta}
              onSearch={onSearch}
              exportReport={exportReport}
              isMultiOutlet={isMultiOutlet}
              setIsMultiOutlet={setIsMultiOutlet}
              dateFromOnly={dateFromOnly}
              setDateFromOnly={setDateFromOnly}
              stockMovementFilter={stockMovementFilter}
              setStockMovementFilter={setStockMovementFilter}
              trDescList={trDescList}
              setTrDescList={setTrDescList}
              productRecipeFilter={productRecipeFilter}
              setProductRecipeFilter={setProductRecipeFilter}
              materialCon={materialCon}
              setMaterialCon={setMaterialCon}
              setStatuses={setStatuses}
            />

            {(reportType && inventoryReport && Object.keys(inventoryReport).length > 0) ?
              <>
                {reportType === 'material_consumption_listing' && <MaterialConsumption columns={columns} data={reports} />}
                {reportType === 'gr_details_listing' && <GoodReceiveDetails columns={columns} data={reports} />}
                {reportType === 'gr_summary_listing' && <GoodReceiveSummary columns={columns} data={reports} />}
                {reportType === 'adj_details_listing' && <StockAdjustmentDetails columns={columns} data={reports} />}
                {reportType === 'sw_details_listing' && <StockWastageDetails columns={columns} data={reports} />}
                {reportType === 'sw_summary_listing' && <StockWastageSummary columns={columns} data={reports} />}
                {reportType === 'st_details_listing' && <StockTakeDetails columns={columns} data={reports} />}
                {reportType === 'tr_descrepancy_details_listing' && <TransferDescrepancyDetails columns={columns} data={reports} />}
                {reportType === 'product_recipe_listing' && <ProductRecipe columns={columns} data={reports} />}
                {reportType === 'po_summary_listing' && <PurchaseOrderSummary columns={columns} data={reports} />}
                {reportType === 'po_details_listing' && <PurchaseOrderDetails columns={columns} data={reports} />}
                {reportType === 'stock_movement_listing' && <StockMovement columns={columns} data={reports} />}
                {reportType === 'closing_stock_listing' && <InventoryBalanceDaily columns={columns} data={reports} />}
              </>
              :
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
          </Card>
        </Col>
      </Row>
    </>
  )
}
