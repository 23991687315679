import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';

const _ = require('lodash');

export default (props) => {

  const { errors, control, options, allowedEdit, onRevoke, allowedSave } = props

  const formFields = [
    {
      name: 'store_name',
      label: 'Name',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
    },
    {
      name: 'email',
      label: 'Email',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      nospace: true,
      email: true,
      static: !allowedSave
    },
    {
      name: 'merchant_type',
      label: 'Merchant',
      req: true,
      type: 'select',
      options: options?.merchant_types?.map((x) => ({ label: x?.toUpperCase(), value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedSave
    },
    {
      name: 'slug',
      label: 'Subscription ID',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      hidden: allowedEdit,
      static: true,
    },
    {
      name: 'api_key',
      label: 'API Key',
      type: 'password',
      // static: true,
      twocol: false,
      colWidth: "0 1 100%",
      onBtnClick: () => { 
        onRevoke()
      },
      addOnButton: "Revoke",
      readOnly: true,
      class: "readonly",
      hidden: allowedEdit,
      static: !allowedSave
    }
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}