import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { getOrderType } from "../../ducks/actions";
import { editOrderType } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import { getFileName } from 'Features/utility';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm();
  const data = useSelector((state) => state.product.orderType);
  const meta = useSelector(state => state.product.orderTypeOption);
  const [fileId, setFileId] = useState();
  const [fileList, setFileList] = useState([]);
  const allowedEdit = allowed([AllRoles.PRODUCT.ORDERTYPE], 'write');

  useEffect(() => {
    dispatch(getOrderType(id, props.setLoading));
  }, []);

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('code', data.code);
      setValue('desc', data.desc);
      setValue('is_fa', data.is_fa === "1");
      setValue('tax_condition', data.tax_condition_id ? {label: `${data.tax_condition_code} - ${data.tax_condition_desc}`, value: data.tax_condition_id} : '');
      setValue('order_type_group', data.order_type_group_id ? { label: `${data.order_type_group_code} - ${data.order_type_group_desc}`, value: data.order_type_group_id} : '');
      if (data?.image_url) {
        setFileList([{ uid: '-1', name: getFileName(data?.image_url), status: 'done', url: data?.image_url }]);
      } else {
        setFileList([]);
      }
    }
  }, [data])

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      code: val.code,
      desc: val.desc,
      tax_condition_id: val?.tax_condition?.value,
      order_type_group_id: val?.order_type_group?.value,
      is_fa: val?.is_fa ? "1" : "0",
      blob_id: fileId,
      order_type_id: id
    }
    await editOrderType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Order Type updated successfully!");
        setTimeout(() => navigate("/product/order-type"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>Edit Order Type</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                {
                  allowedEdit &&
                  <Button size="large" htmlType='submit' className='green-btn'>Save</Button>
                }
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/product/order-type')}>{'<'} Back</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <InputForm
            control={control}
            errors={errors}
            fileList={fileList}
            setFileList={setFileList}
            setFileId={setFileId}
            setLoading={props.setLoading}
            meta={meta}
            allowedEdit={allowedEdit}
          />
        </Col>
      </Row>
    </Form>
  )
}