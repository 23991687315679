import AllRoles from './AllRoles';

const titles = {
  Inventory: 'Inventory',
};

export const Inventory = [
  {
    component: 'Vendor',
    path: '/inventory/vendor',
    title: titles.Inventory,
    key: 'vendor',
    menu: 'Inventory',
    submenu: 'Vendor',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.VENDOR],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddVendor',
    path: '/inventory/vendor/add',
    title: titles.Inventory,
    key: 'vendor',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.VENDOR],
    parent: false,
  },
  {
    component: 'EditVendor',
    path: '/inventory/vendor/:id',
    title: titles.Inventory,
    key: 'vendor',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.VENDOR],
    parent: false,
  },
  {
    component: 'PurchaseOrder',
    path: '/inventory/purchase-order',
    title: titles.Inventory,
    key: 'purchase-order',
    menu: 'Inventory',
    submenu: 'Purchase Order',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.PURCHASE_ORDER],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddPurchaseOrder',
    path: '/inventory/purchase-order/add',
    title: titles.Inventory,
    key: 'purchase-order',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.PURCHASE_ORDER],
    parent: false,
  },
  {
    component: 'EditPurchaseOrder',
    path: '/inventory/purchase-order/:id',
    title: titles.Inventory,
    key: 'purchase-order',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.PURCHASE_ORDER],
    parent: false,
  },
  {
    component: 'GoodReceive',
    path: '/inventory/good-receive',
    title: titles.Inventory,
    key: 'good-receive',
    menu: 'Inventory',
    submenu: 'Good Receive',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.GOOD_RECEIVE],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddGoodReceive',
    path: '/inventory/good-receive/add',
    title: titles.Inventory,
    key: 'good-receive',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.GOOD_RECEIVE],
    parent: false,
  },
  {
    component: 'EditGoodReceive',
    path: '/inventory/good-receive/:id',
    title: titles.Inventory,
    key: 'good-receive',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.GOOD_RECEIVE],
    parent: false,
  },
  {
    component: 'TransferOut',
    path: '/inventory/transfer-out',
    title: titles.Inventory,
    key: 'transfer-out',
    menu: 'Inventory',
    submenu: 'Transfer Out',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.TRANSFER_OUT],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddTransferOut',
    path: '/inventory/transfer-out/add',
    title: titles.Inventory,
    key: 'transfer-out',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.TRANSFER_OUT],
    parent: false,
  },
  {
    component: 'EditTransferOut',
    path: '/inventory/transfer-out/:id',
    title: titles.Inventory,
    key: 'transfer-out',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.TRANSFER_OUT],
    parent: false,
  },
  {
    component: 'TransferIn',
    path: '/inventory/transfer-in',
    title: titles.Inventory,
    key: 'transfer-in',
    menu: 'Inventory',
    submenu: 'Transfer Receive',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.TRANSFER_RECEIVE],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddTransferIn',
    path: '/inventory/transfer-in/add',
    title: titles.Inventory,
    key: 'transfer-in',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.TRANSFER_RECEIVE],
    parent: false,
  },
  {
    component: 'EditTransferIn',
    path: '/inventory/transfer-in/:id',
    title: titles.Inventory,
    key: 'transfer-in',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.TRANSFER_RECEIVE],
    parent: false,
  },
  {
    component: 'StockWastage',
    path: '/inventory/stock-wastage',
    title: titles.Inventory,
    key: 'stock-wastage',
    menu: 'Inventory',
    submenu: 'Stock Wastage',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_WASTE],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddStockWastage',
    path: '/inventory/stock-wastage/add',
    title: titles.Inventory,
    key: 'stock-wastage',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_WASTE],
    parent: false,
  },
  {
    component: 'EditStockWastage',
    path: '/inventory/stock-wastage/:id',
    title: titles.Inventory,
    key: 'stock-wastage',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_WASTE],
    parent: false,
  },
  {
    component: 'StockAdjustment',
    path: '/inventory/stock-adjustment',
    title: titles.Inventory,
    key: 'stock-adjustment',
    menu: 'Inventory',
    submenu: 'Stock Adjustment',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_ADJUSTMENT],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddStockAdjustment',
    path: '/inventory/stock-adjustment/add',
    title: titles.Inventory,
    key: 'stock-adjustment',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_ADJUSTMENT],
    parent: false,
  },
  {
    component: 'EditStockAdjustment',
    path: '/inventory/stock-adjustment/:id',
    title: titles.Inventory,
    key: 'stock-adjustment',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_ADJUSTMENT],
    parent: false,
  },
  // temporary hide
  {
    component: 'StockConversion',
    path: '/inventory/stock-conversion',
    title: titles.Inventory,
    key: 'stock-conversion',
    menu: 'Inventory',
    submenu: 'Stock Conversion',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_CONVERSION],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddStockConversion',
    path: '/inventory/stock-conversion/add',
    title: titles.Inventory,
    key: 'stock-conversion',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_CONVERSION],
    parent: false,
  },
  {
    component: 'EditStockConversion',
    path: '/inventory/stock-conversion/:id',
    title: titles.Inventory,
    key: 'stock-conversion',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_CONVERSION],
    parent: false,
  },
  {
    component: 'StockTemplate',
    path: '/inventory/stock-template',
    title: titles.Inventory,
    key: 'stock-template',
    menu: 'Inventory',
    submenu: 'Stock Template',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_TEMPLATE],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddStockTemplate',
    path: '/inventory/stock-template/add',
    title: titles.Inventory,
    key: 'stock-template',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_TEMPLATE],
    parent: false,
  },
  {
    component: 'EditStockTemplate',
    path: '/inventory/stock-template/:id',
    title: titles.Inventory,
    key: 'stock-template',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_TEMPLATE],
    parent: false,
  },
  {
    component: 'StockTake',
    path: '/inventory/stock-take',
    title: titles.Inventory,
    key: 'stock-take',
    menu: 'Inventory',
    submenu: 'Stock Take',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_TAKE],
    parent: true,
    parenticon: 'FacultyIcon',
  },
  {
    component: 'AddStockTake',
    path: '/inventory/stock-take/add',
    title: titles.Inventory,
    key: 'stock-take',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_TAKE],
    parent: false,
  },
  {
    component: 'EditStockTake',
    path: '/inventory/stock-take/:id',
    title: titles.Inventory,
    key: 'stock-take',
    menu: 'Inventory',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_TAKE],
    parent: false,
  },
  {
    component: 'StockMovement',
    path: '/inventory/stock-movement',
    title: titles.Inventory,
    key: 'stock-movement',
    menu: 'Inventory',
    submenu: 'Stock Movement',
    icon: 'OverviewIcon',
    permission: [AllRoles.INVENTORY.STOCK_MOVEMENT],
    parent: true,
    parenticon: 'FacultyIcon',
  },
]