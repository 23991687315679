import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Typography, Button, Space, message } from 'antd';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetail } from '../../ducks/actions'
import { editUser, editUserOC } from '../../ducks/services'
import { useForm } from 'react-hook-form';
import AddForm from '../components/AddForm';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';

const { Title, Text } = Typography;

export default (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.systems.user);
  const meta = useSelector((state) => state.systems.option);
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation().pathname;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    callApi()
  }, []);

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const callApi = () => {
    dispatch(getUserDetail(id, props.setLoading))
  };

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  }

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>Edit {data && data.name}</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/ems/users')}>{'<'} Back</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          setLoading={props.setLoading}
          errors={errors}
          control={control}
          meta={meta}
          activeTab={activeTab}
          setActiveTab={setActive}
          data={data}
          setValue={setValue}
          searchParams={searchParams}
          allowedEdit={allowed([AllRoles.SYSTEM.USERS], 'write')}
          handleSubmit={handleSubmit}
          editUserOC={editUserOC}
          mode={"edit"}
          id={id}
          reloadApi={callApi}
        />
      </Col>
    </Row>
  )
}