import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Empty, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getDailySummary } from '../ducks/actions'
import { exportDailySummaryReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.dailySummary);
  const meta = useSelector((state) => state.reporting.dailySummaryOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [scrollingWidth, setScrollingWidth] = useState(1000);

  const colName = [
    {
      title: 'Outlet',
      dataIndex: 'location_code',
      key: 'location_code',
      width: 250,
      className: 'valign-btm'
    },
    {
      title: 'Business Date',
      dataIndex: 'report_date',
      key: 'report_date',
      width: 250,
      className: 'valign-btm'
    },
    {
      title: <span className='text-bold'>Sales</span>,
      children: [
        {
          title: 'Gross Sales',
          dataIndex: 'sub_total',
          key: 'sub_total',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Discount',
          dataIndex: 'discount_amount',
          key: 'discount_amount',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Net Amount',
          dataIndex: 'net_amount',
          key: 'net_amount',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Inclusive Tax',
          dataIndex: 'inclusive_tax_amount',
          key: 'inclusive_tax_amount',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Net Sales',
          dataIndex: 'net_sales',
          key: 'net_sales',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Exclusive Tax',
          dataIndex: 'exclusive_tax_amount',
          key: 'exclusive_tax_amount',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Voucher Amount',
          dataIndex: 'voucher_amount',
          key: 'voucher_amount',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Bill Rounding',
          dataIndex: 'adjustment_amount',
          key: 'adjustment_amount',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Total Sales',
          dataIndex: 'amount',
          key: 'amount',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        }
      ]
    },
    {
      title: <span className='text-bold'>Statistics</span>,
      hidden: !searchVal?.statistics,
      children: [
        {
          title: 'Trans. Count',
          dataIndex: 'transaction_count',
          key: 'transaction_count',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Guest Count',
          dataIndex: 'total_pax',
          key: 'total_pax',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'ATV (Net Sales)',
          dataIndex: 'atv_sales',
          key: 'atv_sales',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'ATV (Gross Sales)',
          dataIndex: 'atv_gross',
          key: 'atv_gross',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Cust. Avg. Trans.',
          dataIndex: 'avg_trans',
          key: 'avg_trans',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        }
      ],
    },
    {
      title: <span className='text-bold'>Tender</span>,
      hidden: !searchVal?.tender,
      children: meta?.childrens?.find(x => x.header === "payment_method")?.children?.map(x => {
        return {
          title: x.label,
          dataIndex: x.value,
          key: x.value,
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        }
      })
    },
    {
      title: <span className='text-bold'>Tax</span>,
      hidden: !searchVal?.tax,
      children: [
        {
          title: 'SST-Malaysia SST',
          dataIndex: 'sst',
          key: 'sst',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        }
      ],
    },
    {
      title: <span className='text-bold'>Order Type</span>,
      hidden: !searchVal?.order_type,
      // the rest to follow this standard
      children: meta?.childrens?.find(x => x.header === "order_type_desc")?.children?.map(x => {
        return {
          title: x.label,
          dataIndex: x.value,
          key: x.value,
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        }
      })
    },
    {
      title: <span className='text-bold'>Voided Sales</span>,
      hidden: !searchVal?.voided_sales,
      children: [
        {
          title: 'New',
          dataIndex: 'new_sales',
          key: 'new_sales',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        },
        {
          title: 'Save & Sent',
          dataIndex: 'save_sales',
          key: 'save_sales',
          width: 200,
          align: 'right',
          className: 'valign-btm',
          render: (text) => <span>{comma(text)}</span>
        }
      ]
    },
    {
      title: <span className='text-bold'>Category</span>,
      hidden: !searchVal?.category,
      children: [
        {
          title: 'Burger & Sandwich',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'bns_quantity',
              key: 'bns_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'bns_net_total',
              key: 'bns_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Burger & Sandwich (Take Away)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'take_away_quantity',
              key: 'take_away_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'take_away_net_total',
              key: 'take_away_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Beverage',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'beverage_quantity',
              key: 'beverage_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'beverage_net_total',
              key: 'beverage_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Beverage (Take Away)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'beverage_tw_quantity',
              key: 'beverage_tw_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'beverage_tw_net_total',
              key: 'beverage_tw_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Campaign',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'campaign_quantity',
              key: 'campaign_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'campaign_net_total',
              key: 'campaign_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Campaign (O2O Dine In)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'campaign_dine_in_quantity',
              key: 'campaign_dine_in_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'campaign_dine_in_net_total',
              key: 'campaign_dine_in_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Campaign (O2O Take Away)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'campaign_tw_quantity',
              key: 'campaign_tw_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'campaign_tw_net_total',
              key: 'campaign_tw_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Combo Addon (O2O Delivery)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'combo_quantity',
              key: 'combo_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'combo_net_total',
              key: 'combo_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Dessert',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'dessert_quantity',
              key: 'dessert_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'dessert_net_total',
              key: 'dessert_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Plastic Bag',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'plastic_bag_quantity',
              key: 'plastic_bag_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'plastic_bag_net_total',
              key: 'plastic_bag_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Rice Box',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'ricebox_quantity',
              key: 'ricebox_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'ricebox_net_total',
              key: 'ricebox_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Rice Box (Take Away)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'ricebox_tw_quantity',
              key: 'ricebox_tw_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'ricebox_tw_net_total',
              key: 'ricebox_tw_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Salad (O2O Take Away)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'salad_tw_quantity',
              key: 'salad_tw_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'salad_tw_net_total',
              key: 'salad_tw_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Seafood',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'seafood_quantity',
              key: 'seafood_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'seafood_net_total',
              key: 'seafood_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Sides',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'sides_quantity',
              key: 'sides_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'sides_net_total',
              key: 'sides_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Sides (Take Away)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'sides_tw_quantity',
              key: 'sides_tw_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'sides_tw_net_total',
              key: 'sides_tw_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Signature',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'signature_quantity',
              key: 'signature_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'signature_net_total',
              key: 'signature_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Signature (O2O Dine In)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'signature_dine_in_quantity',
              key: 'signature_dine_in_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'signature_dine_in_net_total',
              key: 'signature_dine_in_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
        {
          title: 'Signature (Take Away)',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'signature_tw_quantity',
              key: 'signature_tw_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'signature_tw_net_total',
              key: 'signature_tw_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            }
          ]
        },
      ]
    },
    {
      title: <span className='text-bold'>Department</span>,
      hidden: !searchVal?.department,
      children: [
        {
          title: 'Delivery',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'delivery_quantity',
              key: 'delivery_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'delivery_net_total',
              key: 'delivery_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
          ]
        },
        {
          title: 'Dine In',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'dine_in_quantity',
              key: 'dine_in_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
            {
              title: 'Net Total',
              dataIndex: 'dine_in_net_total',
              key: 'dine_in_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
          ]
        },
        {
          title: 'Take Away',
          children: [
            {
              title: 'Quantity',
              dataIndex: 'takeaway_quantity',
              key: 'takeaway_quantity',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>

            },
            {
              title: 'Net Total',
              dataIndex: 'takeaway_net_total',
              key: 'takeaway_net_total',
              width: 200,
              align: 'right',
              className: 'valign-btm',
              render: (text) => <span>{comma(text)}</span>
            },
          ]
        }
      ],
    }
  ];

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getDailySummary(searchVal, props.setLoading));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      let temp = [];
      if(search?.outlets?.length > 0) {
        search?.outlets?.map(x => {
          temp.push(x.value)
        });
      }
      let temp2 = Object.entries(search).filter(([key, value]) => value === true);
      let obj = {};
      temp2.forEach(d => {
        obj[d[0]] = d[1];
      })
      searching = {
        ...obj,
        outlets: temp,
        is_non_sales: search?.non_sales?.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
        checkedCount: ''
      };
      // let ddd = search?.checkedCount;
      // setScrollingWidth((1000 + (ddd * 200)));
      setSearchVal(searching);
      dispatch(getDailySummary(searching, props.setLoading));
    } else {
      setSearchVal(null);
      setScrollingWidth(1000);
      dispatch(getDailySummary(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportDailySummaryReport(searchVal).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Daily Summary Report"} btnList={allowed([AllRoles.REPORTING.DAILY_SUMMARY], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Search onSearch={onSearch} field1={outlets} />

            <div className="table-responsive" style={{ overflowX: 'auto', marginTop: "30px" }}>
              <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th rowSpan={2} style={{minWidth: "50px"}}>Outlet</th>
                    <th colSpan={10} className='text-center'>Sales</th>
                    {
                      searchVal?.tender &&
                      <th colSpan={meta?.childrens?.find(x => x.header === "payment_method")?.children?.length} className='text-center'>Tender</th>
                    }
                    {
                      searchVal?.order_type &&
                      <th colSpan={meta?.childrens?.find(x => x.header === "order_type_desc")?.children?.length} className='text-center'>Order Type</th>
                    }
                  </tr>
                  <tr>
                    <th style={{minWidth: "50px"}}>Business Date</th>
                    <th style={{ minWidth: "120px"}} className='text-right'>Gross Sales</th>
                    <th style={{minWidth: "100px"}} className='text-right'>Discount</th>
                    <th style={{minWidth: "100px"}} className='text-right'>Net Amount</th>
                    <th style={{minWidth: "100px"}} className='text-right'>Inclusive Tax</th>
                    <th style={{minWidth: "100px"}} className='text-right'>Net Sales</th>
                    <th style={{minWidth: "100px"}} className='text-right'>Exclusive Tax</th>
                    <th style={{minWidth: "100px"}} className='text-right'>Voucher Amount</th>
                    <th style={{minWidth: "100px"}} className='text-right'>Bill Rounding</th>
                    <th style={{minWidth: "100px"}} className='text-right'>Total Sales</th>
                    {
                      searchVal?.tender &&
                      meta?.childrens?.find(x => x.header === "payment_method")?.children?.map(x => (
                        <th className='text-right' style={{ minWidth: "100px" }}>{x.label}</th>
                      ))
                    }
                    {
                      searchVal?.order_type &&
                      meta?.childrens?.find(x => x.header === "order_type_desc")?.children?.map(x => (
                        <th className='text-right' style={{ minWidth: "100px" }}>{x.label}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    orders?.list?.length <= 0 &&
                    <tr>
                      <td colSpan="11">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                  {
                    orders?.list?.length > 0 &&
                    orders?.list?.map((x, ind) => {
                      return (
                        <Fragment key={ind}>
                          {
                            x.list?.map((y, yIndex) => {
                              return (
                                <tr key={yIndex}>
                                  <td style={{minWidth: "50px"}}>{y.location_code}</td>
                                  <td style={{ minWidth: "120px" }}>{y.report_date}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.sub_total)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.discount_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.net_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.inclusive_tax_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.net_sales)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.exclusive_tax_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.voucher_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.adjustment_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.amount)}</td>
                                  {
                                    searchVal?.tender &&
                                    meta?.childrens?.find(b => b.header === "payment_method")?.children?.map(c => (
                                      <td align="right" style={{ minWidth: "100px" }}>{y[c.value]}</td>
                                    ))
                                  }
                                  {
                                    searchVal?.order_type &&
                                    meta?.childrens?.find(b => b.header === "order_type_desc")?.children?.map(c => (
                                      <td align="right" style={{ minWidth: "100px" }}>{y[c.value]}</td>
                                    ))
                                  }
                                </tr>
                              )
                            })
                          }
                          {
                            x.list?.length > 1 &&
                            <tr className='bg-gray text-white fwbold'>
                              <td colSpan={2}>SubTotal</td>
                              {x?.sub_totals?.map((z, zIndex) => (
                                <td align='right' key={zIndex}>{z}</td>
                              ))}
                            </tr>
                          }
                        </Fragment>
                      )
                    })
                  }
                  <tr className='bg-gray fwbold text-white'>
                    {
                      orders?.list?.length <= 0
                        ? <td colSpan="12">Grand Total</td>
                        : <td align='' colSpan="2">Grand Total</td>
                    }
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <td key={index} align='right'>
                            {comma(data)}
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>

          {/* <ListCard
            scrolling={scrollingWidth}
            Search={Search}
            onSearch={onSearch}
            ListData={orders?.list?.map((x, ind) => ({ ...x, key: ind }))}
            // onChange={onTableChange}
            ListCol={colName?.filter(x => !x.hidden )}
            field1={outlets}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row className='bg-gray'>
                    <Table.Summary.Cell className='text-white text-bold' index={0} colSpan={2}>
                      <Text>Grand Total</Text>
                    </Table.Summary.Cell>
                    {orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                      return (
                        <Table.Summary.Cell key={index} className='text-white text-right text-bold' index={index + 2}>
                          <Text>{comma(data)}</Text>
                        </Table.Summary.Cell>
                      )
                    })}
                  </Table.Summary.Row>
                </>
              )
            }}
            pagination={false}
          /> */}
        </Col>
      </Row>
    </>
  )
}