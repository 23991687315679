import byebugInterceptor from './axiosInterceptor';
import { loginID } from '../configs/constants';
const byebugApiMethod = process.env.REACT_APP_EXTERNAL_IP;
const authApi = byebugApiMethod + '/oauth/token';

const getQueryString = (data = {}) => {
  return Object.entries(data)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
};

export const authentications = (username, password) => {
  const data = {
    grant_type: 'password',
    client_id: loginID,
    redirect_url: 'https://getpostman.com/oauth2/callback',
    username: username,
    password: password,
  };
  const postData = getQueryString(data);
  return byebugInterceptor.post(authApi, postData);
};

