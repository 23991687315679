import React, { useEffect, useState } from 'react';
import { Row, Col, Button, message, Space, Popconfirm } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getKitchenQueues } from '../ducks/actions'
import { removeKitchenQueue, bulkRemoveKitchenQueues } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.pos.kitchenQueues);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const allowedEdit = allowed([AllRoles.POS.KITCHEN_QUEUE], 'write');
  const allowedDelete = allowed([AllRoles.POS.KITCHEN_QUEUE], 'delete');
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {/* {
            // allowed([Roles.USERS], 'write') &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          } */}
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Kitchen Queue"
          btnAction1={() => navigate(`/pos/kitchen-queue/${record.id}`)}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  const addNew = () => navigate('/pos/kitchen-queue/add');

  const btnList = [
    {
      title: 'Are you sure to remove the selected Kitchen Queue(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Kitchen Queue',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.kitchenQueueListQuery) {
      dispatch(getKitchenQueues(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('kitchenQueueListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.kitchenQueueListQuery
      dispatch(getKitchenQueues(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAction = async (recordId, status) => {
    props.setLoading(true);

    const payload = {
      kitchen_queue_id: recordId,
      status_event: status === 'Active' ? 'deactivate' : 'activate'
    }

    await removeKitchenQueue(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Kitchen Queue status has been updated successfully!");
        setTimeout(() => dispatch(getKitchenQueues(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('kitchenQueueListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      kitchen_queue_id: id,
      status_event: 'remove'
    }

    await removeKitchenQueue(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Kitchen Queue has been removed successfully!");
        setTimeout(() => dispatch(getKitchenQueues(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('kitchenQueueListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      kitchen_queues: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveKitchenQueues(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Kitchen Queues has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getKitchenQueues(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('kitchenQueueListQuery', 1, limit, '', '', null, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getKitchenQueues(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('kitchenQueueListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getKitchenQueues(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('kitchenQueueListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        name: search.code
      };
      setSearchVal(searching);
      dispatch(getKitchenQueues(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('kitchenQueueListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getKitchenQueues(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('kitchenQueueListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
          navigate(`/pos/kitchen-queue/${record.id}`)
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Kitchen Queues"} btnList={allowedEdit && btnList} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.kitchenQueueListQuery?.searchValue}
            ListData={data?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            onChange={onTableChange}
            pagination={{
              total: data?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}