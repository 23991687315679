import React, { useEffect, useState } from 'react';
import { Row, Col, Table } from 'antd';
import ListCard from 'Molecules/ListCard';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuSyncLogs } from '../../../../../ducks/actions'

export default (props) => {
  const { id, activeTab } = props;
  const dispatch = useDispatch();
  const logs = useSelector((state) => state.product.menuLogs);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const colName = [
    {
      title: 'Sync No.',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Timestamp',
      dataIndex: 'created_at',
      key: 'created_at'
    }
  ];

  const expandedRowRender = (record) => {
    const colName = [
      {
        title: 'FA Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Outlets (Success)',
        dataIndex: 'success',
        key: 'success',
        render: (text, record) => {
          return (
            <>
              <div>
                Success: {text?.count || "0"}
              </div>
              <div>
                Outlets: {text?.location_ids?.join(",") || "-"}
              </div>
            </>
          )
        }
      },
      {
        title: 'Outlets (Failed)',
        dataIndex: 'failed',
        key: 'failed',
        render: (text, record) => {
          return (
            <>
              <div>
                Failed: {text?.count || "0"}
              </div>
              <div>
                Outlets: {text?.location_ids?.join(",") || "-"}
              </div>
            </>
          )
        }
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        render: (text) => <span className={text === "Completed" ? "c-success" : "c-pending"}>{text}</span>
      }
    ];

    return <Table columns={colName} dataSource={record?.logs?.map((x) => ({ ...x, key: x.id }))} pagination={false} />;
  }

  useEffect(() => {
    if (activeTab === "5")
      dispatch(getMenuSyncLogs(1, limit, '', '', props.setLoading, id));
  }, [activeTab]);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getMenuSyncLogs(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, props.setLoading, id));
    } else {
      dispatch(getMenuSyncLogs(pagination.current, pagination.pageSize, '', '', props.setLoading, id));
    }
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <ListCard
            ListData={logs?.list?.map((x, ind) => ({ ...x, key: ind.toString() }))}
            ListCol={colName}
            onChange={onTableChange}
            expandable={{
              expandedRowRender,
              defaultExpandedRowKeys: ["0"],
            }}
            pagination={{
              total: logs?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}