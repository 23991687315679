import * as action_types from './constants';

const initialState = {
  customers: {},
  customer: {},
  customerOption: {},
  salesOrders: {},
  salesOrdersOption: {}
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.CRM_CUSTOMER_LIST:
      return { ...state, customers: data };
    case action_types.CRM_CUSTOMER_DETAIL:
      return { ...state, customer: data, customerOption: meta };
    case action_types.CRM_CUSTOMER_OPTION:
      return { ...state, customerOption: data };
    case action_types.CRM_SALES_ORDER_LIST:
      return { ...state, salesOrders: data, salesOrdersOption: meta };

    default:
      return state;
  }
};
