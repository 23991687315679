import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getActivities } from '../../../../../ducks/actions'

export default (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const activities = useSelector((state) => state.pos.activities);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);

  const colName = [
    {
      title: 'Datetime',
      dataIndex: 'date',
      key: 'date',
      sorter: true
    },
    {
      title: 'Type',
      dataIndex: 'activity_type',
      key: 'activity_type',
      sorter: true
    },
    {
      title: 'Source',
      dataIndex: 'source_name',
      key: 'source_name',
      sorter: true
    },
    {
      title: 'Cashier',
      dataIndex: 'user_name',
      key: 'user_name',
      sorter: true
    }
  ];

  useEffect(() => {
    dispatch(getActivities(1, limit, '', '', props.setLoading, id));
  }, []);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getActivities(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, props.setLoading, id));
    } else {
      dispatch(getActivities(pagination.current, pagination.pageSize, '', '', props.setLoading, id));
    }
  }

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <ListCard
          ListData={activities?.list?.map(x => ({ ...x, key: x.id }))}
          onChange={onTableChange}
          ListCol={colName}
          pagination={{
            total: activities?.total_count,
            current: page,
            pageSize: limit
          }}
        />
      </Col>
    </Row>
  )
}