import React, { useEffect, useState } from 'react';
import { Row, Col, message, Spin, Form, Space, Typography, Button } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getUploadFiles } from '../ducks/actions'
import Search from './components/Search';
// import AllRoles from '../../../routing/config/AllRoles';
// import { allowed } from '../../../routing/config/utils';

export default (props) => {
  const dispatch = useDispatch();
  const uploadFileList = useSelector((state) => state.outlet.uploadFileList);
  const uploadFileListMeta = useSelector((state) => state.outlet.uploadFileListMeta);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const colName = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true
    },
    {
      title: 'Filename',
      dataIndex: 'filename',
      key: 'filename',
      sorter: true,
      render: (text, record) => <a href={record.url} target='_blank'>{text}</a>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text) => <span className={text === "Active" ? "c-success" : "c-danger"}>{text == "Active" ? "Success" : "Failed"}</span>
    }
  ];

  useEffect(() => {
    dispatch(getUploadFiles(1, limit, '', '', null, props.setLoading));
  }, []);

  useEffect(() => {
    if (uploadFileListMeta) {
      if ('locations' in uploadFileListMeta) {
        let temp = [];
        uploadFileListMeta?.locations?.map((item) => {
          temp.push({
            label: `${item.code} ${item.desc}`,
            value: item.id
          })
        });
        setOutlets(temp);
      }

      if ("statuses" in uploadFileListMeta) {
        let temp2 = [];
        uploadFileListMeta.statuses?.map((x, ind) => {
          if (ind === 0) {
            temp2.push({
              label: "All Status",
              value: ""
            });
          }
          temp2.push({
            label: x,
            value: x
          })
        });
        setStatuses(temp2);
      }
    }
  }, [uploadFileListMeta])

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getUploadFiles(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
    } else {
      dispatch(getUploadFiles(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        outlet: search?.outlet?.value,
        status: search?.status?.value
      };
      setSearchVal(searching);
      dispatch(getUploadFiles(1, limit, '', '', searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getUploadFiles(1, limit, '', '', null, props.setLoading));
    }
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Mall Sales"} />
        </Col>
        <Col span={24}>
          <ListCard
            Search={Search}
            onSearch={onSearch}
            ListData={uploadFileList?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            field1={outlets}
            field2={statuses}
            pagination={{
              total: uploadFileList?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}