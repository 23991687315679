import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Space, Typography, Spin, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { InputField, SelectField } from 'Atoms/FormElement';
import { getFurtherSubCategories } from '../../../../../ducks/actions';
import { addFurtherSubCategory, editFurtherSubCategory, removeFurtherSubCategory, bulkRemoveFurtherSubCategories } from '../../../../../ducks/services'
import ListCard from 'Molecules/ListCard';
import ActionButton from 'Molecules/ActionButton';
import HeadingChip from 'Molecules/HeadingChip'
import Search from './components/Search';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { id, activeTab, allowedEdit, allowedDelete } = props;
  const dispatch = useDispatch();
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const furtherSubCategory = useSelector((state) => state.product.furtherSubCategory);
  const meta = useSelector((state) => state.product.furtherSubCategoryMeta);
  const [searching, setSearching] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [cates, setSubCates] = useState([]);
  const [load, setLoad] = useState(false);

  const ListCol = [
    {
      title: 'Code',
      dataIndex: 'code',
      className: 'enable-click',
      key: 'code',
      sorter: true
    },
    {
      title: 'Description',
      className: 'enable-click',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: 'Family Group',
      dataIndex: 'sub_category_code',
      className: 'enable-click',
      key: 'sub_category_code',
      sorter: true
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) =>
        <ActionButton
          title="Retail Group"
          btnAction1={() => {
            setValue('id', record.id);
            setValue('sub_category', { label: record?.sub_category_code, value: record?.sub_category_id });
            setValue('code', record?.code);
            setValue('name', record?.name);
            setVisible(true);
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  useEffect(() => {
    if (activeTab && activeTab === "3") {
      callApi();
    }
  }, [activeTab]);

  useEffect(() => {
    if (meta && 'sub_categories' in meta) {
      let temp = [];
      meta.sub_categories?.map((x, i) => {
        if (i == 0) {
          temp.push({ label: 'All Group', value: '' });
          temp.push({ label: x.code, value: x.id });
        } else {
          temp.push({ label: x.code, value: x.id });
        }
      });
      setSubCates(temp);
    }
  }, [meta]);

  const callApi = () => {
    dispatch(getFurtherSubCategories(1, limit, '', '', null, props.setLoading, id));
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getFurtherSubCategories(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searching, props.setLoading, id));
    } else {
      dispatch(getFurtherSubCategories(pagination.current, pagination.pageSize, '', '', searching, props.setLoading, id));
    }
  }

  const onSearch = (value) => {
    setPage(1);
    if (value) {
      let searchVal = {
        sub_category: value?.sub_category?.value
      };
      setSearching(searchVal);
      dispatch(getFurtherSubCategories(1, limit, '', '', searchVal, props.setLoading, id));
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onFinish = async (val) => {
    setLoad(true);
    const payload = {
      category_id: id,
      sub_category_id: val?.sub_category?.value,
      code: val?.code,
      name: val?.name,
      further_subcategory_id: val?.id
    }

    val?.id ?
      await editFurtherSubCategory(payload).then(res => {
        setLoad(false);
        if (res.data.code === 200) {
          message.success("Retail Gruop updated successfully.");
          setVisible(false);
          setTimeout(() => callApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoad(false);
        message.error(e.message ?? "Something went wrong");
      }) :
      await addFurtherSubCategory(payload).then(res => {
        setLoad(false);
        if (res.data.code === 200) {
          message.success("Retail Gruop added successfully.");
          setVisible(false);
          setTimeout(() => callApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoad(false);
        message.error(e.message ?? "Something went wrong");
      });
  };

  const onRemove = async (cid) => {
    props.setLoading(true);

    const payload = {
      further_subcategory_id: cid,
      category_id: id,
      status_event: 'remove'
    }

    await removeFurtherSubCategory(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Removed successfully!");
        callApi();
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      category_id: id,
      further_subcategories: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveFurtherSubCategories(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Selected Categories has been removed successfully!");
        callApi();
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? 'Edit' : 'Add'} Retail Gruop
          </Title>
          <InputField
            fieldname='id'
            label=''
            class='d-none'
            initValue={''}
            control={control}
          />
          <SelectField
            isRequired={visible}
            fieldname='sub_category'
            label='Sub Category'
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Please select', isDisabled: !allowedEdit }}
            selectOption={meta?.sub_categories?.map(x => ({ label: x.code, value: x.id }))}
            rules={{
              required: 'Sub Category is required',
            }}
            validate={errors.sub_category && 'error'}
            validMessage={errors.sub_category && errors.sub_category.message}
          />
          <InputField
            isRequired={visible}
            fieldname='code'
            label='Code'
            class='mb-0 w-100'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Please enter', disabled: !allowedEdit }}
            rules={{
              required: 'Code is required',
            }}
            validate={errors.code && 'error'}
            validMessage={errors.code && errors.code.message}
          />
          <InputField
            isRequired={visible}
            fieldname='name'
            label='Description'
            class='mb-0 w-100'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Please enter', disabled: !allowedEdit }}
            rules={{
              required: 'Description is required',
            }}
            validate={errors.name && 'error'}
            validMessage={errors.name && errors.name.message}
          />
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
    },
  };

  const addNew = () => {
    setVisible(true);
    setValue('id', null);
    setValue('sub_category', null);
    setValue('code', '');
    setValue('name', '');
  }

  const btnList = [
    {
      title: 'Are you sure to remove the selected Retail Group?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ Add New',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")){
          setValue('id', record.id);
          setValue('sub_category', { label: record?.sub_category_code, value: record?.sub_category_id });
          setValue('code', record?.code);
          setValue('name', record?.name);
          setVisible(true);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <HeadingChip title={"Retail Group"} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>

        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={Search && onSearch}
            ListCol={ListCol}
            ListData={furtherSubCategory?.list?.map(x => ({...x, key: x.id})) || []}
            onTableChange={onTableChange}
            field1={cates}
            pagination={{
              count: furtherSubCategory?.total_count,
              page: page,
              limit: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  );
}