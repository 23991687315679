import React from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField, SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import DateRangeDropdown from 'Modules/Application/components/DateRangeDropdown';

export default (props) => {
  const { control, handleSubmit, setValue } = useForm()

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  const clearProps = () => {
    setValue('reference', '');
    setValue('start_date', '');
    setValue('end_date', '');
    setValue('location', '');
    setValue('product', '');
    setValue('dateRange', '');
    props.onSearch({});
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <SelectField
            fieldname='location'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Outlet' }}
            selectOption={props.field1}
          />
        </Col>

        <Col flex="auto">
          <SelectField
            fieldname='product'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Product' }}
            selectOption={props.field2}
          />
        </Col>

        <DateRangeDropdown control={control} onSetValue={setValue} placeholderValue='Business Date' isRequired={false} />

        <Col flex="auto">
          <InputField
            fieldname='reference'
            label=''
            class='mb-0 w-100'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Search by Doc No..' }}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' danger size='large' type='primary' htmlType='button' onClick={() => clearProps()}>Clear</Button>
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
    </Form>
  )
}