import React, { useState, useEffect } from 'react';
import Dashboard from '../../templates/Dashboard';
import Outlet from 'Modules/Outlet';
import AddOutlet from 'Modules/Outlet/AddOutlet';
import EditOutlet from 'Modules/Outlet/EditOutlet';
import OutletGroup from 'Modules/Outlet/OutletGroup';
import AddOutletGroup from 'Modules/Outlet/OutletGroup/AddOutletGroup';
import EditOutletGroup from 'Modules/Outlet/OutletGroup/EditOutletGroup';
import UploadFile from 'Modules/Outlet/UploadFile';

const Components = {
    Outlet,
    AddOutlet,
    EditOutlet,
    OutletGroup,
    AddOutletGroup,
    EditOutletGroup,
    UploadFile
};

export default (props) => {
    const OutletComp = Components[props.Comp];
    const [loading, setLoading] = useState(false);

    // TO BE CONTINUE remain pagination state
    // useEffect(() => {
    //     // if(!globalListQuery.outletListQuery) {
    //     // dispatch(getOutlets(1, limit, '', '', null, props.setLoading));
    //     // // dispatch(getListQueryItems('outletListQuery', 1, limit, '', '', null))
    //     // } else {
    //     // const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.outletListQuery
    //     // dispatch(getOutlets(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
    //     // setPage(page);
    //     // setLimit(limit);
    //     // setSearchVal(searchValue);
    //     // }
    //     console.log('test', props.Comp)
    // }, [OutletComp]);

    return (
        <Dashboard load={loading} setLoading={setLoading}>
            <OutletComp loading={loading} setLoading={setLoading} />
        </Dashboard>
    );
};