import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Spin, message } from 'antd';
import AddForm from '../components/AddForm';
import { getStockTemplate, reloadStockTemplate } from "../../ducks/actions";
import { editStockTemplate } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';

const { Title, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation().pathname;
  const data = useSelector((state) => state.inventory.stockTemplate);
  const meta = useSelector((state) => state.inventory.stockTemplateOption);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [load, setLoad] = useState(false);
  const allowedEdit = allowed([AllRoles.INVENTORY.STOCK_TEMPLATE], 'write');
  const allowedDelete = allowed([AllRoles.INVENTORY.STOCK_TEMPLATE], 'delete');

  useEffect(() => {
    callApi();
  }, []);

  const callApi = () => {
    dispatch(getStockTemplate(id, setLoad));
  };

  const reloadApi = (data) => {
    dispatch(reloadStockTemplate(data, meta));
  }

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  }

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[20, 30]}>
        <Col span={24} style={{ position: "sticky", top: "-30px", backgroundColor: "#f5f5f5", zIndex: 3, padding: "10px" }}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>
                {data?.code}
                {" (Status: "}
                <span className={data?.status === "Active" ? "c-success" : "c-danger"}>
                  {data?.status?.[0]?.toUpperCase()}{data?.status?.slice(1)}
                </span>
                {")"}
              </Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/inventory/stock-template')}>{'<'} Back</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AddForm
            loading={props.loading}
            setLoading={setLoad}
            activeTab={activeTab}
            setActiveTab={setActive}
            data={data}
            meta={meta}
            id={id}
            editStockTemplate={editStockTemplate}
            reloadApi={reloadApi}
            searchParams={searchParams}
            mode={'edit'}
            navigate={navigate}
            allowedEdit={allowedEdit}
            allowedDelete={allowedDelete}
          />
        </Col>
      </Row>
    </Spin>
  )
}