import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Space, message, Empty } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getTaxChargesDetails } from '../ducks/actions'
import { exportSalesReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.taxChargesDetails);
  const meta = useSelector((state) => state.reporting.taxChargesDetailsOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [products, setProducts] = useState([]);
  const [itemProducts, setItemProducts] = useState([]);

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getTaxChargesDetails(null, props.setLoading, true));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
      if ("set_products" in meta) {
        let temp = [];
        meta.set_products?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.name}`,
            value: x.id
          })
        });
        setProducts(temp);
      }
    }
    if ("item_products" in meta) {
      let temp = [];
      meta.item_products?.map((x, ind) => {
        temp.push({
          label: `${x.code} - ${x.name}`,
          value: x.id
        })
      });
      setItemProducts(temp);
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      let temp = [];
      if (search?.products?.length > 0) {
        search?.products?.map(x => {
          temp.push(x.value)
        });
      }
      searching = {
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
        products: temp,
        is_condiment_required: search?.is_condiment_required?.value,
      };
      if (search?.outlets?.length > 0) {
        let temp2 = [];
        search?.outlets?.map(x => {
          temp2.push(x.value)
        });
        searching["location_ids"] = JSON.stringify(temp2);
      }
      setSearchVal(searching);
      dispatch(getTaxChargesDetails(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getTaxChargesDetails(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (!orders?.total_count || orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportSalesReport(searchVal, "tax_charges_details").then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  // test this
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {/* update roles to TAX_CHARGES_DETAILS, create new permission */}
          <HeadingChip title={"Tax Charges Details Report"} btnList={allowed([AllRoles.REPORTING.SALES_DETAILS], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Space direction={'vertical'} size={20} style={{width: "100%"}}>
              <Search onSearch={onSearch} field1={outlets} field2={products} field3={[{
                label: "By Set",
                value: 1
              }, {
                label: "By Item",
                value: 0
              }]} field4={itemProducts} />

              <div className="table-responsive" style={{ overflowX: 'auto' }}>
                <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th className=''>Date</th>
                      <th className=''>Outlet</th>
                      <th className=''>Item</th>
                      {/* <th className=''>Tax</th> */}
                      <th className=''>Tax Condition</th>
                      <th className='text-right'>Order Qty.</th>
                      <th className='text-right'>Gross Amount</th>
                      <th className='text-right'>Discount</th>
                      <th className='text-right'>Net Sales</th>
                      <th className='text-right'>Taxable Amount</th>
                      <th className='text-right'>Tax Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      orders?.list?.length <= 0 &&
                      <tr>
                        <td colSpan="11">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    }
                    {
                      orders?.list?.length > 0 &&
                      orders?.list?.map((x, ind) => {
                        return (
                          <Fragment key={`List ${ind}`}>
                            {
                              x.list?.map((y, yIndex) => {
                                return (
                                  <Fragment key={`Inner List ${yIndex}`}>
                                    <tr key={ind}>
                                      <td>{x.date}</td>
                                      <td>{y.location_code} {y.location_desc}</td>
                                      <td>{y.code}-{y.name}</td>
                                      {/* <td>{y.tax_code}-{y.tax_desc}</td> */}
                                      <td>{y.tax_condition_code}-{y.tax_condition_desc}</td>
                                      <td align='right'>{comma(y.order_qty)}</td>
                                      <td align='right'>{comma(parseFloat(y.gross_amount).toFixed(2))}</td>
                                      <td align='right'>{comma(parseFloat(y.discount_amount).toFixed(2))}</td>
                                      <td align='right'>{comma(parseFloat(y.net_sales).toFixed(2))}</td>
                                      <td align='right'>{comma(parseFloat(y.taxable_amount).toFixed(2))}</td>
                                      <td align='right'>{comma(parseFloat(y.tax_amount).toFixed(2))}</td>
                                    </tr>
                                  </Fragment>
                                )
                              })
                            }
                            <tr className='bg-gray text-white fwbold'>
                              <td align='' colSpan="4">SubTotal</td>
                              {
                                x?.sub_totals && Object.entries(x?.sub_totals)?.map(([key, data], zIndex) => {
                                  return (
                                    <td key={zIndex} align='right'>{comma(data)}</td>
                                  )
                                })
                              }
                            </tr>
                          </Fragment>
                        )
                      })
                    }
                    <tr className='bg-gray fwbold text-white'>
                      {
                        orders?.list?.length <= 0
                          ? <td colSpan="10">Grand Total</td>
                          : <td align='' colSpan="4">Grand Total</td>
                      }
                      {
                        orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                          return (
                            <td key={index} align='right'>
                              {comma(data)}
                            </td>
                          )
                        })
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  )
}