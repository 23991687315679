import React, { useState, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListCard from 'Molecules/ListCard';
import Search from './components/Search';
import { getAvailableProducts } from '../../../../../ducks/actions';
import { updateDiscountProductList } from '../../../../../ducks/services';

const filters = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'active',
  }
];

export default (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { activeTab, data, reloadApi, allowedEdit, allowedDelete } = props;
  const [filterVal, setFilterVal] = useState(filters[0]?.value);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchVal, setSearchVal] = useState(null);
  const availableProduct = useSelector((state) => state.pos.discountAvProducts);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // products
  const ListCol2 = [
    {
      title: 'Product Code',
      dataIndex: 'code',
      key: 'code',
      sorter: true,
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    }
  ];

  const rowClassName = (record, index) => {
    // discount to return product_ids (discount_products.where(is_blocked: false, status: :active).pluck(:product_id))
    return data?.allowed_product_ids?.some(x => x === record?.id) ? 'highlight-row' : '';
  };

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getAvailableProducts(filterVal, pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getAvailableProducts(filterVal, pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  };

  const onSearch = (search) => {
    if (search) {
      let searchVal = {};
      searchVal = {
        product: search.name
      };
      setSearchVal(searchVal);
      dispatch(getAvailableProducts(filterVal, page, limit, '', '', searchVal, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getAvailableProducts(filterVal, page, limit, '', '', null, props.setLoading, id));
    }
  };

  useEffect(() => {
    if (activeTab === "4") {
      setPage(1);
      dispatch(getAvailableProducts(filterVal, 1, limit, '', '', searchVal, props.setLoading, id));
    }
  }, [activeTab, filterVal]);

  const onFilter = (e) => {
    setFilterVal(e.target.value);
  };

  const btnList = [
    {
      title: 'Are you sure to "Allow" the selected product(s)?',
      text: 'Allow',
      action: () => onUpdateList("allow"),
      classes: 'green-btn',
      permit: true,
      placement: "leftTop"
    },
    {
      title: 'Are you sure to "Remove" the selected product(s)?',
      text: 'Remove',
      action: () => onUpdateList("remove"),
      classes: 'red-btn',
      permit: true,
      placement: "leftTop"
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onUpdateList = async (status) => {
    props.setLoading(true);

    const payload = {
      discount_id: id,
      status: status,
      products: JSON.stringify(selectedRowKeys)
    }
    await updateDiscountProductList(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Listing updated successfully.");
        rowSelection.onChange([]);
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Row gutter={[20, 30]}>
      <Col span={24}>
        <HeadingChip title={`Discount Filters`} btnList={allowedEdit && btnList} btnHidden={selectedRowKeys.length <= 0} />
      </Col>
      <Col span={24}>
        <ListCard
          Search={Search}
          onSearch={onSearch}
          filters={filters}
          filterValue={filterVal}
          onFilter={onFilter}
          onChange={onTableChange}
          rowClassName={rowClassName}
          ListCol={ListCol2}
          ListData={availableProduct?.list?.map(x => ({ ...x, key: x.id }))}
          rowSelection={allowedEdit && rowSelection}
          pagination={{
            total: availableProduct?.total_count,
            current: page,
            pageSize: limit,
          }}
        />
      </Col>
    </Row>
  );
};
