import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography } from 'antd';
import AddForm from '../components/AddForm';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentMethodOption } from "../../ducks/actions"
import { addPaymentMethod } from "../../ducks/services"

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const meta = useSelector((state) => state.systems.paymentMethodOption);

  useEffect(() => {
    dispatch(getPaymentMethodOption(props.setLoading));
  }, []);

  return (
    <Row gutter={[20, 30]}>
      <Col span={24}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>New Payment Method</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/payment-method')}>{'<'} Back</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          loading={props.loading}
          setLoading={props.setLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          oneTab={true}
          mode={'add'}
          meta={meta}
          addPaymentMethod={addPaymentMethod}
          navigate={navigate}
          reloadApi={(propId) => navigate(`/payment-method/${propId}`)}
        />
      </Col>
    </Row>
  )
}