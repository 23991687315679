import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getInventoryReportsMeta = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/reportings/inventory-reports`, {
      get_meta: "1"
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_INVENTORY_META,
      data: data
    });
  };
};

export const getSalesOrderList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/sales-orders/list`, {
      q: JSON.stringify({
        order_no_cont: search?.order_no,
        order_type_id_eq: search?.order_type,
        report_date_gteq: search?.start_date,
        report_date_lteq: search?.end_date,
        payments_payment_method_id_eq: search?.tender_type,
        is_non_sales_eq: search?.is_non_sales,
        status_eq: search?.status,
        s: `${orderby} ${ordering}`
      }),
      outlets: search?.outlet ? JSON.stringify(search?.outlet) : '[]',
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_SALES_ORDER_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getDailySummary = (search = {}, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/daily-summary`, {
      q: JSON.stringify({
        ...search
      })
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_DAILY_SUMMARY,
      data: data,
      meta: meta
    });
  };
};

export const getSalesPayments = (search = {}, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-payment`, {
      q: JSON.stringify({
        ...search
      })
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_SALES_PAYMENT,
      data: data,
      meta: meta
    });
  };
};

export const getSalesDetails = (search = {}, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-detail`, {
      q: JSON.stringify({
        ...search
      })
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_SALES_DETAILS,
      data: data,
      meta: meta
    });
  };
};

export const getSalesSummary = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "sales_summary_listing",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_SALES_SUMMARY,
      data: data,
      meta: meta
    });
  };
};

export const getSalesHourly = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "sales_hourly_listing",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_SALES_HOURLY,
      data: data,
      meta: meta
    });
  };
};

export const getSalesVoucherListing = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "sales_voucher_listing",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_SALES_VOUCHER_LISTING,
      data: data,
      meta: meta
    });
  };
};

export const getBillSummary = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "bill_summary",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_BILL_SUMMARY,
      data: data,
      meta: meta
    });
  };
};

export const getBillSummaryListing = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "bill_summary_listing",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_BILL_SUMMARY_LISTING,
      data: data,
      meta: meta
    });
  };
};

export const getSalesDiscountListing = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "sales_discount_listing",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_SALES_DISCOUNT_LISTING,
      data: data,
      meta: meta
    });
  };
};

export const getCashManagementListing = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "cash_management_listing",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_CASH_MANAGEMENT_LISTING,
      data: data,
      meta: meta
    });
  };
};

export const getProductSalesListing = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "product_sales_listing",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_PRODUCT_SALES_LISTING,
      data: data,
      meta: meta
    });
  };
};

export const getSpeedOfServiceListing = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "speed_of_service",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_SPEED_OF_SERVICE_LISTING,
      data: data,
      meta: meta
    });
  };
};

export const getCancelledSalesListing = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "cancelled_sales_listing",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_CANCELLED_SALES_LISTING,
      data: data,
      meta: meta
    });
  };
};

export const getTaxChargesListing = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "tax_charges_listing",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_TAX_CHARGES_LISTING,
      data: data,
      meta: meta
    });
  };
};

export const getTaxChargesDetails = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "tax_charges_details",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_TAX_CHARGES_DETAILS,
      data: data,
      meta: meta
    });
  };
};

export const getPosAuditLogListing = (search = {}, load, getmeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/sales-reports`, {
      get_meta: getmeta ? "1" : "0",
      report_type: "pos_audit_log",
      ...search
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_POS_AUDIT_LOG_LISTING,
      data: data,
      meta: meta
    });
  };
};
