import * as action_types from './constants';

const initialState = {
  stockBalances: {},
  option: {},
  salesOrders: {},
  salesOrdersOption: {},
  dailySummary: {},
  dailySummaryOption: {},
  salesPayments: {},
  salesPaymentsOption: {},
  salesDetails: {},
  salesDetailsOption: {},
  salesSummary: {},
  salesSummaryOption: {},
  salesHourly: {},
  salesHourlyOption: {},
  salesVoucherListing: {},
  salesVoucherListingOption: {},
  billSummary: {},
  billSummaryOption: {},
  billSummaryListing: {},
  billSummaryListingOption: {},
  salesDiscountListing: {},
  salesDiscountListingOption: {},
  cashManagementListing: {},
  cashManagementListingOption: {},
  productSalesListing: {},
  productSalesListingOption: {},
  speedOfServiceListing: {},
  speedOfServiceListingOption: {},
  cancelledSalesListing: {},
  cancelledSalesListingOption: {},
  taxChargesListing: {},
  taxChargesListingOption: {},
  taxChargesDetails: {},
  taxChargesDetailsOption: {},
  inventoryReport: {},
  inventoryReportMeta: {},
  posAuditLogListing: {},
  posAuditLogListingOption: {},
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.REPORTING_STOCK_BALANCE:
      return { ...state, stockBalances: data, option: meta };
    case action_types.REPORTING_SALES_ORDER_LIST:
      return { ...state, salesOrders: data, salesOrdersOption: meta };
    case action_types.REPORTING_DAILY_SUMMARY:
      return { ...state, dailySummary: data, dailySummaryOption: meta };
    case action_types.REPORTING_SALES_PAYMENT:
      return { ...state, salesPayments: data, salesPaymentsOption: meta };
    case action_types.REPORTING_SALES_DETAILS:
      return { ...state, salesDetails: data, salesDetailsOption: meta };
    case action_types.REPORTING_SALES_SUMMARY:
      return { ...state, salesSummary: data, salesSummaryOption: meta };
    case action_types.REPORT_SALES_SUMMARY_OPTION:
      return { ...state, salesSummaryOption: data };
    case action_types.REPORTING_SALES_HOURLY:
      return { ...state, salesHourly: data, salesHourlyOption: meta };
    case action_types.REPORTING_SALES_VOUCHER_LISTING:
      return { ...state, salesVoucherListing: data, salesVoucherListingOption: meta };
    case action_types.REPORTING_BILL_SUMMARY:
      return { ...state, billSummary: data, billSummaryOption: meta };
    case action_types.REPORTING_BILL_SUMMARY_LISTING:
      return { ...state, billSummaryListing: data, billSummaryListingOption: meta };
    case action_types.REPORTING_SALES_DISCOUNT_LISTING:
      return { ...state, salesDiscountListing: data, salesDiscountListingOption: meta };
    case action_types.REPORTING_CASH_MANAGEMENT_LISTING:
      return { ...state, cashManagementListing: data, cashManagementListingOption: meta };
    case action_types.REPORTING_PRODUCT_SALES_LISTING:
      return { ...state, productSalesListing: data, productSalesListingOption: meta };
    case action_types.REPORTING_SPEED_OF_SERVICE_LISTING:
      return { ...state, speedOfServiceListing: data, speedOfServiceListingOption: meta };
    case action_types.REPORTING_CANCELLED_SALES_LISTING:
      return { ...state, cancelledSalesListing: data, cancelledSalesListingOption: meta };
    case action_types.REPORTING_TAX_CHARGES_LISTING:
      return { ...state, taxChargesListing: data, taxChargesListingOption: meta };
    case action_types.REPORTING_TAX_CHARGES_DETAILS:
      return { ...state, taxChargesDetails: data, taxChargesDetailsOption: meta };
    case action_types.REPORTING_POS_AUDIT_LOG_LISTING:
      return { ...state, posAuditLogListing: data, posAuditLogListingOption: meta };
    case action_types.REPORTING_INVENTORY:
      return { ...state, inventoryReport: data };
    case action_types.REPORTING_INVENTORY_META:
      return { ...state, inventoryReportMeta: data };

    default:
      return state;
  }
};
