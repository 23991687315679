import React from 'react';
import { Tabs } from 'antd';
import * as TabCards from './tabList';

export default (props) => {
  const { setActiveTab, oneTab, searchParams } = props;
  const defaultActive = searchParams?.get('t');

  const tabs = [
    {
      name: "Details",
      Comp: 'Details',
    },
    {
      name: "Menu Types",
      Comp: 'MenuType',
    },
    {
      name: "Menu Categories",
      Comp: 'MenuCategory',
    },
    {
      name: "Layout",
      Comp: 'Layout',
    },
    // {
    //   name: "Sync Logs",
    //   Comp: 'Log',
    // }
  ];

  return (
    <Tabs items={
        (oneTab ? [tabs[0]] : tabs).map((item, index) => {
          const Cardi = TabCards[item.Comp];
          return {
            key: (index + 1).toString(),
            label: item.name,
            forceRender: true,
            children: <Cardi {...props} />
          }
        })
    } defaultActiveKey={defaultActive || "1"} onChange={(activeKey) => setActiveTab(activeKey)}/>
  );
};
