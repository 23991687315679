import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getInventoryReportsMeta = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/reportings/inventory-reports`, {
      get_meta: "1"
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_INVENTORY_META,
      data: data
    });
  };
};

export const getInventoryReports = (search = {}, load, isMultiOutlet, type, msg) => {
  load && load(true);
  let searchResult = {
    report_type: search?.report_type,
    date_from: search?.date_from,
    date_to: search?.date_to,
    status: search?.status
  }

  if(type == 'stock_movement_listing' || type == 'po_details_listing') {
    searchResult["products"] = search["products"];
  }

  if(type == 'material_consumption_listing') {
    searchResult = {
      report_type: search?.report_type,
      date_from: search?.date_from,
      date_to: search?.date_to,
    }
  }

  if(isMultiOutlet) searchResult.location_ids = search?.location_ids
  else searchResult.location_id = search?.location_id

  return async (dispatch) => {
    const {
      data: { data, meta, message, code },
    } = await axios.post(`${externalBaseUrl}/reportings/inventory-reports`, searchResult);
    load && load(false);
    if(code === 406) {
      msg.error(message)
    }

    dispatch({
      type: action_types.REPORTING_INVENTORY,
      data: data,
      meta: meta
    });
  };
};

export const getStockBalances = (search = {}, load, getMeta = false) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/reportings/stock-balances`, {
      location_ids: search?.outlets,
      from: search?.start_date,
      to: search?.end_date,
      get_meta: getMeta ? "1" : "0"
    });
    load && load(false);
    dispatch({
      type: action_types.REPORTING_STOCK_BALANCE,
      data: data,
      meta: meta
    });
  };
};
