import React, { useEffect, useState } from 'react';
import { Layout, Card, Button, Spin, Menu, theme } from 'antd';
import { useTranslate } from 'Translate';
// import Feeds from 'Molecules/Feeds';
import CHeader from 'Molecules/CHeader';
import Navigation from 'Molecules/Navigation';
import { LoadingOutlined, LeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenu } from '../../modules/Application/ducks/actions';
// import ResponsiveNav from 'Molecules/ResponsiveNav';
import { allowedAudit, allowedFeed } from '../../../routing/config/utils';
import { BreakingPoint } from '../../../configs/constantData';
import { useMediaQuery } from 'react-responsive';

// Responsive
import { useResponsiveState } from '../../contexts/ResponsiveContext';
import {CloseOutlined} from '@ant-design/icons'

const { Content, Header, Sider, Footer } = Layout;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const dispatch = useDispatch();
  const isIPAD = useMediaQuery({ query: BreakingPoint.IPAD });
  const menuStat = useSelector((state) => state.global.menu);
  const i18n = useTranslate();
  const { t } = i18n;
  const {isMobile} = useResponsiveState();
  const [collapsed, setCollapsed] = useState(isMobile ? true : false);
  const [menuname, setMenuname] = useState('');
  const {
    token: { colorBgContainer },
  } = theme.useToken();


  return (
    <Spin indicator={antIcon} size="large" spinning={props.load}>
      <Layout>
        <Sider
          collapsed={collapsed}
          collapsedWidth={isMobile ? 0 : 80}
          onBreakpoint={(broken) => {
            console.log(broken)
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          className='sider-sidebar'
          width={isMobile ? "100%" : 220}
          style={isMobile ? {position: "fixed", zIndex: "999", height: "100%"} : {}}
        >
          <div style={{position: "relative", paddingTop: "15px"}}>
          {isMobile && <CloseOutlined style={{position: "absolute", right: "15px", top: "8px", color: "#fff"}} onClick={() => setCollapsed(true)} />}
          <Navigation collapsed={collapsed} menuname={menuname} />
          </div>
        </Sider>
        <Layout className="site-layout">
          <CHeader
            colorBgContainer={colorBgContainer}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            setMenuname={setMenuname}
            menuname={menuname}
            setLoading={props.setLoading}
          />
          <Content
            style={{
              padding: '30px',
              height: 'calc(100vh - 90px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {props.children}
          </Content>
          {/* <Footer
            style={{
              textAlign: 'center',
            }}
          >
            Ant Design ©2023 Created by Ant UED
          </Footer> */}
        </Layout>
      </Layout>
    </Spin>
  );
};