import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getTrItemList } from '../../../../../ducks/actions'
import { editTrItem, addTrItem } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, data, allowedEdit, reloadCount, isNotOriginManager } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const trItems = useSelector((state) => state.inventory.trItems);
  const meta = useSelector((state) => state.inventory.trItemsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const editable = data?.status === "pending" && allowedEdit;

  const colName = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Item',
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      className: 'enable-click',
      render: (text, record) => <span>{text} {record.desc}</span>
    },
    {
      title: 'UOM',
      dataIndex: 'uom_code',
      key: 'uom_code',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Receive Quantity',
      dataIndex: 'receive_quantity',
      key: 'receive_quantity',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Discrepancy Quantity',
      dataIndex: 'discrepancy_quantity',
      key: 'discrepancy_quantity',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Transfer In Item"
          btnAction1={editable ? () => {
            setVisible(true);
            setValue('id', record.id);
            setValue('item', record.product_id ? { label: `${record.code} ${record.desc}`, value: record.product_id } : '');
            setValue('receive_quantity', record.receive_quantity);
            setValue('remarks', record.remarks);
          } : false}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'item',
      label: 'Item',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      // options: _.map(meta?.products, (e) => ({ label: `${e.code} ${e.name}`, value: e.id, uom_id: e.uom_id, uom_code: e.uom_code })),
      options: [],
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: true
      // onChange: (e) => {
      //   setValue('uom', { label: e.uom_code, value: e.uom_id })
      // }
    },
    {
      name: 'receive_quantity',
      label: 'Receive Quantity',
      req: isNotOriginManager,
      placeholder: 'Please select',
      type: 'input',
      number: true,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !isNotOriginManager
    },
    {
      name: 'remarks',
      label: 'Remarks',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%'
    }
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getTrItemList(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);

  // const addNew = () => {
  //   setVisible(true);
  //   setValue('id', '');
  //   setValue('item', '');
  //   setValue('receive_quantity', '');
  //   setValue('remarks', '');
  // }

  const btnList = [
    // {
    //   text: '+ New Item',
    //   classes: 'attendance-success',
    //   action: () => addNew(),
    // }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getTrItemList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getTrItemList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }
  
  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        product_code: search?.product_code
      };
      setSearchVal(searching);
      dispatch(getTrItemList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getTrItemList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      receive_quantity: val?.receive_quantity,
      remarks: val?.remarks,
      transfer_in_item_id: val?.id,
      transfer_in_id: id
    }
    await (val?.id ? editTrItem : addTrItem)(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success("TR Item Updated Successfully!");
        setVisible(false);
        setPage(1);
        reloadCount();
        setTimeout(() => dispatch(getTrItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Edit TR Item
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('item', '');
              setValue('receive_quantity', '');
              setValue('remarks', '');
            }}>Cancel</Button></Col>
            {
              editable &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('item', '');
      setValue('receive_quantity', '');
      setValue('remarks', '');
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click") && editable){
          setValue('id', record.id);
          setValue('item', record.product_id ? { label: `${record.code} ${record.desc}`, value: record.product_id } : '');
          setValue('receive_quantity', record.quantity);
          setValue('remarks', record.remarks);
          setVisible(true);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={''} btnList={editable ? btnList : []} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onSearch={onSearch}
            Search={Search}
            onRow={onClickRow}
            ListData={trItems?.list?.map((x, idx) => ({ ...x, key: x.id, index: idx + 1}))}
            onChange={onTableChange}
            ListCol={colName}
            pagination={{
              total: trItems?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}