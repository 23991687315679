import React, { useEffect, Fragment, useState } from 'react';
import { Form, Button, Row, Col, message, Spin, Space, Typography } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import { clearOutletData } from '../../../../ducks/services';
import { InputPassword } from 'Atoms/FormElement';
import { LoadingOutlined } from '@ant-design/icons';

const _ = require('lodash');
const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { meta, activeTab, data, navigate, addOutlet, editOutlet, mode, id, reloadApi, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const isOutletTab = activeTab === "1";
  const isSuperAdmin = localStorage.getItem("is_superadmin") === "1";
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const formFields = [
    {
      name: 'code',
      label: 'Outlet Code',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: mode === "edit"
    },
    {
      name: 'desc',
      label: 'Outlet Name',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'outlet_group',
      label: 'Outlet Group Code',
      req: isOutletTab,
      placeholder: 'Please select',
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      class: 'white-select',
      options: _.map(meta?.location_groups, (e) => ({ label: e.code, value: e.id })),
      static: !allowedEdit
    },
    {
      name: 'product_menu',
      label: 'Outlet Concept',
      req: isOutletTab,
      placeholder: 'Please select',
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      options: _.map(meta?.product_menus, (e) => ({ label: e.code, value: e.id })),
      static: !allowedEdit
    },
    {
      name: 'email',
      label: 'Email',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      email: true,
      breakrow: true,
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'mobileno',
      label: 'Mobile Number',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      number: true,
      nostep: true,
      nomin: true,
      static: !allowedEdit
    },
    {
      name: 'phoneno',
      label: 'Phone Number',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      number: true,
      nomin: true,
      nostep: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'fax',
      label: 'Fax Number',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      number: true,
      nostep: true,
      nomin: true,
      static: !allowedEdit
    },
    {
      name: 'address1',
      label: 'Address 1',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'address2',
      label: 'Address 2',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'city',
      label: 'City',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'postcode',
      label: 'Postal Code',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      number: true,
      nomin: true,
      static: !allowedEdit
    },
    {
      name: 'state',
      label: 'State',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'input',
      string: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'timezone',
      label: 'Timezone',
      req: isOutletTab,
      placeholder: 'Please state',
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      options: _.map(meta?.timezones, (e) => ({ label: e, value: e })),
      static: !allowedEdit,
      menuPlacement: 'top'
    },
    {
      name: 'region',
      label: 'Region',
      req: false,
      placeholder: 'Please select',
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      options: _.map(meta?.regions, (e) => ({ label: e.code, value: e.id })),
      static: !allowedEdit,
      isClearable: true
    },
    // {
    //   name: 'tax_condition',
    //   label: 'Tax Condition',
    //   req: false,
    //   placeholder: 'Please select',
    //   type: 'select',
    //   twocol: false,
    //   colWidth: '0 1 50%',
    //   reqmessage: 'Required',
    //   options: _.map(meta?.tax_conditions, (e) => ({ label: e.code, value: e.id })),
    // },
    {
      name: 'organization_reference',
      label: 'Company Code',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_plastic_allowed',
      label: 'Is Plastic Bag Allowed? (Take Away)',
      req: false,
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEdit,
      alignEnd: true
    },
    {
      type: 'switch',
      name: 'is_initial_stock_take_required',
      label: 'Is Initial Stock Take Required? (For Existing Outlet)',
      req: false,
      twocol: true,
      colWidth: '0 1 50%',
      static: !allowedEdit,
      alignEnd: true,
      hidden: mode !== "add"
    }
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('code', data.code);
      setValue('desc', data.desc);
      setValue('outlet_group', data.location_group_id ? { label: data.location_group_code, value: data.location_group_id} : '');
      setValue('product_menu', data.product_menu_id ? { label: data.product_menu_code, value: data.product_menu_id } : '');
      setValue('email', data.email);
      setValue('mobileno', data.mobileno);
      setValue('phoneno', data.phoneno);
      setValue('fax', data.fax);
      setValue('address1', data.address1);
      setValue('address2', data.address2);
      setValue('city', data.city);
      setValue('postcode', data.postcode);
      setValue('state', data.state);
      setValue('timezone', data.timezone ? { label: data.timezone, value: data.timezone } : '');
      setValue('region', data.region_id ? { label: data.region_code, value: data.region_id } : '');
      setValue('tax_condition', data.tax_condition_id ? { label: data.tax_condition_code, value: data.tax_condition_id } : '');
      setValue('organization_reference', data.organization_reference);
      setValue('is_plastic_allowed', data.is_plastic_allowed === "1");
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    var temp = {
      ...val,
      location_group_id: val?.outlet_group?.value,
      product_menu_id: val?.product_menu?.value,
      is_plastic_allowed: val?.is_plastic_allowed ? "1" : "0",
      // tax_condition_id: val?.tax_condition?.value,
      region_id: val?.region?.value,
      timezone: val?.timezone?.value,
      id: id
    }

    if (mode === "add") {
      temp["is_initial_stock_take_required"] = val?.is_initial_stock_take_required ? "1" : "0"
    }

    const payload = _.omit(temp, ['outlet_group', 'product_menu', 'region', 'tax_condition']);

    mode === "add"
    ?
    await addOutlet(payload).then(res => {
      props.setLoading(false);
      if(res.data.code === 200) {
        message.success("Outlet added successfully!");
        setTimeout(() => reloadApi(res.data.data), 500);
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
    :
    await editOutlet(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Outlet edited successfully!");
        // setTimeout(() => navigate("/outlets"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const onClearData = async(val) => {
    setLoading(true);
    await clearOutletData({
      id: id,
      password: val?.password
    }).then(res => {
      setLoading(false);
      if(res.data.code === 200) {
        message.success("Outlet data has been cleared successfully.");
        setValue('password', null);
        setVisible(false);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" onFinish={handleSubmit(onClearData)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Clear Outlet Data
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <InputPassword
                fieldname="password"
                label="Please enter your password to proceed"
                control={control}
                initValue=""
                iProps={{ size: 'large' }}
              />
            </Col>
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('password', null);
            }}>Cancel</Button></Col>
            {
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('password', '');
    },
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)} scrollToFirstError>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
        </Row>
        {
          allowedEdit &&
          <Row gutter={[10, 10]} className="mt-1">
            {
              isSuperAdmin &&
              mode == "edit" &&
              <Col span={12}>
                <Button size="large" htmlType='button' className='red-btn attendance-success' onClick={() => setVisible(true)}>Clear Outlet Data</Button>
              </Col>
            }
            <Col span={24} className='text-right'>
              <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
            </Col>
          </Row>
        }
      </Form>
      <Popup {...popup} />
    </>
  )
}