import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import ListCard from 'Molecules/ListCard';
import ActionButton from 'Molecules/ActionButton';
import Search from './Components/Search';
import { getAvailabilityList } from '../ducks/actions';
import { removeAvailability, bulkRemoveAvailabilities } from '../ducks/services';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchVal] = useState(null);
  const availabilities = useSelector((state) => state.product.availabilities);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const allowedEdit = allowed([AllRoles.PRODUCT.AVAILABILITY], 'write');
  const allowedDelete = allowed([AllRoles.PRODUCT.AVAILABILITY], 'delete');
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  useEffect(() => {
    if(!globalListQuery.availableProductListQuery) {
      dispatch(getAvailabilityList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('availableProductListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.availableProductListQuery
      dispatch(getAvailabilityList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const reloadApi = () => {
    dispatch(getAvailabilityList(page, limit, '', '', null, props.setLoading));
    dispatch(getListQueryItems('availableProductListQuery', page, limit, '', '', null, props.setLoading))
  };

  const onRemove = (recordId) => {
    props.setLoading(true);
    let payload = {
      work_schedule_id: recordId,
      status_event: 'remove'
    }
    removeAvailability(payload)
      .then((response) => {
        props.setLoading(false);
        if (response.data.code == 200) {
          message.success("Product Availability deleted successfully");
          reloadApi();
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        props.setLoading(false);
        message.error(error?.message ?? 'Something went wrong');
      });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      work_schedules: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveAvailabilities(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Categories has been removed successfully!");
        setTimeout(() => dispatch(getCategories(1, limit, '', '', props.setLoading)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const ListCol = [
    {
      title: 'Template Name',
      dataIndex: 'name',
      key: 'template_name',
      className: 'enable-click',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Product Availability"
          btnAction1={() => navigate(`/product/availability/${record.id}`)}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const btnList = [
    {
      title: 'Are you sure to remove the selected record(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Product Availability',
      action: () => {
        navigate('add')
      },
    },
  ];

  const onSearch = (value) => {
    if (value) {
      let searchVal = {
        name: value?.template_name ? value?.template_name : '',
      };
      setSearchVal(searchVal);
      setPage(1);
      dispatch(getAvailabilityList(1, 10, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('availableProductListQuery', 1, 10, '', '', searchVal, props.setLoading))
    }
  };

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(
        getAvailabilityList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchValue, props.setLoading),
      );
      dispatch(getListQueryItems('availableProductListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchValue, props.setLoading))
    } else {
      dispatch(getAvailabilityList(pagination.current, pagination.pageSize, '', '', searchValue, props.setLoading));
      dispatch(getListQueryItems('availableProductListQuery', pagination.current, pagination.pageSize, '', '', searchValue, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
        navigate(`/product/availability/${record.id}`);
      },
    };
  };

  return (
    <>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <HeadingChip title="Product Availability" btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.availableProductListQuery?.searchValue}
            ListCol={ListCol}
            ListData={availabilities?.list?.map(x => ({...x, key: x.id}))}
            rowSelection={allowedEdit && rowSelection}
            pagination={{
              total: availabilities?.total_count,
              current: page,
              pageSize: limit,
            }}
            onChange={onTableChange}
          />
        </Col>
      </Row>
    </>
  );
};
