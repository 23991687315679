import React, { useEffect, useState } from 'react';
import { Row, Col, message, Tooltip, Typography } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStockMovementList } from '../ducks/actions'
import { exportStockMovement } from '../ducks/services'
import Search from './Search';
import { LoadingOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.inventory.stockMovementList);
  const meta = useSelector((state) => state.inventory.stockMovementListMeta);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [products, setProducts] = useState([]);

  const colName = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true
    },
    {
      title: 'Doc No.',
      dataIndex: 'reference',
      key: 'reference',
      sorter: true
    },
    {
      title: 'Outlet',
      dataIndex: 'location_code',
      key: 'location_code',
      sorter: true,
      render: (text, record) => <span>{text} {record.location_desc}</span>
    },
    {
      title: 'Product',
      dataIndex: 'product_code',
      key: 'product_code',
      sorter: true,
      render: (text, record) => <span>{text} {record.product_name}</span>
    },
    {
      title: 'UOM Code',
      dataIndex: 'uom_code',
      key: 'uom_code',
      sorter: true
    },
    {
      title: 'Source Type',
      dataIndex: 'source_type',
      key: 'source_type',
      sorter: true
    },
    {
      title: 'Source Name',
      dataIndex: 'source_name',
      key: 'source_name',
      sorter: true
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: true,
      align: 'right'
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unit_cost',
      key: 'unit_cost',
      sorter: true,
      align: 'right'
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      sorter: true,
      align: 'right'
    },
    {
      title: 'Netsuite Synced?',
      dataIndex: 'netsuite_synced',
      key: 'netsuite_synced',
      className: 'valign-top',
      render: (text) => text === "1" ? <Tooltip title="Synced to Netsuite"><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip> : "No"
    },
  ];

  // const btnList = [
  //   {
  //     text: 'Export',
  //     classes: 'attendance-success',
  //     action: () => onExport(),
  //   },
  // ];

  useEffect(() => {
    dispatch(getStockMovementList(page, limit, '', '', null, props.setLoading));
  }, []);

  useEffect(() => {
    if(meta) {
      if ('locations' in meta) {
        let temp = [];
        meta?.locations?.map((item, index) => {
          if (index === 0) {
            temp.push({
              label: "All Outlets",
              value: ""
            })
          }
          temp.push({
            label: `${item.code} ${item.desc}`,
            value: item.id
          })
        });
        setOutlets(temp);
      }

      if ("products" in meta) {
        let temp = [];
        meta.products?.map((x, ind) => {
          temp.push({
            label: `${x.code} - ${x.name}`,
            value: x.id
          })
        });
        setProducts(temp);
      }
    }
  }, [meta]);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getStockMovementList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
    } else {
      dispatch(getStockMovementList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {
        ...search,
        product: search.product?.value,
        location: search?.location?.value,
        start_date: search.start_date ? dayjs(search.start_date).format("YYYY-MM-DD") : "",
        end_date: search.end_date ? dayjs(search.end_date).format("YYYY-MM-DD") : ""
      };
      setSearchVal(searching);
      dispatch(getStockMovementList(1, limit, '', '', searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getStockMovementList(1, limit, '', '', null, props.setLoading));
    }
  };

  const exportReport = async () => {
    props.setLoading(true);
    await exportStockMovement(searchVal).then(res => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.csv");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(true);
      message.error(e.message ?? "Something went wrong")
    })
  }

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn attendance-success',
      action: () => exportReport(),
    }
  ]

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Stock Movement"} btnList={btnList} />
        </Col>
        <Col span={24} className="">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            ListData={data?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            field1={outlets}
            field2={products}
            pagination={{
              total: data?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}