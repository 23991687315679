import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Upload } from 'antd';
import DndListCard from 'Molecules/DndListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { addMenuButton, editMenuButton, removeMenuButton, bulkRemoveMenuButtons, updateMenuButtonsOrder } from '../../../../../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { dummyRequest, getFileName, uploadFileV2 } from 'Features/utility';
import placeholderImg from "Assets/img/product-placeholder.png"
import FormGroup from 'Molecules/FormGroup';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { id, data, datalist, reloadApi, buttonType, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState();
  const [load, setLoad] = useState(false);
  const [list, setDataList] = useState([]);
  const [hasDirty, setHasDirty] = useState(false);

  const colName = [
    {
      title: "No.",
      dataIndex: 'index',
      key: 'index',
      align: 'center'
    },
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      align: 'center',
      render: (text, record) => <img width="50px" src={text || placeholderImg} />,
      width: 100
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Text Color Code',
      dataIndex: 'text_color_code',
      key: 'text_color_code',
      render: (text) => <Space size={10}>
        <div style={{ width: '20px', height: '20px', backgroundColor: text?.includes("#") ? text : `#${text}` }}></div>
        {text}
      </Space>
    },
    {
      title: 'Background Color Code',
      dataIndex: 'bg_color_code',
      key: 'bg_color_code',
      render: (text) => <Space size={10}>
        <div style={{ width: '20px', height: '20px', backgroundColor: text?.includes("#") ? text : `#${text}` }}></div>
        {text}
      </Space>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {" "}
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (text, record) =>
        <ActionButton
          title="Bom"
          btnAction1={() => {
            setVisible(true);
            setValue('id', record.id);
            setValue('code', record.code);
            setValue('desc', record.desc);
            setValue('text_color_code', record.text_color_code ? record.text_color_code : "");
            setValue('bg_color_code', record.bg_color_code ? record.bg_color_code : "");
            if (record?.image_url) {
              setFileList([
                {
                  uid: '-1',
                  name: getFileName(record?.image_url),
                  status: 'done',
                  url: record?.image_url
                }
              ])
            } else {
              setFileList();
              setFileId();
            }
          }}
          recordId={record.id}
          // onRemove={onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !!getValues("id") || !allowedEdit
    },
    {
      name: 'desc',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'text_color_code',
      label: 'Text Color Code',
      req: true,
      placeholder: 'Please state',
      type: 'color',
      showText: true,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'bg_color_code',
      label: 'Background Color Code',
      req: true,
      placeholder: 'Please state',
      type: 'color',
      showText: true,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    }
  ];

  // const addNew = () => {
  //   setVisible(true);
  //   setFileId();
  //   setFileList([]);
  //   setValue('id', '');
  //   setValue('code', '');
  //   setValue('desc', '');
  //   setValue('text_color_code', '');
  //   setValue('bg_color_code', '');
  // }

  const btnList = [
    {
      title: "Are you sure to reorder the list?",
      text: "Update Listing",
      classes: 'b-success attendance-success',
      btnHidden: !hasDirty,
      action: () => onUpdateList()
    },
    // {
    //   title: 'Are you sure to remove the selected Button(s)?',
    //   text: 'Bulk Remove',
    //   classes: 'red-btn text-white attendance-success',
    //   permit: true,
    //   action: () => onBulkRemove(),
    // },
    // {
    //   text: '+ New Button',
    //   classes: 'attendance-success',
    //   action: () => addNew(),
    // },
  ];

  useEffect(() => {
    if (data) {
      setDataList(datalist);
    }
  }, [data]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAction = async (recId, status) => {
    props.setLoading(true);

    const payload = {
      product_menu_id: id,
      product_menu_button_id: recId,
      status_event: status === "Active" ? "deactivate" : "activate"
    }

    await removeMenuButton(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Button status has been updated successfully!");
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (recId) => {
    props.setLoading(true);

    const payload = {
      product_menu_id: id,
      product_menu_button_id: recId,
      status_event: 'remove'
    }

    await removeMenuButton(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Button has been removed successfully!");
        reloadApi();
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      ...val,
      blob_id: fileId,
      button_type: buttonType,
      text_color_code: val?.text_color_code,
      bg_color_code: val?.bg_color_code,
      product_menu_button_id: val?.id,
      product_menu_id: id
    }

    val?.id ?
      await editMenuButton(payload).then(res => {
        setLoad(false);
        if (res.data.code === 200) {
          message.success("Button updated successfully.");
          setVisible(false);
          reloadApi();
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoad(false);
        message.error(e.message ?? "Something went wrong");
      }) :
      await addMenuButton(payload).then(res => {
        setLoad(false);
        if (res.data.code === 200) {
          message.success("Button added successfully.");
          setVisible(false);
          reloadApi();
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoad(false);
        message.error(e.message ?? "Something went wrong");
      });
  };

  const onUpdateList = async () => {
    props.setLoading(true);

    const payload = {
      product_menu_buttons: JSON.stringify(list?.map((d, i) => ({ id: d.id, position: i + 1 })))
    }
    await updateMenuButtonsOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Buttons ordering has been updated successfully.");
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const handleUpload = async (e) => {
    setLoad(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      setLoad(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      setLoad(false);
    }
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues("id") ? 'Edit' : 'Add'} button
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24} className='text-center'>
              <Upload
                disabled={!allowedEdit}
                listType="picture-card"
                className="avatar-uploader larger"
                showUploadList={false}
                accept="image/*"
                maxCount={1}
                fileList={fileList}
                customRequest={dummyRequest}
                onChange={(e) => handleUpload(e)}
              >
                <Space size={4}>
                  {fileList?.length > 0 ? (
                    <img
                      src={fileList[0].url}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) :
                    <div>
                      <img src={placeholderImg} alt="" className='w-100' />
                    </div>
                  }
                </Space>
              </Upload>
            </Col>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
    },
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24} className={!hasDirty ? "d-none" : "d-block"}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <DndListCard
            setHasDirty={setHasDirty}
            setDataList={setDataList}
            list={list}
            classes="nopad"
            ListData={list?.filter((item) => item.code !== 'print_receipt' )?.map((x, ind) => ({ ...x, key: x.id, index: ind + 1 }))}
            ListCol={colName}
            // rowSelection={rowSelection}
            pagination={false}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}