import React, { useEffect, useState } from 'react';
import { Row, Col, Button, message, Space, Popconfirm } from 'antd';
import DndListCard from 'Molecules/DndListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderTypes } from '../ducks/actions'
import { removeOrderType, bulkRemoveOrderTypes, updateOrderTypeOrder } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search';
import placeholderImg from "Assets/img/product-placeholder.png"
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderTypes = useSelector((state) => state.product.orderTypes);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const [hasDirty, setHasDirty] = useState(false);
  const [data, setData] = useState([]);
  const allowedEdit = allowed([AllRoles.PRODUCT.ORDERTYPE], 'write');
  const allowedDelete = allowed([AllRoles.PRODUCT.ORDERTYPE], 'delete');
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'Image',
      dataIndex: 'image_url',
      key: 'image_url',
      className: 'enable-click',
      width: 150,
      render: (text) => <img src={text ? text : placeholderImg} width="75px" />
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Order Type Group Code',
      dataIndex: 'order_type_group_code',
      key: 'order_type_group_code',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Tax Condition Code',
      dataIndex: 'tax_condition_code',
      key: 'tax_condition_code',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Is Food Aggregator (FA)',
      dataIndex: 'is_fa',
      key: 'is_fa',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span className={text === "0" ? "c-pending" : "c-success"}>{text === "0" ? "No" : "Yes"}</span>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Order Type"
          btnAction1={() => navigate(`/product/order-type/${record.id}`)}
          recordId={record.id}
          onRemove={(allowedDelete && !["DINE_IN", "TAKE_AWAY"].includes(record.code)) && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  const addNew = () => navigate('/product/order-type/add');

  const btnList = [
    {
      title: "Are you sure to reorder the list?",
      text: "Update Listing",
      classes: 'b-success attendance-success',
      btnHidden: !hasDirty,
      action: () => onUpdateList()
    },
    {
      title: 'Are you sure to remove the selected Order Type(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Order Type',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.orderTypeListQuery) {
      dispatch(getOrderTypes(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('orderTypeListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.orderTypeListQuery
      dispatch(getOrderTypes(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: ["DINE_IN", "TAKE_AWAY"].includes(record.code)
    }),
  };

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      order_type_id: id,
      status_event: 'remove'
    }

    await removeOrderType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Product Type has been removed successfully!");
        setTimeout(() => dispatch(getOrderTypes(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('orderTypeListQuery', page, limit, '', '', searchVal, props.setLoading))
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      order_types: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveOrderTypes(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Order Types has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getOrderTypes(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('orderTypeListQuery', 1, limit, '', '', null, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onUpdateList = async () => {
    props.setLoading(true);

    const payload = {
      order_types: JSON.stringify(data?.map((d, i) => ({ id: d.id, position: i + 1 })))
    }
    await updateOrderTypeOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Order Types Re-ordered successfully.");
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  useEffect(() => {
    if (orderTypes) {
      setData(orderTypes?.list);
    }
  }, [orderTypes]);


  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getOrderTypes(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('orderTypeListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getOrderTypes(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('orderTypeListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        name: search.name,
        code: search.code
      };
      setSearchVal(searching);
      dispatch(getOrderTypes(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('orderTypeListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getOrderTypes(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('orderTypeListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Order Types"} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <DndListCard
            setHasDirty={setHasDirty}
            setDataList={setData}
            list={data}
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.orderTypeListQuery?.searchValue}
            ListData={data?.map((x, ind) => ({ ...x, key: x.id, index: ind + 1 }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            onChange={onTableChange}
            pagination={{
              total: orderTypes?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}