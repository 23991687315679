import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { getPrinterOption } from '../../ducks/actions';
import { addPrinter } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const meta = useSelector((state) => state.pos.printerOption);

  useEffect(() => {
    dispatch(getPrinterOption(props.setLoading));
  }, []);

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      code: val.code,
      name: val.name,
      printer_type: val?.printer_type?.value,
    }
    await addPrinter(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Printer created successfully!");
        setTimeout(() => navigate("/pos/printer"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>New Printer</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                <Button size="large" htmlType='submit' className='green-btn'>Save</Button>
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/pos/printer')}>{'<'} Back</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <InputForm
          control={control}
          errors={errors}
          meta={meta}
          allowedEdit={true}
        />
      </Row>
    </Form>
  )
}