import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStockBalances } from '../ducks/actions'
import { exportStockBalance } from '../ducks/services'
import Search from './components/Search'
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stockBalances = useSelector((state) => state.reporting.stockBalances);
  const meta = useSelector((state) => state.reporting.option);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);

  const colName = [
    {
      title: 'Outlet',
      dataIndex: 'location_desc',
      key: 'location_desc',
      width: 200,
      fixed: 'left',
      render: (text, record) => <span><b>{record.location_code} - {text}</b></span>
    },
    {
      title: 'Item',
      dataIndex: 'desc',
      key: 'desc',
      width: 250,
      fixed: 'left',
      render: (text, record) => <span>{record.code} - {text}</span>
    },
    {
      title: 'UOM',
      dataIndex: 'uom_code',
      key: 'uom_code',
      width: 100,
      fixed: 'left'
    },
    {
      title: 'Opening',
      children: [
        {
          title: 'Qty.',
          dataIndex: 'opening_qty',
          key: 'opening_qty',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(5))}</span>
        },
        {
          title: 'Inv. Cost',
          dataIndex: 'opening_cost',
          key: 'opening_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
        {
          title: 'Inv. Unit Cost',
          dataIndex: 'opening_unit_cost',
          key: 'opening_unit_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
      ],
    },
    {
      title: 'GRN-Goods Received Note',
      children: [
        {
          title: 'Qty.',
          dataIndex: 'stock_in_qty',
          key: 'stock_in_qty',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(5))}</span>
        },
        {
          title: 'Inv. Cost',
          dataIndex: 'stock_in_cost',
          key: 'stock_in_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
        {
          title: 'Inv. Unit Cost',
          dataIndex: 'stock_in_unit_cost',
          key: 'stock_in_unit_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
      ],
    },
    {
      title: 'ILT-Interlocation Transfer',
      children: [
        {
          title: 'Qty.',
          dataIndex: 'transfer_qty',
          key: 'transfer_qty',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(5))}</span>
        },
        {
          title: 'Inv. Cost',
          dataIndex: 'transfer_cost',
          key: 'transfer_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
        {
          title: 'Inv. Unit Cost',
          dataIndex: 'transfer_unit_cost',
          key: 'transfer_unit_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
      ],
    },
    {
      title: 'POSBM-POS BOM',
      children: [
        {
          title: 'Qty.',
          dataIndex: 'sales_qty',
          key: 'sales_qty',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(5))}</span>
        },
        {
          title: 'Inv. Cost',
          dataIndex: 'sales_cost',
          key: 'sales_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
        {
          title: 'Inv. Unit Cost',
          dataIndex: 'sales_unit_cost',
          key: 'sales_unit_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
      ],
    },
    {
      title: 'CONV-Stock Conversion',
      children: [
        {
          title: 'Qty.',
          dataIndex: 'stock_conversion_qty',
          key: 'stock_conversion_qty',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(5))}</span>
        },
        {
          title: 'Inv. Cost',
          dataIndex: 'stock_conversion_cost',
          key: 'stock_conversion_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
        {
          title: 'Inv. Unit Cost',
          dataIndex: 'stock_conversion_unit_cost',
          key: 'stock_conversion_unit_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
      ],
    },
    {
      title: 'WAS-Stock Wastage',
      children: [
        {
          title: 'Qty.',
          dataIndex: 'stock_wastage_qty',
          key: 'stock_wastage_qty',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(5))}</span>
        },
        {
          title: 'Inv. Cost',
          dataIndex: 'stock_wastage_cost',
          key: 'stock_wastage_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
        {
          title: 'Inv. Unit Cost',
          dataIndex: 'stock_wastage_unit_cost',
          key: 'stock_wastage_unit_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
      ],
    },
    {
      title: 'Adjustment',
      children: [
        {
          title: 'Qty.',
          dataIndex: 'adjustment_qty',
          key: 'adjustment_qty',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(5))}</span>
        },
        {
          title: 'Inv. Cost',
          dataIndex: 'adjustment_cost',
          key: 'adjustment_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
        {
          title: 'Inv. Unit Cost',
          dataIndex: 'adjustment_unit_cost',
          key: 'adjustment_unit_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
      ],
    },
    {
      title: 'STAKE-Stock Take',
      children: [
        {
          title: 'Qty.',
          dataIndex: 'stock_take_qty',
          key: 'stock_take_qty',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(5))}</span>
        },
        {
          title: 'Inv. Cost',
          dataIndex: 'stock_take_cost',
          key: 'stock_take_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
        {
          title: 'Inv. Unit Cost',
          dataIndex: 'stock_take_unit_cost',
          key: 'stock_take_unit_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
      ],
    },
    {
      title: 'Closing',
      children: [
        {
          title: 'Qty.',
          dataIndex: 'closing_qty',
          key: 'closing_qty',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(5))}</span>
        },
        {
          title: 'Inv. Cost',
          dataIndex: 'closing_cost',
          key: 'closing_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
        {
          title: 'Inv. Unit Cost',
          dataIndex: 'closing_unit_cost',
          key: 'closing_unit_cost',
          width: 200,
          align: 'right',
          render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
        },
      ],
    },
    // {
    //   title: 'Closing Adjustment Cost',
    //   dataIndex: 'adjustment_cost',
    //   key: 'adjustment_cost',
    //   align: 'right',
    //   width: 300,
    //   render: (text) => <span>{comma(parseFloat(text).toFixed(8))}</span>
    // }
  ];

  useEffect(() => {
    dispatch(getStockBalances(null, props.setLoading, true));
  }, []);

  useEffect(() => {
    if(meta) {
      if('outlets' in meta) {
        let temp = [];
        meta.outlets.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta])

  const exportCsv = async () => {
    if (stockBalances?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportStockBalance(searchVal).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  }

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn attendance-success',
      action: () => exportCsv(),
    },
  ];

  // const onTableChange = (pagination, filters, sorter) => {
  //   setPage(pagination.current);
  //   setLimit(pagination.pageSize);
  //   if (sorter.order) {
  //     dispatch(getStockBalances(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
  //   } else {
  //     dispatch(getStockBalances(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
  //   }
  // }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      let temp = [];
      if (search?.outlets?.length > 0) {
        search?.outlets?.map(x => {
          temp.push(x.value)
        });
      }
      searching = {
        start_date: search.start_date ? dayjs(search.start_date).format("YYYY-MM-DD") : "",
        end_date: search.end_date ? dayjs(search.end_date).format("YYYY-MM-DD") : "",
        outlets: temp.length > 0 ? JSON.stringify(temp) : null
      };
      setSearchVal(searching);
      dispatch(getStockBalances(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getStockBalances(null, props.setLoading));
    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={"Stock Balance"} btnList={allowed([AllRoles.INVENTORY_REPORTING.STOCK_BALANCE], 'write') ? btnList : null} />
      </Col>
      {/* refer to csv table layout */}
      <Col span={24} className="inv-report-sb">
        <ListCard
          isSticky={true}
          scrolling={stockBalances?.list?.length ? 4000 : 1000}
          Search={Search}
          onSearch={onSearch}
          bordered={true}
          // listClass="nopad"
          ListData={stockBalances?.list?.map(x => ({ ...x, key: x.id }))}
          // onChange={onTableChange}
          ListCol={colName}
          field1={outlets}
          pagination={false}
          showHeader={stockBalances?.list?.length ? true : false}
        />
      </Col>
    </Row>
  )
}