import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message, Upload, Space } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { id, editOrganization, meta, data, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      name: 'currency_id',
      label: 'Currency Code',
      req: true,
      placeholder: 'Please select',
      options: meta?.currencies?.map(x => ({label: x.code, value: x.id})),
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Currency Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Default Calling Code',
      name: 'calling_code',
      twocol: false,
      options: meta?.calling_codes?.map((x) => ({ label: `${x.name} (+${x.calling_code})`, value: x.calling_code })),
      req: false,
      placeholder: 'Please select',
      reqmessage: 'Default Calling Code is Required',
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      name: 'rounding_method',
      label: 'Rounding Method',
      req: false,
      type: 'select',
      options: meta?.rounding_methods?.map((x) => ({ label: x.label, value: x.value })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'rounding_step_size',
      label: 'Rounding Step Size',
      req: false,
      type: 'select',
      options: meta?.rounding_step_sizes?.map((x) => ({ label: x, value: x })),
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'cash_drawer_threshold',
      label: 'Cash Drawer Threshold',
      req: true,
      placeholder: 'Please select',
      number: true,
      nostep: true,
      min: 0,
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Cash Drawer Threshold Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Plastic Bag Used (Take Away)',
      name: 'product',
      twocol: false,
      options: meta?.products?.map((x) => ({ label: `${x.code} ${x.name}`, value: x.id })),
      req: false,
      placeholder: 'Please select',
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      name: 'stock_take_threshold',
      label: 'Stock Take Threshold (%)',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      number: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      suffix: "%"
    },
    {
      name: 'stock_in_threshold',
      label: 'Good Receive Threshold (%)',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      number: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      suffix: "%"
    },
    {
      name: 'finance_email',
      label: 'Finance Email',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      email: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      tips: "Receipts for non-sale (SCM, Marketing, Training, Food Testing, and QA) will be sent to the provided email address"
    },
    {
      name: 'tax_display_inclusive',
      label: 'Tax Display Inclusive',
      req: false,
      type: 'switch',
      twocol: false,
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
    {
      name: 'non_sales_kitchen_queue_enabled',
      label: 'Non Sales Kitchen Queue Enabled?',
      req: false,
      type: 'switch',
      twocol: false,
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
    {
      name: 'is_blocked_stock_in_exceed_threshold',
      label: 'Block Good Receive Exceed Threshold?',
      req: false,
      type: 'switch',
      twocol: false,
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
    {
      name: 'is_blocked_transfer_in_exceed_threshold',
      label: 'Block Transfer Receive Exceed Threshold?',
      req: false,
      type: 'switch',
      twocol: false,
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      if (data.calling_code) {
        let temp = meta?.calling_codes?.find(x => x.calling_code == data.calling_code);
        setValue('calling_code', { label: `${temp.name} (+${temp.calling_code})`, value: temp.calling_code });
      }
      setValue('tax_display_inclusive', data.tax_display_inclusive === "1");
      setValue('is_blocked_stock_in_exceed_threshold', data.is_blocked_stock_in_exceed_threshold === "1");
      setValue('is_blocked_transfer_in_exceed_threshold', data.is_blocked_transfer_in_exceed_threshold === "1");
      setValue('non_sales_kitchen_queue_enabled', data.non_sales_kitchen_queue_enabled === "1");
      setValue('cash_drawer_threshold', data.cash_drawer_threshold);
      setValue('finance_email', data.finance_email);
      setValue('stock_take_threshold', data.stock_take_threshold);
      setValue('stock_in_threshold', data.stock_in_threshold);
      setValue('currency_id', data.currency_id ? { label: data.currency_code, value: data.currency_id } : "");
      setValue('product', data.product_id ? { label: `${data.product_code} ${data.product_name}`, value: data.product_id } : "");
      setValue('rounding_method', data.rounding_method ? { label: meta?.rounding_methods?.find(x => x.value === data?.rounding_method)?.label, value: data.rounding_method } : '');
      setValue('rounding_step_size', data.rounding_step_size ? { label: data.rounding_step_size, value: data.rounding_step_size } : '');
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      finance_email: val?.finance_email,
      stock_take_threshold: val?.stock_take_threshold,
      stock_in_threshold: val?.stock_in_threshold,
      tax_display_inclusive: val?.tax_display_inclusive ? 1 : 0,
      is_blocked_stock_in_exceed_threshold: val?.is_blocked_stock_in_exceed_threshold ? "1" : "0",
      is_blocked_transfer_in_exceed_threshold: val?.is_blocked_transfer_in_exceed_threshold ? "1" : "0",
      non_sales_kitchen_queue_enabled: val?.non_sales_kitchen_queue_enabled ? 1 : 0,
      cash_drawer_threshold: val?.cash_drawer_threshold,
      currency_id: val?.currency_id?.value,
      is_setting: 1,
      calling_code: val?.calling_code?.value,
      product_id: val?.product?.value,
      rounding_method: val?.rounding_method?.value,
      rounding_step_size: val?.rounding_step_size?.value,
      id: id
    }

    await editOrganization(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Receipt Setting Updated successfully!");
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}