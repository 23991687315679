import React, { useEffect, Fragment, useState } from 'react';
import { Row, Col, Form, Button, message, Upload, Space } from 'antd';
import { dummyRequest, uploadFileV2, getFileName } from 'Features/utility';
import placeholderImg from "Assets/img/product-placeholder.png"
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { id, addPaymentMethod, updatePaymentMethod, meta, data, mode, reloadApi } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState();

  const formFields = [
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'name',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'payment_method_type',
      label: 'Payment Method Type',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      class: 'default-select',
      options: meta?.payment_method_types,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('code', data.code);
      setValue('name', data.name);
      let payment_method_type = meta?.payment_method_types?.find(x => x.value === data.payment_method_type);
      setValue('payment_method_type', data.payment_method_type ? { label: payment_method_type?.label, value: data.payment_method_type } : '');
      if (data?.image_url) {
        setFileList([
          {
            uid: '-1',
            name: getFileName(data?.image_url),
            status: 'done',
            url: data?.image_url
          }
        ])
      } else {
        setFileList();
        setFileId();
      }
      
    }
  }, [data]);

  const handleUpload = async (e) => {
    props.setLoading(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      props.setLoading(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      props.setLoading(false);
    }
  };

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      payment_method_type: val?.payment_method_type?.value,
      blob_id: fileId,
      payment_method_id: id
    }

    await (mode === "add" ? addPaymentMethod : updatePaymentMethod)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(`${mode === "add" ? "Created" : "Updated"} Payment Method successfully!`);
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24} className="text-center">
          <Upload
            listType="picture-card"
            className="avatar-uploader larger"
            showUploadList={false}
            accept="image/*"
            maxCount={1}
            fileList={fileList}
            customRequest={dummyRequest}
            onChange={(e) => handleUpload(e)}
          >
            <Space size={4}>
              {fileList?.length > 0 ? (
                <img
                  src={fileList[0].url}
                  alt="avatar"
                  style={{
                    width: '100%',
                  }}
                />
              ) :
                <div>
                  <img src={placeholderImg} alt="" className='w-100' />
                </div>
              }
            </Space>
          </Upload>
        </Col>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        <Col span={24} className='text-right'>
          <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
        </Col>
      </Row>
    </Form>
  )
}