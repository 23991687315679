import * as action_types from './constants';

const initialState = {
  vendors: {},
  vendor: {},
  purchaseOrders: {},
  purchaseOrdersOption: {},
  purchaseOrder: {},
  option: {},
  suppliedItems: {},
  suppliedItemsOption: {},
  poItems: {},
  poItemsOption: {},
  goodReceives: {},
  goodReceivesOption: {},
  goodReceive: {},
  goodReceiveOption: {},
  grItems: {},
  grItemOption: {},
  transferOuts: {},
  transferOutsOption: {},
  transferOut: {},
  transferOutOption: {},
  toItems: {},
  toItemsOption: {},
  transferIns: {},
  transferInsOption: {},
  transferIn: {},
  transferInOption: {},
  trItems: {},
  trItemsOption: {},
  stockWastages: {},
  stockWastagesOption: {},
  stockWastage: {},
  stockWastageOption: {},
  swItems: {},
  swItemsOption: {},
  stockAdjustments: {},
  stockAdjustmentsOption: {},
  stockAdjustment: {},
  stockAdjustmentOption: {},
  saItems: {},
  saItemsOption: {},
  stockTemplates: {},
  stockTemplatesOption: {},
  stockTemplate: {},
  stockTemplateOption: {},
  stItems: {},
  stItemsOption: {},
  stockTakes: {},
  stockTakesOption: {},
  stockTake: {},
  stockTakeOption: {},
  stakeItems: {},
  stakeItemsOption: {},
  stockMovementList: {},
  stockMovementListMeta: {},
  stockConversions: {},
  stockConversionsOption: {},
  stockConversion: {},
  stockConversionOption: {},
  scItems: {},
  scItemsOption: {}
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.VENDOR_LIST:
      return { ...state, vendors: data };
    case action_types.VENDOR_DETAIL:
      return { ...state, vendor: data, option: meta };
    case action_types.VENDOR_OPTION:
      return { ...state, option: data };
    case action_types.PURCHASE_ORDER_LIST:
      return { ...state, purchaseOrders: data, purchaseOrdersOption: meta };
    case action_types.PURCHASE_ORDER_DETAIL:
      return { ...state, purchaseOrder: data, option: meta };
    case action_types.PURCHASE_ORDER_OPTION:
      return { ...state, option: data };
    case action_types.SUPPLIED_ITEM_LIST:
      return { ...state, suppliedItems: data, suppliedItemsOption: meta };
    case action_types.PO_ITEM_LIST:
      return { ...state, poItems: data, poItemsOption: meta };
    case action_types.GOOD_RECEIVE_LIST:
      return { ...state, goodReceives: data, goodReceivesOption: meta };
    case action_types.GOOD_RECEIVE_DETAIL:
      return { ...state, goodReceive: data, goodReceiveOption: meta };
    case action_types.GOOD_RECEIVE_OPTION:
      return { ...state, goodReceiveOption: data };
    case action_types.GR_ITEM_LIST:
      return { ...state, grItems: data, grItemOption: meta };
    case action_types.TRANSFER_OUT_LIST:
      return { ...state, transferOuts: data, transferOutsOption: meta };
    case action_types.TRANSFER_OUT_DETAIL:
      return { ...state, transferOut: data, transferOutOption: meta };
    case action_types.TRANSFER_OUT_OPTION:
      return { ...state, transferOutOption: data };
    case action_types.TO_ITEM_LIST:
      return { ...state, toItems: data, toItemsOption: meta };
    case action_types.TRANSFER_IN_LIST:
      return { ...state, transferIns: data, transferInsOption: meta };
    case action_types.TRANSFER_IN_DETAIL:
      return { ...state, transferIn: data, transferInOption: meta };
    case action_types.TRANSFER_IN_OPTION:
      return { ...state, transferInOption: data };
    case action_types.TR_ITEM_LIST:
      return { ...state, trItems: data, trItemsOption: meta };
    case action_types.STOCK_WASTAGE_LIST:
      return { ...state, stockWastages: data, stockWastagesOption: meta };
    case action_types.STOCK_WASTAGE_DETAIL:
      return { ...state, stockWastage: data, stockWastageOption: meta };
    case action_types.STOCK_WASTAGE_OPTION:
      return { ...state, stockWastageOption: data };
    case action_types.SW_ITEM_LIST:
      return { ...state, swItems: data, swItemsOption: meta };
    case action_types.STOCK_ADJUSTMENT_LIST:
      return { ...state, stockAdjustments: data, stockAdjustmentsOption: meta };
    case action_types.STOCK_ADJUSTMENT_DETAIL:
      return { ...state, stockAdjustment: data, stockAdjustmentOption: meta };
    case action_types.STOCK_ADJUSTMENT_OPTION:
      return { ...state, stockAdjustmentOption: data };
    case action_types.SA_ITEM_LIST:
      return { ...state, saItems: data, saItemsOption: meta };
    case action_types.STOCK_TEMPLATE_LIST:
      return { ...state, stockTemplates: data, stockTemplatesOption: meta };
    case action_types.STOCK_TEMPLATE_DETAIL:
      return { ...state, stockTemplate: data, stockTemplateOption: meta };
    case action_types.STOCK_TEMPLATE_OPTION:
      return { ...state, stockTemplateOption: data };
    case action_types.ST_ITEM_LIST:
      return { ...state, stItems: data, stItemsOption: meta };
    case action_types.STOCK_TAKE_LIST:
      return { ...state, stockTakes: data, stockTakesOption: meta };
    case action_types.STOCK_TAKE_DETAIL:
      return { ...state, stockTake: data, stockTakeOption: meta };
    case action_types.STOCK_TAKE_OPTION:
      return { ...state, stockTakeOption: data };
    case action_types.STOCK_TAKE_ITEM_LIST:
      return { ...state, stakeItems: data, stakeItemsOption: meta };
    case action_types.STOCK_CONVERSION_LIST:
      return { ...state, stockConversions: data, stockConversionsOption: meta };
    case action_types.STOCK_CONVERSION_DETAIL:
      return { ...state, stockConversion: data, stockConversionOption: meta };
    case action_types.STOCK_CONVERSION_OPTION:
      return { ...state, stockConversionOption: data };
    case action_types.STOCK_CONVERSION_ITEM_LIST:
      return { ...state, scItems: data, scItemsOption: meta };

    case action_types.STOCK_MOVEMENT_LIST:
      return { ...state, stockMovementList: data, stockMovementListMeta: meta };

    default:
      return state;
  }
};
