import * as action_types from './constants';

const initialState = {
  outlets: {},
  outletsMeta: {},
  option: {},
  outlet: {},
  outletGroups: {},
  outletGroup: {},
  countryData: {},
  menu: [],
  posUsers: {},
  posUsersMeta: {},
  blockedOrderTypeListData: {},
  blockedOrderTypeListMeta: {},
  blockedProductListData: {},
  blockedProductListMeta: {},
  uploadFileList: {},
  uploadFileListMeta: {},
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.OUTLET_LIST:
      return { ...state, outlets: data, outletsMeta: meta };
    case action_types.OUTLET_DETAIL:
      return { ...state, outlet: data, option: meta };
    case action_types.OUTLET_OPTION:
      return { ...state, option: data };
    case action_types.OUTLET_GROUP_LIST:
      return { ...state, outletGroups: data };
    case action_types.OUTLET_GROUP_DETAIL:
      return { ...state, outletGroup: data };
    case action_types.COUNTRY:
      return { ...state, countryData: data };
    case action_types.MENU_STAT:
      return { ...state, menu: data };
    case action_types.OUTLET_POS_USER_LIST:
      return { ...state, posUsers: data, posUsersMeta: meta };
    case action_types.BLOCKED_ORDER_TYPE_LIST:
      return { ...state, blockedOrderTypeListData: data, blockedOrderTypeListMeta: meta };
    case action_types.BLOCKED_PRODUCT_LIST:
      return { ...state, blockedProductListData: data, blockedProductListMeta: meta };
    case action_types.OUTLET_UPLOAD_FILES:
      return { ...state, uploadFileList: data, uploadFileListMeta: meta };

    default:
      return state;
  }
};
