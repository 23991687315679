import React, { Fragment, useEffect, useState } from 'react';
import { Select, Form, Row, Col, Space, Typography, Spin, Button, message, Upload, Tabs } from 'antd';
import * as TabCards from './tabList';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import { SelectField } from 'Atoms/FormElement';
import { getSelectedMenuType, getKioskValue } from '../../../../../ducks/actions';

export default (props) => {
  const tabs = [
    {
      name: "POS",
      Comp: 'Pos',
    },
    {
      name: "Kiosk",
      Comp: 'Kiosk',
    },
  ];


  const { id, activeTab, navigate } = props;
  const dispatch = useDispatch();
  const { control, formState: { errors }, handleSubmit, setValue, clearErrors } = useForm();
  const option = useSelector((state) => state.product.menuCategoryOption);
  const kioskValue = useSelector((state) => state.product.kioskValue);
  const [subTab, setSubTab] = useState("1")
  const selectedMenuType = useSelector((state) => state.product.selectedMenuType);
  const [tabItem, setTabItem] = useState(tabs)

  const onChange = (e) => {
    dispatch(getSelectedMenuType(option?.product_menu_types?.find(x => x.id === e.value)))
  };

  useEffect(() => {
    if (option && 'product_menu_types' in option) {
      if (selectedMenuType) {
        let temp = option.product_menu_types.find(x => x.id === selectedMenuType.id);
        if(temp) {
          dispatch(getSelectedMenuType(temp))
          setValue('menu_type', { label: temp?.order_type_desc, value: temp?.id });
        } else {
          onChange({value: option?.product_menu_types[0]?.id});
          setValue('menu_type', { label: option?.product_menu_types[0]?.order_type_desc, value: option?.product_menu_types[0]?.id });
        }
      } else {
        onChange({value: option?.product_menu_types[0]?.id});
        setValue('menu_type', { label: option?.product_menu_types[0]?.order_type_desc, value: option?.product_menu_types[0]?.id });
      }
    }
  }, [option]);

  useEffect(() => {
    if(selectedMenuType?.order_type_code !== 'DINE_IN' && selectedMenuType?.order_type_code !== 'TAKE_AWAY') {
      setTabItem((items) => items.filter((item) => item.name === "POS"))
    } else {
      setTabItem(tabs)
    }
  }, [selectedMenuType])

  const changeTab = (activeKeyValue) => {
    setSubTab(activeKeyValue)
    dispatch(getKioskValue(activeKeyValue))
  }

  return (
    <>
    <Row gutter={[20, 20]}>
      <Col span={12}>
        <SelectField
          fieldname='menu_type'
          label='Menu Type'
          class='mb-0 w-100'
          initValue={''}
          control={control}
          iProps={{ placeholder: 'Please select' }}
          selectOption={option?.product_menu_types?.map(x => ({ label: x.order_type_desc, value: x.id }))}
          onChange={onChange}
        />
      </Col>
    </Row>
    <Tabs items={
      tabItem.map((item, index) => {
        const Cardi = TabCards[item.Comp];
        return {
          key: (index + 1).toString(),
          label: item.name,
          forceRender: true,
          children: <Cardi {...props} activeTab={activeTab} id={id} subTab={kioskValue} />,
        }
      })
    } defaultActiveKey={kioskValue} onChange={(activeKey) => changeTab(activeKey)} />
    </>
  );
}