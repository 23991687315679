import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Table, message, Empty } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getBillSummaryListing } from '../ducks/actions'
import { exportSalesReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.billSummaryListing);
  const meta = useSelector((state) => state.reporting.billSummaryListingOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getBillSummaryListing(null, props.setLoading, true));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      let temp2 = [];
      if (search?.products?.length > 0) {
        search?.products?.map(x => {
          temp2.push(x.value)
        });
      }

      searching = {
        is_non_sales: search?.non_sales?.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
      };
      if (search?.outlets?.length > 0) {
        let temp = [];
        search?.outlets?.map(x => {
          temp.push(x.value)
        });
        searching["location_ids"] = JSON.stringify(temp);
      }
      setSearchVal(searching);
      dispatch(getBillSummaryListing(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getBillSummaryListing(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (!orders?.total_count || orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }

    props.setLoading(true);

    await exportSalesReport(searchVal, 'bill_summary_listing').then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {/* update roles to BILL_SUMMARY_LISTING */}
          <HeadingChip title={"Bill Summary Listing Report"} btnList={allowed([AllRoles.REPORTING.SALES_DETAILS], 'write') ? btnList : null} />
        </Col>
        <Col span={24}>
          <Card bordered={false}>
            <Search onSearch={onSearch} field1={outlets} />

            <div className="table-responsive" style={{overflowX: 'auto', marginTop: "30px"}}>
              <table className='table table-borderless w-100' style={{ borderCollapse: "collapse"}}>
                <thead>
                  <tr>
                    <th style={{minWidth: "180px"}} className='text-center'>Business Date</th>
                    <th style={{minWidth: "180px"}} className='text-right'>Trans. Count</th>
                    <th style={{minWidth: "180px"}} className='text-right'>Guest Count</th>
                    <th style={{minWidth: "150px"}} className='text-right'>Gross Amount</th>
                    <th style={{minWidth: "150px"}} className='text-right'>Discount</th>
                    <th style={{minWidth: "150px"}} className='text-right'>Net Sales</th>
                    <th style={{minWidth: "150px"}} className='text-right'>Tax</th>
                    <th style={{minWidth: "150px"}} className='text-right'>Inclusive Tax Amount</th>
                    <th style={{minWidth: "150px"}} className='text-right'>Exclusive Tax Amount</th>
                    <th style={{minWidth: "150px"}} className='text-right'>Voucher</th>
                    <th style={{minWidth: "150px"}} className='text-right'>Adj. Amount</th>
                    <th style={{minWidth: "150px"}} className='text-right'>Net Total</th>
                    {
                      orders?.fe_cols?.map((x, ind) => {
                        return (
                          <th style={{minWidth: "150px"}} key={ind} className="text-right">{x.label}</th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    orders?.list?.length <= 0 &&
                    <tr>
                      <td colSpan="10">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                  {
                    orders?.list?.map((x, ind) => {
                      return (
                        <Fragment key={ind}>
                          <tr>
                            <td colSpan="4" className='fwbold'>{"-"} {x.location_code}</td>
                          </tr>
                          {
                            x.list?.map((y, index) => {
                              return (
                                <tr key={index}>
                                  <td className='text-center'>{y.date}</td>
                                  <td align="right">{y.trans_count}</td>
                                  <td align="right">{y.guest_count}</td>
                                  <td align="right">{comma(y.gross_amount)}</td>
                                  <td align="right">{comma(y.discount_amount)}</td>
                                  <td align="right">{comma(y.net_sales)}</td>
                                  <td align="right">{comma(y.tax_amount)}</td>
                                  <td align="right">{comma(y.inclusive_tax_amount)}</td>
                                  <td align="right">{comma(y.exclusive_tax_amount)}</td>
                                  <td align="right">{comma(y.voucher_amount)}</td>
                                  <td align="right">{comma(y.adjustment_amount)}</td>
                                  <td align="right">{comma(y.net_total)}</td>
                                  {
                                    orders?.fe_cols?.map((z, zIndex) => {
                                      return (
                                        <td align="right" key={zIndex}>
                                          {comma(y[z.key])}
                                        </td>
                                      )
                                    })
                                  }
                                </tr>
                              )
                            })
                          }
                          <tr className='bg-gray text-white fwbold'>
                            <td align='right'>SubTotal</td>
                            {
                              x?.sub_totals && Object.entries(x?.sub_totals)?.map(([key, data], zIndex) => {
                                return (
                                  <td key={zIndex} align='right'>{comma(data)}</td>
                                )
                              })
                            }
                          </tr>
                        </Fragment>
                      )
                    })
                  }
                  <tr className='bg-gray fwbold text-white'>
                    {
                      orders?.list?.length <= 0
                      ? <td colSpan="99">Grand Total</td>
                      : <td align='right'>Grand Total</td>
                    }
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <td key={index} align='right'>
                            {comma(data)}
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}