import React, { Fragment } from 'react';

export default (props) => {
  const { columns, data } = props;

  return (
    <div className="inventory-table-container">
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.po_details_listing?.list?.map(item =>
                <th className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} key={`Title ${item.title}`}>
                  {item.title}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.list?.map((listItem, listItemIndex) =>
              <Fragment >
                <tr key={`List Item ${listItemIndex}`}>
                  <td>{listItem.date}</td>
                  <td>{listItem.code}</td>
                  <td>{listItem.month}</td>
                  <td>{listItem.year}</td>
                  <td>{listItem.store}</td>
                  <td>{listItem.product_category}</td>
                  <td>{listItem.reference}</td>
                  <td>{listItem.vendor}</td>
                  <td>{listItem.name}</td>
                  <td>{listItem.base_uom_desc}</td>
                  <td>{listItem.uom_desc}</td>
                  <td align='right'>{listItem.unit_cost}</td>
                  <td align='right'>{listItem.quantity}</td>
                </tr>
              </Fragment>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}