import byebugAxios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const getBrands = (payload) => {
  return byebugAxios.post(`${externalBaseUrl}/organizations/list`, payload);
};

export const updateOrganization = (id) => {
  return byebugAxios.post(`${externalBaseUrl}/organizations/set-current`, { id: id });
};

export const unsetEntity = () => {
  return byebugAxios.post(`${externalBaseUrl}/organizations/unset-current`);
};

export const addOrganization = (payload) => {
  return byebugAxios.post(`${externalBaseUrl}/organizations/create`, payload);
};
