import React from 'react';
import { Tabs } from 'antd';
import * as TabCards from './tabList';

export default (props) => {
  const { setActiveTab, oneTab, searchParams } = props;
  const defaultActive = searchParams?.get('t');

  const tabs = [
    {
      name: "Details",
      Comp: 'Outlet',
    },
    {
      name: "Receipt",
      Comp: 'PriceType',
    },
    {
      name: "EMS User",
      Comp: 'RegisteredUser',
    },
    {
      name: "POS User",
      Comp: 'PosUser',
    },
    {
      name: "Blocked Order Type",
      Comp: 'BlockedOrderType',
    },
    {
      name: "Blocked Item",
      Comp: 'BlockedItem',
    },
    {
      name: "FA Integration",
      Comp: 'FaIntegration',
    },
    {
      name: "Mall Integration",
      Comp: 'MallIntegration',
    },
  ];

  return (
    <Tabs items={
        (oneTab ? [tabs[0]] : tabs).map((item, index) => {
          const Cardi = TabCards[item.Comp];
          return {
            key: (index + 1).toString(),
            label: item.name,
            forceRender: true,
            children: <Cardi {...props} />
          }
        })
    } defaultActiveKey={defaultActive || "1"} onChange={(activeKey) => setActiveTab(activeKey)}/>
  );
};
