import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Button, Typography } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesOrderList } from '../ducks/actions'
import { Popup } from 'Atoms/Popup';
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'

const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.members.salesOrders);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const [visible, setVisible] = useState(false);
  const [rec, setRecord] = useState([]);

  const colName = [
    {
      title: 'ID',
      dataIndex: 'reference',
      key: 'reference',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Customer',
      dataIndex: 'user_name',
      key: 'user_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Paid" ? "c-success" : "c-default"}>
            {text}
          </span>
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="Sales Order"
          btnAction1={() => {
            setVisible(true);
            setRecord(record);
          }}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const popup = {
    closable: false,
    visibility: visible,
    content: 
      <Space direction='vertical' size={30} className='w-100'>
        <Title level={4} className='m-0'>
          Sales Order Detail
        </Title>
        <Row gutter={[10, 10]}>
          {/* details refer to receipt UI */}
        </Row>
        <Row gutter={10}>
          <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
            setVisible(false);
            setRecord();
          }}>Close</Button></Col>
        </Row>
      </Space>
    ,
    width: 625,
    onCancel: () => {
      setVisible(false);
      setRecord();
    },
  };

  const btnList = [];

  useEffect(() => {
    dispatch(getSalesOrderList(1, limit, '', '', null, props.setLoading));
  }, []);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getSalesOrderList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
    } else {
      dispatch(getSalesOrderList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        reference: search.reference,
      };
      setSearchVal(searching);
      dispatch(getSalesOrderList(1, limit, '', '', searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getSalesOrderList(1, limit, '', '', null, props.setLoading));
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setVisible(true);
          setRecord(record);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Sales Orders"} />
        </Col>
        <Col span={24} className="">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            ListData={orders?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            pagination={{
              total: orders?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}