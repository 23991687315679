import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { apiresource, apiMethod, externalBaseUrl } from '../../../../configs/constants';

export const getCountry = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.get(`${apiresource}/Country?limit_page_length=0`);
    dispatch({
      type: action_types.COUNTRY,
      data: data,
    });
  };
};

export const updateMenu = (stat) => {
  return (dispatch) => {
    dispatch({
      type: action_types.MENU_STAT,
      data: stat,
    });
  };
};

export const getListQueryItems = (queryItem, ...value) => {
  let queryObject;

  if(value?.[0] === null) {
    queryObject = null
  } else {
    const [page, limit, sortOrder, sortColKey, searchValue] = value;
    queryObject = { page, limit, sortOrder, sortColKey, searchValue };
  }

  return (dispatch) => {
    dispatch({
      type: action_types.LIST_QUERY_ITEMS,
      data: queryObject,
      queryItem: queryItem
    });
  };
};

export const setStats = (data) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.SIDE_MENU_STATS,
      data: data,
    });
  };
}

export const getStatistics = (search = {}, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/organizations/statistic`, {
    q: JSON.stringify({
      ...search
    })
  });
  load && load(false);
  dispatch({
    type: action_types.ENTITY_STATISTICS,
    data: data,
    meta: meta
  });
}

export const onClear = () => {
  return { type: action_types.DESTROY_SESSION };
};