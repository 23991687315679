export const POS_TERMINAL_LIST = 'POS_TERMINAL_LIST';
export const POS_TERMINAL_DETAIL = 'POS_TERMINAL_DETAIL';
export const POS_TERMINAL_OPTION = 'POS_TERMINAL_OPTION';

export const VOUCHER_TYPE_LIST = 'VOUCHER_TYPE_LIST';
export const VOUCHER_TYPE_DETAIL = 'VOUCHER_TYPE_DETAIL';
export const VOUCHER_TYPE_OPTION = 'VOUCHER_TYPE_OPTION';

export const GENERATOR_OPTION = 'GENERATOR_OPTION';
export const VOUCHERS_LIST = 'VOUCHERS_LIST';
export const VOUCHERS_DETAIL = 'VOUCHERS_DETAIL';
export const VOUCHER_ITEM_LIST = 'VOUCHER_ITEM_LIST';

export const KITCHEN_DISPLAY_LIST = 'KITCHEN_DISPLAY_LIST';
export const KITCHEN_DISPLAY_DETAIL = 'KITCHEN_DISPLAY_DETAIL';
export const KITCHEN_DISPLAY_OPTION = 'KITCHEN_DISPLAY_OPTION';

export const KITCHEN_QUEUE_LIST = 'KITCHEN_QUEUE_LIST';
export const KITCHEN_QUEUE_DETAIL = 'KITCHEN_QUEUE_DETAIL';
export const KITCHEN_QUEUE_OPTION = 'KITCHEN_QUEUE_OPTION';

export const DISCOUNT_LIST = 'DISCOUNT_LIST';
export const DISCOUNT_DETAIL = 'DISCOUNT_DETAIL';
export const DISCOUNT_OPTION = 'DISCOUNT_OPTION';
export const DISCOUNT_AVAILABILITY_LIST = 'DISCOUNT_AVAILABILITY_LIST';
export const DISCOUNT_AVAILABLE_PRODUCT_LIST = 'DISCOUNT_AVAILABLE_PRODUCT_LIST';

export const PRINTER_LIST = 'PRINTER_LIST';
export const PRINTER_DETAIL = 'PRINTER_DETAIL';
export const PRINTER_OPTION = 'PRINTER_OPTION';

export const TERMINAL_ACTIVITY_LIST = 'TERMINAL_ACTIVITY_LIST';
export const ALL_ACTIVITY_LIST = 'ALL_ACTIVITY_LIST';
