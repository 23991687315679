import * as action_types from './constants';

const initialState = {
  countryData: {},
  menu: [],
  listQueryItems: {
    outletListQuery: null,
    outletGroupListQuery: null,
    productListQuery: null,
    productMenuListQuery: null,
    orderTypeListQuery: null,
    ingredientListQuery: null,
    categoryListQuery: null,
    uomListQuery: null,
    availableProductListQuery: null,
    emsUsersListQuery: null,
    emsRolesListQuery: null,
    posUsersListQuery: null,
    posRolesListQuery: null,
    currencyListQuery: null,
    paymentTermListQuery: null,
    regionListQuery: null,
    orderTypeGroupListQuery: null,
    taxListQuery: null,
    taxConditionListQuery: null,
    reasonListQuery: null,
    remarksListQuery: null,
    remarkTypeListQuery: null,
    tenderMediaTypeListQuery: null,
    setupListQuery: null,
    merchantListQuery: null,
    posTerminalListQuery: null,
    voucherTypeListQuery: null,
    vouchersListQuery: null,
    discountListQuery: null,
    kitchenQueueListQuery: null,
    printersListQuery: null,
    auditLogListQuery: null,
    vendorListQuery: null,
    purchaseOrderListQuery: null,
    goodReceiveListQuery: null,
    transferOutListQuery: null,
    transferReceiveListQuery: null,
    stockWastageListQuery: null,
    stockAdjListQuery: null,
    stockTemplateListQuery: null,
    stockTakeListQuery: null,
    customerListQuery: null,
    salesListQuery: null,
    stockBalanceListQuery: null,
    netsuiteSyncLogsQuery: null
  },
  menuStats: {},
  entityStats: {},
  statsMeta: null
};

export default (state = initialState, action) => {
  const { type, data, meta, queryItem } = action;
  switch (type) {
    case action_types.COUNTRY:
      return { ...state, countryData: data };
    case action_types.MENU_STAT:
      return { ...state, menu: data };
    case action_types.LIST_QUERY_ITEMS:
      return { ...state, listQueryItems: {...initialState.listQueryItems, [queryItem]: data} };
    case action_types.SIDE_MENU_STATS:
      return { ...state, menuStats: data };
    case action_types.ENTITY_STATISTICS:
      return { ...state, entityStats: data, statsMeta: meta };
    

    default:
      return state;
  }
};
