import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';

const _ = require('lodash');

export default (props) => {

  const { errors, control, meta, editable } = props

  const formFields = [
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable
    },
    {
      name: 'desc',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable
    },
    {
      name: 'apply_method',
      label: 'Apply Method',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      options: meta?.apply_methods?.map(x => ({label: x, value: x})),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable
    },
    {
      name: 'apply_reference',
      label: 'Apply Reference',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable
    },
    {
      name: 'value',
      label: 'Default Value',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      number: true,
      min: 1,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable
    },
    {
      name: 'value_type',
      label: 'Value Type',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      options: meta?.value_types?.map(x => ({label: x, value: x})),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable
    },
    {
      name: 'counter_reference',
      label: 'Doc. Counter for Running No.',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable,
      tips: "Enter 0 if it's for voucher import"
    },
    {
      name: 'counter_digit_number',
      label: 'Define Running No.',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      number: true,
      min: 0,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !editable,
      tips: "Enter 0 if it's for voucher import"
    },
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}