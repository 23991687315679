import React, { useState } from 'react';
import { Button, Form, Row, Col, Input } from 'antd';
import { DateField, SelectField, InputField, SwitchField, TimeField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

const customParseFormat = require('dayjs/plugin/customParseFormat');

// Extend Day.js with customParseFormat plugin
dayjs.extend(customParseFormat);

// Set the locale to start the week from Monday
dayjs.locale({
  name: 'custom',
  weekStart: 1, // Monday (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
});

// Use the custom locale
dayjs.locale('custom');

const dateRange = [
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'Yesterday',
    value: 'yesterday'
  },
  {
    label: 'Last 7 Days',
    value: 'last7Days'
  },
  {
    label: 'Last 30 Days',
    value: 'last30Days'
  },
  {
    label: 'This Week',
    value: 'thisWeek'
  },
  {
    label: 'Past Week',
    value: 'pastWeek'
  },
  {
    label: 'This Month',
    value: 'thisMonth'
  },
  {
    label: 'Past Month',
    value: 'pastMonth'
  },
  {
    label: 'This Year',
    value: 'thisYear'
  },
  {
    label: 'Past Year',
    value: 'pastYear'
  },
]

export default (props) => {

  const { meta } = props;
  const { control, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const [showParams, setShowParams] = useState([]);

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  const onPrint = () => {
    props.downloadPdf();
  };

  const clearProps = () => {
    setValue('date_type', '');
    setValue('start_date', '');
    setValue('time_from', '');
    setValue('end_date', '');
    setValue('time_to', '');
    setValue('record_count', '');
    setValue('username', '');
    setValue('category_level', '');
    setValue('shift_no', '');
    setValue('session_hour', '');
    setValue('group_by', '');
    setValue('layout', '');
    setValue('sales_by', '');
    setValue('hide_cancelled', false);
    setValue('hide_voided', false);
    setValue('is_consolidate', false);
  };

  const changeDateRange = (e) => {
    const filter = e.value;
    setValue("time_from", dayjs('00:00:00', 'HH:mm:ss'));
    setValue("time_to", dayjs('23:59:59', 'HH:mm:ss'));

    switch (filter) {
      case "today":
        setValue("start_date", dayjs());
        setValue("end_date", dayjs());
        break;
      case "yesterday":
        setValue("start_date", dayjs().subtract(1, 'day'));
        setValue("end_date", dayjs().subtract(1, 'day'));
        break;
      case "last7Days":
        setValue("start_date", dayjs().subtract(7, 'day'));
        setValue("end_date", dayjs());
        break;
      case "last30Days":
        setValue("start_date", dayjs().subtract(30, 'day'));
        setValue("end_date", dayjs());
        break;
      case "thisWeek":
        setValue("start_date", dayjs().startOf('week'));
        setValue("end_date", dayjs().endOf('week'));
        break;
      case "pastWeek":
        setValue("start_date", dayjs().subtract(1, 'week').startOf('week'));
        setValue("end_date", dayjs().subtract(1, 'week').endOf('week'));
        break;
      case "thisMonth":
        setValue("start_date", dayjs().startOf('month'));
        setValue("end_date", dayjs().endOf('month'));
        break;
      case "pastMonth":
        setValue("start_date", dayjs().subtract(1, 'month').startOf('month'));
        setValue("end_date", dayjs().subtract(1, 'month').endOf('month'));
        break;
      case "thisYear":
        setValue("start_date", dayjs().startOf('year'));
        setValue("end_date", dayjs().endOf('year'));
        break;
      case "pastYear":
        setValue("start_date", dayjs().subtract(1, 'year').startOf('year'));
        setValue("end_date", dayjs().subtract(1, 'year').endOf('year'));
        break;
      default:
        break;
    }
  };

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <SelectField
            fieldname='group'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Select Report Type' }}
            selectOption={meta.report_types?.map(x => ({ label: x.desc, value: x.code, required_params: x.required_params}))}
            onChange={(e) => {
              clearProps();
              setShowParams(e.required_params);
            }}
            rules={{
              required: 'Please select report type'
            }}
            validate={errors.group && 'error'}
            validMessage={errors.group && errors.group.message}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
        <Col flex="70px">
          <Button className='w-100 b-success' size='large' htmlType='button' onClick={() => onPrint()}>Print</Button>
        </Col>
      </Row>
      <Row className='w-100 mt-1-5' gutter={[10, 10]}>
        {showParams?.length > 0 &&
          <Col span={6}>
            <SelectField
              fieldname='date_type'
              label=''
              initValue={''}
              selectOption={dateRange}
              control={control}
              class='mb-0 w-100 default-select'
              iProps={{ placeholder: 'Date Range', size: 'large' }}
              onChange={changeDateRange}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("date_from") &&
          <Col span={6}>
            <DateField
              fieldname='start_date'
              label=''
              initValue={''}
              control={control}
              class='mb-0 w-100'
              iProps={{ placeholder: 'Date (From)', size: 'large' }}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("time_from") &&
          <Col span={6}>
            <TimeField
              fieldname={"time_from"}
              label={""}
              control={control}
              class="mb-0"
              iProps={{ size: 'large', placeholder: "Time From" }}
              initValue={''}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("date_to") &&
          <Col span={6}>
            <DateField
              fieldname='end_date'
              label=''
              initValue={''}
              control={control}
              class='mb-0 w-100'
              iProps={{ placeholder: 'Date (To)', size: 'large' }}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("time_to") &&
          <Col span={6}>
            <TimeField
              fieldname={"time_to"}
              label={""}
              control={control}
              class="mb-0"
              iProps={{ size: 'large', placeholder: "Time To" }}
              initValue={''}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("record_count") &&
          <Col span={6}>
            <InputField
              fieldname={"record_count"}
              label={""}
              control={control}
              class="mb-0"
              iProps={{ size: 'large', placeholder: "Record Count" }}
              initValue={''}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("username") &&
          <Col span={6}>
            <InputField
              fieldname={"username"}
              label={""}
              control={control}
              class="mb-0"
              iProps={{ size: 'large', placeholder: "Username" }}
              initValue={''}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("hide_cancelled") &&
          <Col span={6}>
            <SwitchField
              fieldname={"hide_cancelled"}
              label={"Hide Cancelled?"}
              control={control}
              iProps={{ size: 'large' }}
              initValue={''}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("hide_voided") &&
          <Col span={6}>
            <SwitchField
              fieldname={"hide_voided"}
              label={"Hide Voided?"}
              control={control}
              iProps={{ size: 'large' }}
              initValue={''}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("category_level") &&
          <Col span={6}>
            <SelectField
              fieldname='category_level'
              label=''
              class='mb-0 w-100 default-select'
              initValue={''}
              control={control}
              iProps={{ placeholder: 'Select Category Level' }}
              selectOption={meta.category_levels?.map(x => ({ label: x, value: x }))}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("shift_no") &&
          <Col span={6}>
            <SelectField
              fieldname='shift_no'
              label=''
              class='mb-0 w-100 default-select'
              initValue={''}
              control={control}
              iProps={{ placeholder: 'Select Shift No' }}
              selectOption={meta.shift_nos?.map(x => ({ label: x, value: x }))}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("session_hour") &&
          <Col span={6}>
            <SelectField
              fieldname='session_hour'
              label=''
              class='mb-0 w-100 default-select'
              initValue={''}
              control={control}
              iProps={{ placeholder: 'Select Session Hour' }}
              selectOption={meta.session_hours?.map(x => ({ label: x, value: x }))}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("group_by") &&
          <Col span={6}>
            <SelectField
              fieldname='group_by'
              label=''
              class='mb-0 w-100 default-select'
              initValue={''}
              control={control}
              iProps={{ placeholder: 'Select Group By' }}
              selectOption={meta.group_bys?.map(x => ({ label: x, value: x }))}
            />
            </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("layout") &&
          <Col span={6}>
            <SelectField
              fieldname='layout'
              label=''
              class='mb-0 w-100 default-select'
              initValue={''}
              control={control}
              iProps={{ placeholder: 'Select Report Layout' }}
              selectOption={meta.layouts?.map(x => ({ label: x, value: x }))}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("sales_by") &&
          <Col span={6}>
            <SelectField
              fieldname='sales_by'
              label=''
              class='mb-0 w-100 default-select'
              initValue={''}
              control={control}
              iProps={{ placeholder: 'Select Sales By' }}
              selectOption={meta.sales_bys?.map(x => ({ label: x, value: x }))}
            />
          </Col>
        }
        {
          showParams?.length > 0 && showParams?.includes("is_consolidate") &&
          <Col span={24}>
            <SwitchField
              fieldname={"is_consolidate"}
              label={"Is Consolidated?"}
              control={control}
              iProps={{ size: 'large' }}
              initValue={''}
            />
          </Col>
        }
      </Row>
    </Form>
  )
}