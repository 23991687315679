export const VENDOR_LIST = 'VENDOR_LIST';
export const VENDOR_DETAIL = 'VENDOR_DETAIL';
export const PURCHASE_ORDER_LIST = 'PURCHASE_ORDER_LIST';
export const PURCHASE_ORDER_DETAIL = 'PURCHASE_ORDER_DETAIL';
export const PURCHASE_ORDER_OPTION = 'PURCHASE_ORDER_OPTION';
export const SUPPLIED_ITEM_LIST = 'SUPPLIED_ITEM_LIST';
export const PO_ITEM_LIST = 'PO_ITEM_LIST';
export const VENDOR_OPTION = 'VENDOR_OPTION';

export const GOOD_RECEIVE_LIST = 'GOOD_RECEIVE_LIST';
export const GOOD_RECEIVE_DETAIL = 'GOOD_RECEIVE_DETAIL';
export const GOOD_RECEIVE_OPTION = 'GOOD_RECEIVE_OPTION';
export const GR_ITEM_LIST = 'GR_ITEM_LIST';

export const TRANSFER_OUT_LIST = 'TRANSFER_OUT_LIST';
export const TRANSFER_OUT_DETAIL = 'TRANSFER_OUT_DETAIL';
export const TRANSFER_OUT_OPTION = 'TRANSFER_OUT_OPTION';
export const TO_ITEM_LIST = 'TO_ITEM_LIST';

export const TRANSFER_IN_LIST = 'TRANSFER_IN_LIST';
export const TRANSFER_IN_DETAIL = 'TRANSFER_IN_DETAIL';
export const TRANSFER_IN_OPTION = 'TRANSFER_IN_OPTION';
export const TR_ITEM_LIST = 'TR_ITEM_LIST';

export const STOCK_WASTAGE_LIST = 'STOCK_WASTAGE_LIST';
export const STOCK_WASTAGE_DETAIL = 'STOCK_WASTAGE_DETAIL';
export const STOCK_WASTAGE_OPTION = 'STOCK_WASTAGE_OPTION';
export const SW_ITEM_LIST = 'SW_ITEM_LIST';

export const STOCK_ADJUSTMENT_LIST = 'STOCK_ADJUSTMENT_LIST';
export const STOCK_ADJUSTMENT_DETAIL = 'STOCK_ADJUSTMENT_DETAIL';
export const STOCK_ADJUSTMENT_OPTION = 'STOCK_ADJUSTMENT_OPTION';
export const SA_ITEM_LIST = 'SA_ITEM_LIST';

export const STOCK_TEMPLATE_LIST = 'STOCK_TEMPLATE_LIST';
export const STOCK_TEMPLATE_DETAIL = 'STOCK_TEMPLATE_DETAIL';
export const STOCK_TEMPLATE_OPTION = 'STOCK_TEMPLATE_OPTION';
export const ST_ITEM_LIST = 'ST_ITEM_LIST';

export const STOCK_TAKE_LIST = 'STOCK_TAKE_LIST';
export const STOCK_TAKE_DETAIL = 'STOCK_TAKE_DETAIL';
export const STOCK_TAKE_OPTION = 'STOCK_TAKE_OPTION';
export const STOCK_TAKE_ITEM_LIST = 'STOCK_TAKE_ITEM_LIST';

export const STOCK_CONVERSION_LIST = 'STOCK_CONVERSION_LIST';
export const STOCK_CONVERSION_DETAIL = 'STOCK_CONVERSION_DETAIL';
export const STOCK_CONVERSION_OPTION = 'STOCK_CONVERSION_OPTION';
export const STOCK_CONVERSION_ITEM_LIST = 'STOCK_CONVERSION_ITEM_LIST';

export const STOCK_MOVEMENT_LIST = 'STOCK_MOVEMENT_LIST';
