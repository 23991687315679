import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { getOutletGroup } from "../../ducks/actions";
import { editOutletGroup } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm();
  const data = useSelector((state) => state.outlet.outletGroup);

  useEffect(() => {
    dispatch(getOutletGroup(id, props.setLoading));
  }, []);

  useEffect(() => {
    if(data && 'id' in data) {
      setValue('code', data.code);
      setValue('desc', data.desc);
    }
  }, [data])

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      code: val.code,
      desc: val.desc,
      location_group_id: id
    }
    await editOutletGroup(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Outlet Group updated successfully!");
        setTimeout(() => navigate("/outlet-group"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>Edit Outlet Group</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                {
                  allowed([AllRoles.OUTLET.OUTLETGROUP], 'write') &&
                  <Button size="large" htmlType='submit' className='green-btn'>Save</Button>
                }
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/outlet-group')}>{'<'} Back</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <InputForm
          control={control}
          errors={errors}
          allowedEdit={allowed([AllRoles.OUTLET.OUTLETGROUP], 'write')}
        />
      </Row>
    </Form>
  )
}