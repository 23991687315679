import React from 'react';
import Icon from '@ant-design/icons';

const LineChartSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="30" viewBox="0 0 52 30">
    <path
      id="Path_28657"
      data-name="Path 28657"
      d="M-499.426,229.653a4.642,4.642,0,0,1-4.669,4.615,4.667,4.667,0,0,1-1.183-.168l-8.166,9.733a4.528,4.528,0,0,1,.464,1.973,4.644,4.644,0,0,1-4.67,4.616,4.643,4.643,0,0,1-4.669-4.616,4.518,4.518,0,0,1,.219-1.326l-9.935-8.762a4.689,4.689,0,0,1-1.854.382,4.648,4.648,0,0,1-.723-.072l-8.163,12.013a4.53,4.53,0,0,1,.688,2.381,4.643,4.643,0,0,1-4.669,4.615,4.643,4.643,0,0,1-4.67-4.615,4.643,4.643,0,0,1,4.67-4.616,4.66,4.66,0,0,1,.723.072l8.163-12.013a4.531,4.531,0,0,1-.688-2.381,4.642,4.642,0,0,1,4.669-4.615,4.643,4.643,0,0,1,4.67,4.615,4.52,4.52,0,0,1-.219,1.326l9.935,8.762a4.7,4.7,0,0,1,1.853-.382,4.706,4.706,0,0,1,1.183.168l8.166-9.732a4.527,4.527,0,0,1-.464-1.973,4.643,4.643,0,0,1,4.67-4.616A4.642,4.642,0,0,1-499.426,229.653Z"
      transform="translate(551.426 -225.037)"
      fill="#7c7c7c"
    />
  </svg>
);

const LineChartIcon = (props) => <Icon component={LineChartSVG} {...props} />;
export default LineChartIcon;
