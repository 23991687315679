import React, { Fragment, useEffect, useState } from 'react';
import { Select, Form, Row, Col, Space, Typography, Spin, Button, message, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { getProductGroupOption } from '../../../../ducks/actions'
import { addProductGroup, editProductGroup, removeProductGroup, duplicateProductGroupParts } from '../../../../ducks/services'
import DeletePopup from 'Molecules/DeletePopup';
import { SelectField, InputCheckbox, InputField } from 'Atoms/FormElement';
import PartList from './components/PartList'

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {

  const { id, activeTab, data, allowedEdit, allowedDelete } = props;
  const dispatch = useDispatch();
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const { control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm();
  const option = useSelector((state) => state.product.productGroupOption);
  const [bom, setBom] = useState();
  const [visible, setVisible] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [rec, setRecord] = useState();
  const [visible2, setVisible2] = useState(false);
  const [duplicateVisible, setDuplicateVisible] = useState(false);
  const [load, setLoading] = useState(false);
  const isCombo = data?.is_condiment_required === "1";
  const isParentSemi = data?.is_semi_product === "1";

  useEffect(() => {
    if (activeTab && activeTab === "3") {
      callApi();
    }
  }, [activeTab]);

  useEffect(() => {
    if (option && 'boms' in option) {
      if (bom) {
        let temp = option.boms.find(x => x.id === bom.id);
        if(temp) {
          setBom(temp);
        } else {
          onChange({value: option?.boms[0]?.id});
          setValue('bom', { label: option?.boms[0]?.order_type_group_desc, value: option?.boms[0]?.id });  
        }
      } else {
        onChange({value: option?.boms[0]?.id});
        setValue('bom', { label: option?.boms[0]?.order_type_group_desc, value: option?.boms[0]?.id });
      }
    }
  }, [option]);

  const callApi = () => {
    dispatch(getProductGroupOption(id, props.setLoading));
  }

  const onChange = (e) => {
    setBom(option.boms.find(x => x.id === e.value));
  };

  const onFinish = async (val) => {
    setLoading(true);
    const payload = {
      product_id: id,
      bom_id: bom?.id,
      part_group_id: rec?.id,
      position: val?.position,
      name: val?.name,
      is_optional: val?.optional ? "1" : "0",
      is_base_item: val?.is_base_item ? "1" : "0",
      max_allowed_qty: val?.max_qty,
      min_required_qty: val?.min_qty,
      selection_mode: val?.selection_mode?.value
    }

    rec?.id ?
      await editProductGroup(payload).then(res => {
        setLoading(false);
        if (res.data.code === 200) {
          message.success("Product Group updated successfully.");
          setVisible(false);
          setRecord();
          setValue('position', '');
          setValue('name', '');
          setValue('optional', false);
          setValue('is_base_item', false);
          setValue('max_qty', '');
          setValue('selection_mode', '');
          setValue('min_qty', '');
          setTimeout(() => callApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoading(false);
        message.error(e.message ?? "Something went wrong");
      }) :
      await addProductGroup(payload).then(res => {
        setLoading(false);
        if (res.data.code === 200) {
          message.success("Product Group added successfully.");
          setVisible(false);
          setRecord();
          setValue('position', '');
          setValue('name', '');
          setValue('optional', false);
          setValue('is_base_item', false);
          setValue('max_qty', '');
          setValue('selection_mode', '');
          setValue('min_qty', '');
          setTimeout(() => callApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoading(false);
        message.error(e.message ?? "Something went wrong");
      });
  };

  const onRemove = async () => {
    setLoading(true);

    const payload = {
      product_id: id,
      bom_id: bom?.id,
      part_group_id: rec,
      status_event: 'remove'
    }

    await removeProductGroup(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success("Product Group has been removed successfully!");
        setRemoveVisible(false);
        setRecord();
        setTimeout(() => callApi(), 500);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onFinishDuplicate = async (val) => {
    setLoading(true);
    const payload = {
      selected_bom_id: val?.order_type_group?.value,
      product_id: id,
      bom_id: bom?.id
    }

    await duplicateProductGroupParts(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success("Groups has been duplicated successfully.");
        setDuplicateVisible(false);
        setValue2('order_type_group', null);
        setTimeout(() => callApi(), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={20} className='w-100'>
          <Title level={4} className='m-0'>
            {rec ? 'Edit' : 'Add'} Product Group
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <InputField
                isRequired={visible}
                fieldname='position'
                label='Position'
                class='mb-0 w-100'
                initValue={rec?.position}
                control={control}
                iProps={{ placeholder: 'Please enter' }}
                rules={{
                  required: visible ? 'Position is required' : false,
                }}
                validate={errors.position && 'error'}
                validMessage={errors.position && errors.position.message}
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={visible}
                fieldname='name'
                label='Name'
                class='mb-0 w-100'
                initValue={rec?.name}
                control={control}
                iProps={{ placeholder: 'Please enter' }}
                rules={{
                  required: visible ? 'Name is required' : false,
                }}
                validate={errors.name && 'error'}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
            {
              isCombo &&
              <>
                <Col span={12}>
                  <InputCheckbox
                    fieldname={`optional`}
                    label="Optional"
                    class="mb-0 justify-left"
                    control={control}
                    initValue={false}
                    onChange={(e) => setVisible2(e)}
                  />
                </Col>
                <Col span={12}>
                  <InputCheckbox
                    fieldname={`is_base_item`}
                    label="Is Base Item?"
                    class="mb-0 justify-left"
                    control={control}
                    initValue={false}
                  />
                </Col>
                {
                  visible2 &&
                  <Col span={12}>
                    <InputField
                      fieldname={`max_qty`}
                      label="Maximum Allowed Quantity"
                      class="mb-0"
                      control={control}
                      initValue={''}
                      iProps={{ placeholder: 'Please enter' }}
                    />
                  </Col>
                }
              </>
            }
          </Row>
          {
            isCombo &&
            <Row gutter={10}>
              <Col span={12}>
                <SelectField
                  fieldname='selection_mode'
                  label='Selection Mode'
                  class='mb-0 w-100 default-select'
                  initValue={''}
                  control={control}
                  iProps={{ placeholder: 'Please select' }}
                  selectOption={option?.selection_modes?.map(x => ({ label: x, value: x }))}
                />
              </Col>
              <Col span={12}>
                <InputField
                  fieldname={`min_qty`}
                  label="Minimum Required Qty."
                  class="mb-0"
                  control={control}
                  initValue={''}
                  iProps={{ placeholder: 'Please enter' }}
                />
              </Col>
            </Row>
          }
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('position', '');
              setValue('name', '');
              setValue('optional', false);
              setValue('is_base_item', false);
              setValue('max_qty', '');
              setValue('selection_mode', '');
              setValue('min_qty', '');
              rec && setRecord();
            }}>Cancel</Button></Col>
            <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('order_type', '');
      setRecord();
    },
  };

  const popup2 = {
    closable: false,
    visibility: removeVisible,
    class: 'black-modal',
    content: <DeletePopup title={"Product Group"} x loading={load} onClose={() => setRemoveVisible(false)} onDelete={onRemove} />,
    width: 536,
    onCancel: () => setRemoveVisible(false)
  };

  const popup3 = {
    closable: false,
    visibility: duplicateVisible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit2(onFinishDuplicate)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Duplicate Groups
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <SelectField
                isRequired={duplicateVisible}
                fieldname='order_type_group'
                label='Order Type Group'
                class='mb-0 w-100 default-select'
                initValue={''}
                control={control2}
                iProps={{ placeholder: 'Please select', isDisabled: !allowedEdit }}
                selectOption={option?.boms?.filter(x => x.id !== bom?.id)?.map(x => ({ label: x.order_type_group_desc, value: x.id }))}
                rules={{ required: duplicateVisible ? 'Required' : false }}
                validate={errors2.order_type_group && 'error'}
                validMessage={errors2.order_type_group && errors2.order_type_group.message}
              />
            </Col>
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setDuplicateVisible(false);
              setValue('order_type_group', '');
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 1000,
    onCancel: () => {
      setDuplicateVisible(false);
      setValue('order_type_group', '');
    },
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <SelectField
            fieldname='bom'
            label='Order Type Group'
            class='mb-0 w-100'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Please select' }}
            selectOption={option?.boms?.map(x => ({ label: x.order_type_group_desc, value: x.id }))}
            onChange={onChange}
          />
          {/* <label>Order Type</label>
          <Select getPopupContainer={trigger => trigger.parentNode} size="large" className="w-100 default-select" onChange={onChange}>
            {option?.boms?.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.order_type_code}
              </Select.Option>
            ))}
          </Select> */}
        </Col>
        {
          allowedEdit &&
          <>
            <Col span={12} className='text-right'>
              <Space size={10}>
                <Button type='primary' htmlType='button' className='b-success attendance-success' onClick={() => {
                  setDuplicateVisible(true);
                  setValue('order_type_group', '');
                  setVisible(false);
                  setVisible2(false);
                }}>
                  Duplicate Groups
                </Button>
                <Button type='primary' htmlType='button' className='attendance-success' onClick={() => {
                  setVisible(true);
                  setValue('position', '');
                  setValue('name', '');
                  setValue('optional', false);
                  setValue('is_base_item', false);
                  setValue('max_qty', '');
                  setValue('selection_mode', '');
                  setValue('min_qty', '');
                  setVisible2(false);
                  setRecord();
                }}>
                  + Add New Group
                </Button>
              </Space>
            </Col>
          </>
        }

        {
          bom?.part_groups && bom?.part_groups?.length > 0 ?
          bom?.part_groups?.map((item, index) => (
            <Fragment key={index}>
              <PartList
                allowedEdit={allowedEdit}
                allowedDelete={allowedDelete}
                loading={props.loading}
                setLoading={props.setLoading}
                bomId={bom?.id}
                partGroups={bom.part_groups}
                item={item}
                reloadApi={callApi}
                index={index}
                meta={option}
                id={id}
                onParentEdit={(pg) => { 
                  setVisible(true);
                  setRecord(pg);
                  setValue('position', pg?.position);
                  setValue('name', pg?.name); 
                  setVisible2(pg?.is_optional === "1");
                  setValue('optional', pg?.is_optional === "1");
                  setValue('is_base_item', pg?.is_base_item === "1");
                  setValue('max_qty', pg?.max_allowed_qty);
                  setValue('selection_mode', { label: pg?.selection_mode, value: pg?.selection_mode });
                  setValue('min_qty', pg?.min_required_qty);
                }}
                onParentRemove={(pgId) => { setRemoveVisible(true); setRecord(pgId) }}
                isCombo={isCombo}
                isParentSemi={isParentSemi}
              />
            </Fragment>
          ))
          :
          <Col span={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        }
      </Row>
      <Popup {...popup} />
      <Popup {...popup2} />
      <Popup {...popup3} />
    </>
  );
}