import React, { useEffect, useState } from 'react';
import { Row, Col, message, Button, notification, Typography, Table, Spin, Tooltip } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { Popup } from 'Atoms/Popup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from './ducks/actions'
import { removeProduct, bulkRemoveProducts, exportProduct, importProduct, getLogs } from './ducks/services'
import Search from './components/Search'
import ActionButton from 'Molecules/ActionButton';
import { CloseCircleFilled, CheckCircleFilled, ReloadOutlined, LoadingOutlined } from '@ant-design/icons';
import AllRoles from '../../../routing/config/AllRoles';
import { allowed } from '../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const meta = useSelector((state) => state.product.option);
  const [statuses, setStatuses] = useState([]);
  const [importVisible, setImportVisible] = useState(false);
  const [deets, setDeets] = useState([]);
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: {
        multiple: 6
      }
    },
    {
      title: 'Long Description',
      dataIndex: 'name',
      key: 'name',
      className: 'enable-click',
      sorter: {
        multiple: 4
      }
    },
    {
      title: 'Base Price',
      dataIndex: 'price',
      key: 'price_cents',
      className: 'enable-click',
      sorter: {
        multiple: 5
      }
    },
    {
      title: 'Is Combo?',
      dataIndex: 'is_combo',
      key: 'is_combo',
      className: 'enable-click',
      sorter: {
        multiple: 1
      },
      align: 'center',
      render: (text) => <span>{text === "Yes" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    {
      title: 'Is Condiment?',
      dataIndex: 'is_condiment_required',
      key: 'is_condiment_required',
      className: 'enable-click',
      sorter: {
        multiple: 2
      },
      align: 'center',
      render: (text) => <span>{text === "Yes" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    {
      title: 'Is Semi Product?',
      dataIndex: 'is_semi_product',
      key: 'is_semi_product',
      className: 'enable-click',
      sorter: {
        multiple: 3
      },
      align: 'center',
      render: (text) => <span>{text === "Yes" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    // {
    //   title: 'Hide Image?',
    //   dataIndex: 'hide_image',
    //   key: 'hide_image',
    //   className: 'enable-click',
    //   sorter: true,
    //   align: 'center',
    //   render: (text) => <span>{text === "Yes" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {text}
          </span>
          {" "}
          {
            allowed([AllRoles.PRODUCT.PRODUCT], 'write') &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? "(Deactivate)" : "(Activate)"}
            </Button>
          }
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="Product"
          btnAction1={() => navigate(`/product/${record.id}`)}
          recordId={record.id}
          onRemove={allowed([AllRoles.PRODUCT.PRODUCT], 'delete') && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const addNew = () => navigate('/product/add');

  const btnList = [
    {
      title: 'Are you sure to remove the selected product(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: 'Import Logs',
      classes: '',
      action: () => onLoadLogs(),
    },
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => onExport(),
    },
    // {
    //   text: 'Download Sample',
    //   classes: 'green-btn',
    //   action: () => handleDownload(),
    // },
    {
      text: 'Import Products',
      classes: 'attendance-success',
      isUploadCsv: true,
      action: (e) => handleUpload(e)
    },
    {
      text: '+ New Product',
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if (meta) {
      if ("statuses" in meta) {
        let temp = [];
        meta.statuses?.map((x, ind) => {
          if (ind === 0) {
            temp.push({
              label: "All Status",
              value: ""
            });
          }
          temp.push({
            label: x,
            value: x
          })
        });
        setStatuses(temp);
      }
    }
  }, [meta]);

  useEffect(() => {
    reloadApi();
  }, []);

  const reloadApi = () => {
    if (!globalListQuery.productListQuery) {
      dispatch(getProducts(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('productListQuery', 1, limit, '', '', null))
    } else {
      // console.log(listQuery)
      const { page, limit, sortOrder, sortColKey, searchValue } = globalListQuery.productListQuery
      dispatch(getProducts(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }

  const onTableChange = (pagination, filters, sorter) => {
    console.log(sorter);
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if(Array.isArray(sorter)){
      dispatch(getProducts(pagination.current, pagination.pageSize, "", "", searchVal, props.setLoading, sorter));
      dispatch(getListQueryItems('productListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal))
    } else if (sorter.order) {
      dispatch(getProducts(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('productListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal))
    } else {
      dispatch(getProducts(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('productListQuery', pagination.current, pagination.pageSize, '', '', searchVal))
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAction = async (recId, status) => {
    props.setLoading(true);

    const payload = {
      product_id: recId,
      status_event: status === "Active" ? "deactivate" : "activate"
    }

    await removeProduct(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Status has been updated successfully!");
        setTimeout(() => dispatch(getProducts(page, limit, '', '', searchVal, props.setLoading)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      product_id: id,
      status_event: 'remove'
    }

    await removeProduct(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Product has been removed successfully!");
        setTimeout(() => dispatch(getProducts(page, limit, '', '', searchVal, props.setLoading)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      products: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveProducts(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Products has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getProducts(1, limit, '', '', null, props.setLoading)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        code: search.code,
        status: search.status.value
      };
      setSearchVal(searching);
      dispatch(getProducts(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('productListQuery', 1, limit, '', '', searching))
    } else {
      setSearchVal(null);
      dispatch(getProducts(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('productListQuery', 1, limit, '', '', null))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
        navigate(`/product/${record.id}`);
      },
    };
  };

  const onExport = async () => {
    props.setLoading(true);

    await exportProduct(searchVal, selectedRowKeys).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  const handleUpload = async (e) => {
    props.setLoading(true);
    try {
      let res = await importProduct(e.file.originFileObj);
      if (res === undefined) return;
      if (res?.res === false) {
        notification.error({ message: res?.msg, duration: 0, placement: 'top', style: { fontSize: "xx-large", width: "450px" } });
      } else {
        if (res?.data?.code === 200) {
          // notification.success({ message: "", duration: 0, placement: 'top', style: { fontSize: "xx-large" } });
          message.success("Import in progress, please verify the result shortly.");
        } else {
          notification.error({ message: res?.data?.message, duration: 0, placement: 'top', style: { fontSize: "xx-large", width: "600px" } });
        }
      }
      props.setLoading(false);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      props.setLoading(false);
    }
  };

  const logCols = [
    {
      title: 'Date',
      dataIndex: 'created_date',
      key: 'created_date'
    },
    {
      title: 'Time',
      dataIndex: 'created_time',
      key: 'created_time',
    },
    {
      title: 'Filename',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Imported by',
      dataIndex: 'imported_by',
      key: 'imported_by',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <span className={text === "Pending" ? "c-pending" : text === "Failed" ? "c-danger" : "c-success"}>{text}</span>
    }
  ]

  const popup = {
    closable: true,
    visibility: importVisible,
    content:
      <Spin indicator={antIcon} size="large" spinning={props.loading}>
        <div className="d-flex justify-content-between">
          <Title className='mb-1' level={4}>
            Product Import Logs
          </Title>
          <Tooltip title="Reload">
            <Button className='mr-2' type="link" icon={<ReloadOutlined />} onClick={() => onLoadLogs()} />
          </Tooltip>
        </div>
        <Table
          scroll={500}
          dataSource={deets?.map((x, idx) => ({ ...x, key: idx + 1 }))}
          columns={logCols}
          pagination={false}
        />
      </Spin>
    ,
    width: 1000,
    class: "modal-overflow-scroll",
    onCancel: () => {
      setImportVisible(false);
      reloadApi();
    },
  };

  const onLoadLogs = async () => {
    props.setLoading(true);
    await getLogs().then(res => {
      props.setLoading(false);
      if(res.data.code === 200) {
        setImportVisible(true);
        setDeets(res.data.data.list);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Products"} btnList={allowed([AllRoles.PRODUCT.PRODUCT], 'write') ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.productListQuery?.searchValue}
            onRow={onClickRow}
            // listClass="nopad"
            ListData={products?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            rowSelection={rowSelection}
            field1={statuses}
            pagination={{
              total: products?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}