import React, { useEffect, Fragment } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { id, data, addVendor, editVendor, reloadApi, mode, meta, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();

  const formFields = [
    {
      name: 'code',
      label: 'Vendor Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'name',
      label: 'Name',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'company_registration_no',
      label: 'Company Registration No.',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'tax_registration_no',
      label: 'Tax Registration No.',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Currency Code',
      name: 'currency',
      twocol: false,
      options: meta?.currencies?.map((x) => ({ label: x.code, value: x.id })),
      req: true,
      placeholder: 'Please select',
      reqmessage: 'Currency Code Required',
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Payment Term Code',
      name: 'payment_term',
      twocol: false,
      options: meta?.payment_terms?.map((x) => ({ label: x.code, value: x.id })),
      req: false,
      placeholder: 'Please select',
      reqmessage: 'Payment Term Code Required',
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      name: 'cancellation_grace_period',
      label: 'Cancellation Grace Period (In Days)',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50.5%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'address1',
      label: 'Address 1',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'address2',
      label: 'Address 2',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'city',
      label: 'City',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'postcode',
      label: 'Postal Code',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'state',
      label: 'State',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: 'Country',
      name: 'country',
      twocol: false,
      options: meta?.countries?.map((x) => ({ label: x, value: x })),
      req: true,
      placeholder: 'Please select',
      reqmessage: 'Country Required',
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      name: 'order_attn',
      label: 'Order Attention Name',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'email',
      label: 'Email',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      email: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      beautifyTips: {__html: 'use <span class="font-italic">(comma (,) separated)</span> for input multiple email address'}
    },
    {
      name: 'finance_email',
      label: 'Finance Email',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      email: true,
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEdit,
    },
    {
      name: 'other_email',
      label: 'Other Email',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      email: true,
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEdit,
    },
    {
      name: 'phoneno',
      label: 'Phone No.',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'fax',
      label: 'Fax No.',
      req: false,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'remarks',
      label: 'Remarks',
      placeholder: 'Please state',
      type: 'textarea',
      twocol: false,
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('code', data.code);
      setValue('name', data.name);
      setValue('company_registration_no', data.company_registration_no);
      setValue('tax_registration_no', data.tax_registration_no);
      setValue('email', data.email);
      setValue('finance_email', data.finance_email);
      setValue('other_email', data.other_email);
      setValue('currency', data.currency_id ? { label: data.currency_code, value: data.currency_id } : '');
      setValue('payment_term', data.payment_term_id ? { label: data.payment_term_code, value: data.payment_term_id } : '');
      setValue('country', data.country ? { label: data.country, value: data.country } : '');
      setValue('address1', data.address1);
      setValue('address2', data.address2);
      setValue('city', data.city);
      setValue('postcode', data.postcode);
      setValue('state', data.state);
      setValue('order_attn', data.order_attn);
      setValue('phoneno', data.phoneno);
      setValue('fax', data.fax);
      setValue('remarks', data.remarks);
      setValue('cancellation_grace_period', data.cancellation_grace_period);
    }
  }, [data]);

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      currency_id: val?.currency?.value,
      country: val?.country?.value,
      payment_term_id: val?.payment_term?.value
    }

    await addVendor(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Vendor created successfully!");
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onFinish = async (val) => {
    if(mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      currency_id: val?.currency?.value,
      payment_term_id: val?.payment_term?.value,
      country: val?.country?.value,
      vendor_id: id
    }

    await editVendor(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Vendor updated successfully!");
        setTimeout(() => reloadApi(), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)} scrollToFirstError>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>Save</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}