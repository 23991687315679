import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { getGeneratorOption } from "../ducks/actions";
import { generateVouchers } from "../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from 'Molecules/FormGroup';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm();
  const meta = useSelector((state) => state.pos.generatorOption);
  const [startDate, setStartDate] = useState(null);
  const [isUserBorne, setIsUserBorne] = useState(false);
  const [usages, setUsages] = useState([]);
  const allowedEdit = allowed([AllRoles.POS.VOUCHER_GENERATOR], 'write');

  const disabledDates = (current) => {
    return startDate && current && current < startDate.endOf('day');
  }

  const formFields = [
    {
      name: 'voucher_type',
      label: 'Voucher Types',
      req: true,
      placeholder: 'Please select',
      options: meta?.voucher_types?.map(x => ({ label: `${x.code} (${x.desc})`, value: x.id, discount_amount: x.value, value_type: x.value_type, code: x.code })),
      type: 'select',
      class: 'default-select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      onChange: (e) => {
        setValue('code', e.code);
        setValue('discount_amount', e.discount_amount);
        setValue('value_type', e.value_type ? { label: e.value_type, value: e.value_type } : '');
      },
      static: !allowedEdit
    },
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Code Required',
      static: !allowedEdit
    },
    {
      name: 'from',
      label: 'Effective Date',
      req: true,
      placeholder: 'Please select',
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      onChange: (date) => {
        setValue('to', '');
        setStartDate(date);
      }
    },
    {
      name: 'to',
      label: 'Expiry Date',
      req: false,
      placeholder: 'Please select',
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      disabledDate: disabledDates,
      static: !allowedEdit
    },
    {
      name: 'discount_amount',
      label: 'Voucher Value',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      number: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'value_type',
      label: 'Value Type',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      options: meta?.value_types?.map(x => ({ label: x, value: x })),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'min_spend_amount',
      label: 'Minimum Spend Amount',
      req: false,
      number: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'quantity',
      label: 'Quantity',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      number: true,
      min: 0,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit,
      tips: "Enter 0 if it's for voucher import"
    },
    {
      type: 'switch',
      name: 'is_user_borne',
      label: 'User-Borne Voucher',
      req: false,
      placeholder: 'Please select',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit,
      onChange: (e) => {
        setIsUserBorne(e);
      }
    },
    {
      name: 'user_type',
      label: 'User Type',
      req: isUserBorne,
      hidden: !isUserBorne,
      placeholder: 'Please select',
      type: 'select',
      options: meta?.user_types,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      onChange: (e) => {
        if(e.value == "new_user") {
          setUsages(meta?.no_of_usages.filter(x => x.value !== "unlimited"))
        }else{
          setUsages(meta?.no_of_usages)
        }
      },
      static: !allowedEdit
    },
    {
      name: 'no_of_usage',
      label: 'No. Of Usage',
      req: isUserBorne,
      hidden: !isUserBorne,
      placeholder: 'Please select',
      type: 'select',
      options: usages,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    dispatch(getGeneratorOption(props.setLoading));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if(meta) {
      if("no_of_usages" in meta) {
        setUsages(meta?.no_of_usages);
      }
    }
  }, [meta])

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      voucher_type_id: val?.voucher_type?.value,
      quantity: val?.quantity,
      from: val?.from ? dayjs(val?.from).format("YYYY-MM-DD") : '',
      to: val?.to ? dayjs(val?.to).format("YYYY-MM-DD") : '',
      discount_type: val?.value_type?.value,
      discount_amount: val?.discount_amount,
      is_user_borne: val?.is_user_borne ? 1 : 0,
      no_of_uses: val?.no_of_usage?.value,
      user_type: val?.user_type?.value,
      min_spend_amount: val?.min_spend_amount,
      code: val?.code
    }

    await generateVouchers(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Voucher(s) has been generated successfully!");
        setTimeout(() => navigate(`/pos/vouchers/${res.data.data}`), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>Voucher Generator</Title>
            </Col>
            {
              allowedEdit &&
              <Col flex='auto' className='text-right'>
                <Space size={10}>
                  <Button size="large" htmlType='submit' className='green-btn'>Generate</Button>
                </Space>
              </Col>
            }
          </Row>
        </Col>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
      </Row>
    </Form>
  )
}