import AllRoles from './AllRoles';

const titles = {
  Reporting: 'Reporting',
};

export const Reporting = [
  {
    component: 'SalesOrder',
    path: '/reporting/sales-order',
    title: titles.Reporting,
    key: 'sales-order',
    menu: 'Sales Reporting',
    submenu: 'Sales',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.SALES],
  },
  {
    component: 'DailySummary',
    path: '/reporting/daily-summary',
    title: titles.Reporting,
    key: 'daily-summary',
    menu: 'Sales Reporting',
    submenu: 'Daily Summary',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.DAILY_SUMMARY],
  },
  {
    component: 'SalesPayment',
    path: '/reporting/sales-payment',
    title: titles.Reporting,
    key: 'sales-payment',
    menu: 'Sales Reporting',
    submenu: 'Sales Payment',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.SALES_PAYMENT],
  },
  {
    component: 'SalesDetails',
    path: '/reporting/sales-detail',
    title: titles.Reporting,
    key: 'sales-detail',
    menu: 'Sales Reporting',
    submenu: 'Sales Details',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.SALES_DETAILS],
  },
  {
    component: 'SalesSummary',
    path: '/reporting/sales-summary',
    title: titles.Reporting,
    key: 'sales-summary',
    menu: 'Sales Reporting',
    submenu: 'Sales Summary',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.SALES_SUMMARY],
  },
  {
    component: 'SalesHourly',
    path: '/reporting/sales-hourly',
    title: titles.Reporting,
    key: 'sales-hourly',
    menu: 'Sales Reporting',
    submenu: 'Sales Hourly',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.SALES_HOURLY],
  },
  {
    component: 'VoucherListing',
    path: '/reporting/voucher-listing',
    title: titles.Reporting,
    key: 'voucher-listing',
    menu: 'Sales Reporting',
    submenu: 'Voucher Listing',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.VOUCHER_LISTING],
  },
  {
    component: 'BillSummary',
    path: '/reporting/bill-summary',
    title: titles.Reporting,
    key: 'bill-summary',
    menu: 'Sales Reporting',
    submenu: 'Bill Summary',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.BILL_SUMMARY],
  },
  {
    component: 'BillSummaryListing',
    path: '/reporting/bill-summary-listing',
    title: titles.Reporting,
    key: 'bill-summary-listing',
    menu: 'Sales Reporting',
    submenu: 'Bill Summary Lst.',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.BILL_SUMMARY_LISTING],
  },
  {
    component: 'DiscountListing',
    path: '/reporting/sales-discount-listing',
    title: titles.Reporting,
    key: 'sales-discount-listing',
    menu: 'Sales Reporting',
    submenu: 'Sales Discount Lst.',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.SALES_DISCOUNT_LISITNG],
  },
  {
    component: 'CashManagementListing',
    path: '/reporting/cash-management-listing',
    title: titles.Reporting,
    key: 'cash-management-listing',
    menu: 'Sales Reporting',
    submenu: 'Cash Mgmt Lst.',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.CASH_MANAGEMENT_LISTING],
  },
  // {
  //   component: 'ProductSales',
  //   path: '/reporting/product-sales',
  //   title: titles.Reporting,
  //   key: 'product-sales',
  //   menu: 'Sales Reporting',
  //   submenu: 'Product Sales',
  //   icon: 'OverviewIcon',
  //   parent: true,
  //   parenticon: 'SetupIcon',
  //   permission: [AllRoles.REPORTING.PRODUCT_SALES_LISTING],
  // },
  {
    component: 'SpeedOfService',
    path: '/reporting/speed-of-service',
    title: titles.Reporting,
    key: 'speed-of-service',
    menu: 'Sales Reporting',
    submenu: 'Speed Of Service',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.SPEED_OF_SERVICE_LISTING],
  },
  {
    component: 'CancelledSales',
    path: '/reporting/refunded-sales',
    title: titles.Reporting,
    key: 'refunded-sales',
    menu: 'Sales Reporting',
    submenu: 'Refunded Sales',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.CANCELLED_SALES_LISTING],
  },
  {
    component: 'TaxChargesListing',
    path: '/reporting/tax-charges-listing',
    title: titles.Reporting,
    key: 'tax-charges-listing',
    menu: 'Sales Reporting',
    submenu: 'Tax Charges Listing',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.TAX_CHARGES_LISTING],
  },
  {
    component: 'TaxChargesDetails',
    path: '/reporting/tax-charges-details',
    title: titles.Reporting,
    key: 'tax-charges-details',
    menu: 'Sales Reporting',
    submenu: 'Tax Charges Details',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.TAX_CHARGES_DETAILS],
  },
  {
    component: 'PosAuditLog',
    path: '/reporting/pos-audit-log',
    title: titles.Reporting,
    key: 'pos-audit-log',
    menu: 'Sales Reporting',
    submenu: 'POS Audit Log',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.REPORTING.POS_AUDIT_LOG],
  }
]