import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';

const _ = require('lodash');

export default (props) => {

  const { errors, control, meta, allowedEdit } = props;

  const formFields = [
    {
      name: 'code',
      label: 'Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'desc',
      label: 'Description',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'reason_type',
      label: 'Reason Type',
      req: true,
      placeholder: 'Please select',
      type: 'select',
      options: meta?.reason_types?.map((x) => ({ label: x, value: x })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}