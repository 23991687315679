import React from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField, DateField, SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import DateRangeDropdown from 'Modules/Application/components/DateRangeDropdown';

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit, setValue } = useForm()

  const searchStatusLabel = (searchVal?.status === "" || searchVal?.status) ? true : false
  const searchOutletLabel = (searchVal?.outlet === "" || searchVal?.outlet) ? true : false
  const searchOrderTypeLabel = (searchVal?.order_type === "" || searchVal?.order_type) ? true : false
  const searchTenderTypeLabel = (searchVal?.tender_type === "" || searchVal?.tender_type) ? true : false
  
  const onSubmit = (val) => {
    props.onSearch(val);
  }

  const clearProps = () => {
    setValue('reference', '');
    setValue('start_date', '');
    setValue('end_date', '');
    setValue('outlet', '');
    props.onSearch({});
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <InputField
            fieldname='reference'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.reference ? searchVal?.reference : ''}
            control={control}
            iProps={{ placeholder: 'Search by Bill No..' }}
          />
        </Col>

        <DateRangeDropdown control={control} onSetValue={setValue} placeholderValue='Business Date' isRequired={false} />

        <Col flex="auto">
          <SelectField
            fieldname='outlet'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchOutletLabel ? {label: searchVal?.outlet === "" ? 'All Outlets' : searchVal?.outlet, value: searchVal?.outlet} : ''}
            control={control}
            iProps={{ placeholder: 'Outlet', isMulti: true }}
            selectOption={props.field1}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='order_type'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchOrderTypeLabel ? {label: searchVal?.order_type === "" ? 'All Order Types' : searchVal?.order_type, value: searchVal?.order_type} : ''}
            control={control}
            iProps={{ placeholder: 'Order Type' }}
            selectOption={props.field2}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='tender_type'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchTenderTypeLabel ? {label: searchVal?.tender_type === "" ? 'All Tender Types' : searchVal?.tender_type, value: searchVal?.tender_type} : ''}
            control={control}
            iProps={{ placeholder: 'Tender Type' }}
            selectOption={props.field4}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='non_sales'
            label=''
            class='mb-0 w-100 default-select'
            initValue={{ label: "Non Sales: All", value: "" }}
            control={control}
            selectOption={[{
              label: "Non Sales: All",
              value: ""
            }, {
              label: "Non Sales: No",
              value: "0"
            }, {
              label: "Non Sales: Yes",
              value: "1"
            }]}
            iProps={{ placeholder: 'Is Non Sales' }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='status'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchStatusLabel ? {label: searchVal?.status === "" ? 'All Status' : searchVal?.status, value: searchVal?.status} : ''}
            control={control}
            iProps={{ placeholder: 'Status' }}
            selectOption={props.field3}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' danger size='large' type='primary' htmlType='button' onClick={() => clearProps()}>Clear</Button>
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
    </Form>
  )
}