import React from "react";
import Icon from '@ant-design/icons';

const SetupSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
        <path d="M62.32,231.944,51.313,242.95a2.111,2.111,0,0,1-1.5.622h0a2.125,2.125,0,0,1-1.5-3.627l11.008-11.007a.9.9,0,0,0,.254-.747,4.071,4.071,0,0,1,4.637-4.563h.009a.794.794,0,0,1,.435,1.344L63.6,226.031a.684.684,0,0,0-.205.49.693.693,0,0,0,.2.5l.649.648a.684.684,0,0,0,.49.205.693.693,0,0,0,.494-.205l1.055-1.055a.794.794,0,0,1,1.346.448,4.072,4.072,0,0,1-4.562,4.634A.894.894,0,0,0,62.32,231.944Zm-13.277-3.466a.843.843,0,0,0,.35.35l2.49,1.29,1.36,1.34,2.36-2.36-1.36-1.34-1.28-2.48a.868.868,0,0,0-.36-.35l-2.45-1.26a.806.806,0,0,0-.96.15l-1.27,1.26a.835.835,0,0,0-.15.96Zm17.81,10.26-3.86-3.84-4,4,3.85,3.84a2.832,2.832,0,1,0,4.01-4Z" transform="translate(-47.684 -223.572)" />
    </svg>
  );

  const SetupIcon = props => <Icon component={SetupSvg} {...props} />;

  export default SetupIcon;