import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Typography, message, Spin, Radio, Card, Pagination, Button, Form, Space, Empty, Upload } from 'antd';
import { LoadingOutlined, LeftOutlined } from '@ant-design/icons';
import { getUserProfile }  from './ducks/actions';
import { editProfile }  from './ducks/services';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import FormGroup from 'Molecules/FormGroup';
import EntityImg from 'Assets/img/entity-placeholder.png';

import { dummyRequest, getFileName, uploadFileV2 } from 'Features/utility';
import { getListQueryItems } from 'Modules/Application/ducks/actions';



const antIcon = <LoadingOutlined spin />;
const { Title, Text } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState();
  const [id, setId] = useState(null);

  const profile = useSelector((state) => state.profile.userProfile);

  const formFields = [
    {
      name: 'email',
      label: 'Email',
      req: true,
      placeholder: 'Please enter',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Email is Required',
      static: true
    },
    {
      name: 'username',
      label: 'Username',
      req: true,
      placeholder: 'Please enter',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Username is Required',
      static: true
    },
    {
      name: 'name',
      label: 'Name',
      req: true,
      placeholder: 'Please enter',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Name is Required'
    }
  ];

  useEffect(() => {
    dispatch(getUserProfile(setLoading))
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if(profile && 'id' in profile) {
      setId(profile.id)
      setValue('email', profile?.email)
      setValue('username', profile?.username)
      setValue('name', profile?.name)
      if (profile?.photo_url) {
        setFileList([
          {
            uid: '-1',
            name: getFileName(profile?.photo_url),
            status: 'done',
            url: profile?.photo_url
          }
        ])
      } else {
        setFileList();
        setFileId();
      }
    }
  }, [profile])

  const handleUpload = async (e) => {
    setLoading(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      setLoading(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      setLoading(false);
    }
  };

  const onFinish = async (val) => {
    setLoading(true);
    const payload = {
      ...val,
      blob_id: fileId,
      id: id
    }

    await editProfile(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success("Profile Updated successfully!");
        dispatch(getUserProfile(setLoading))
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Card
      className='brand-card'
      bordered={false}
      style={{maxWidth: '600px', margin: "auto", padding: "20px 50px"}}
    >
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[20, 30]}>
          <Col span={24} className="text-center">
          <Upload
              // disabled={!allowedEdit}
              listType="picture-card"
              className="avatar-uploader larger h-100"
              showUploadList={false}
              accept="image/*"
              maxCount={1}
              fileList={fileList}
              customRequest={dummyRequest}
              onChange={(e) => handleUpload(e)}
            >
              <Space size={4} className='h-100'>
                {fileList?.length > 0 ? (
                  <div className='center-image'>
                    <img
                      src={fileList[0].url}
                      alt="avatar"
                      style={{
                        width: '100%',
                        height: 'auto'
                      }}
                    />
                  </div>
                ) :
                  <div>
                    {/* <img src={placeholderImg} alt="" className='w-100' /> */}
                  </div>
                }
              </Space>
            </Upload>
          </Col>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
          {/* {
            allowedEdit && */}
            <Col span={24} className='text-right'>
              <Button size="large" htmlType='submit' className='green-btn attendance-success'>Update</Button>
            </Col>
          {/* } */}
        </Row>
      </Form>
    </Card>
  );
};
