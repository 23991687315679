import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';

const _ = require('lodash');

export default (props) => {

  const { errors, control, allowedEdit } = props

  const formFields = [
    {
      name: 'code',
      label: 'Currency Code',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'symbol',
      label: 'Currency Symbol',
      req: true,
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'reference',
      label: 'External ID',
      placeholder: 'System Generate',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}