import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Empty, message } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesPayments } from '../ducks/actions'
import { exportSalesPayment } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.salesPayments);
  const meta = useSelector((state) => state.reporting.salesPaymentsOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);

  const colName = [
    {
      title: 'Outlet',
      dataIndex: 'location_code',
      key: 'location_code',
    },
    {
      title: 'Tender',
      dataIndex: 'payment_method',
      key: 'payment_method',
    },
    {
      title: 'Business Date',
      dataIndex: 'report_date',
      key: 'report_date',
    },
    {
      title: 'Currency',
      dataIndex: 'currency_code',
      key: 'currency_code',
    },
    {
      title: 'Trans. Count',
      dataIndex: 'transaction_count',
      key: 'transaction_count',
      align: 'right'
    },
    {
      title: 'Tender Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      comma: true
      // render: (text) => <span>{comma(text)}</span>
    }
  ];

  const btnList = [
    {
      text: 'Export Report',
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getSalesPayments(searchVal, props.setLoading));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      searching = {
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
      };
      if (search?.outlets?.length > 0) {
        let temp2 = [];
        search?.outlets?.map(x => {
          temp2.push(x.value)
        });
        searching["location_ids"] = temp2;
      }
      setSearchVal(searching);
      dispatch(getSalesPayments(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getSalesPayments(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (orders?.total_count === 0) {
      return message.warning("No data to be exported.");
    }
    props.setLoading(true);

    await exportSalesPayment(searchVal).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? 'Something went wrong');
    })
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Sales Payment Report"} btnList={allowed([AllRoles.REPORTING.SALES_PAYMENT], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Search onSearch={onSearch} field1={outlets} />

            <div className="table-responsive" style={{ overflowX: 'auto', marginTop: "30px" }}>
              <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {colName.map((x, ind) => (
                      <th key={ind} className={x.align == "right" ? "text-right" : ""}>{x.title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {
                    orders?.list?.length <= 0 &&
                    <tr>
                      <td colSpan="11">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                  {
                    orders?.list?.length > 0 &&
                    orders?.list?.map((x, ind) => {
                      return (
                        <Fragment key={ind}>
                          {
                            x.list?.map((y, yIndex) => {
                              return (
                                <tr key={yIndex}>
                                  {colName.map((z, zInd) => (
                                    <td key={zInd} align={z.align}>{z.comma ? comma(y[z.dataIndex]) : y[z.dataIndex]}</td>
                                  ))}
                                </tr>
                              )
                            })
                          }
                          <tr className='bg-gray text-white fwbold'>
                            <td align='' colSpan="4">SubTotal</td>
                            {
                              x?.sub_totals && Object.entries(x?.sub_totals)?.map(([key, data], zIndex) => {
                                return (
                                  <td key={zIndex} align='right'>{comma(data)}</td>
                                )
                              })
                            }
                          </tr>
                        </Fragment>
                      )
                    })
                  }
                  <tr className='bg-gray fwbold text-white'>
                    {
                      orders?.list?.length <= 0
                        ? <td colSpan="6">Grand Total</td>
                        : <td align='' colSpan="4">Grand Total</td>
                    }
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <td key={index} align='right'>
                            {comma(data)}
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}