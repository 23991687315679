import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Typography, notification } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getVoucherItems } from '../../../../../ducks/actions'
import { removeVoucherItem, importVoucherItems } from '../../../../../ducks/services'
import { LoadingOutlined } from '@ant-design/icons';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, allowedDelete, data } = props;
  const voucherItems = useSelector((state) => state.pos.voucherItems);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const [loading, setLoading] = useState(false);

  const colName = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      render: (text, record) => <h3 className='mb-0'>{text}</h3>
    },
    {
      title: 'Outlet',
      dataIndex: 'location_code',
      key: 'location_code',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Used By',
      dataIndex: 'used_by_name',
      key: 'used_by_name',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Used At',
      dataIndex: 'used_at',
      key: 'used_at',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={(text === "Active" || text === "Used") ? "c-success" : "c-danger"}>
            {text}
          </span>
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Voucher Item"
          recordId={record.id}
          onRemove={(record?.status === "Active" && allowedDelete) && onRemove}
          setLoading={setLoading}
          loading={loading}
        />
    }
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getVoucherItems(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);
  
  const handleUpload = async (e) => {
    props.setLoading(true);
    try {
      let res = await importVoucherItems(data?.id, e.file.originFileObj);
      if (res === undefined) return;
      if (res?.res === false) {
        notification.error({ message: res?.msg, duration: 0, placement: 'top', style: { fontSize: "xx-large", width: "450px" } });
      } else {
        if (res?.data?.code === 200) {
          message.success("Imported Successfully.");
          dispatch(getVoucherItems(1, limit, '', '', null, props.setLoading, id));
        } else {
          notification.error({ message: res?.data?.message, duration: 0, placement: 'top', style: { fontSize: "xx-large", width: "600px" } });
        }
      }
      props.setLoading(false);
    } catch (error) {
      message.error(`Error during file upload: ${error}`);
      props.setLoading(false);
    }
  };

  const btnList = [
    // {
    //   title: 'Are you sure to remove the selected Supplied Item(s)?',
    //   text: 'Bulk Remove',
    //   classes: 'red-btn text-white attendance-success',
    //   permit: true,
    //   notooltip: true,
    //   action: () => onBulkRemove()
    // },
    {
      text: 'Import Vouchers',
      classes: 'green-btn attendance-success',
      isUploadCsv: true,
      hidden: voucherItems?.total_count > 0 || data?.quantity > 0,
      action: (e) => handleUpload(e)
    },
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getVoucherItems(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getVoucherItems(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }
  
  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        code: search?.code
      };
      setSearchVal(searching);
      dispatch(getVoucherItems(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getVoucherItems(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (prodId) => {
    setLoading(true);

    const payload = {
      voucher_id: id,
      voucher_item_id: prodId,
      status_event: 'remove'
    }

    await removeVoucherItem(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success("Voucher Item has been removed successfully!");
        setTimeout(() => dispatch(getVoucherItems(page, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={btnList} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="">
          <ListCard
            onSearch={onSearch}
            Search={Search}
            ListData={voucherItems?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            // rowSelection={rowSelection}
            pagination={{
              total: voucherItems?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}