import React, { useEffect, Fragment, useState } from 'react';
import { Form, Button, Row, Col, message, Spin, Space, Typography } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { updateFaIntegration } from '../../../../ducks/services';
import { LoadingOutlined } from '@ant-design/icons';
import { Popup } from 'Atoms/Popup';
import { InputPassword } from 'Atoms/FormElement';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { data, id, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const { control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const singapore = false; // check by organization.country

  const formFields = [
    {
      name: 'grab_reference',
      label: 'Grab ID',
      req: false,
      placeholder: 'E.g: 1-D2TDFB3JDP6BRD',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'grab_last_sync_at',
      label: 'Grab Menu Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'foodpanda_reference',
      label: 'Foodpanda ID',
      req: false,
      placeholder: 'E.g: z91d',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'foodpanda_last_sync_at',
      label: 'Foodpanda Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'shopeefood_reference',
      label: 'ShopeeFood ID',
      req: false,
      placeholder: 'E.g: 1234567',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'shopeefood_last_sync_at',
      label: 'ShopeeFood Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'o2o_reference',
      label: 'O2O ID',
      req: false,
      placeholder: 'E.g: 100001',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      static: !allowedEdit
    },
    {
      name: 'o2o_last_sync_at',
      label: 'O2O Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
    },
    {
      name: 'deliveroo_reference',
      label: 'Deliveroo ID',
      req: false,
      placeholder: 'E.g: comp-abc-sg1',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Required',
      hidden: !singapore,
      static: !allowedEdit
    },
    {
      name: 'deliveroo_last_sync_at',
      label: 'Deliveroo Last Synced',
      req: false,
      static: true,
      placeholder: 'Timestamp',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      hidden: !singapore,
      reqmessage: 'Required',
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('grab_reference', data.grab_reference);
      setValue('grab_last_sync_at', data.grab_last_sync_at);
      setValue('foodpanda_reference', data.foodpanda_reference);
      setValue('foodpanda_last_sync_at', data.foodpanda_last_sync_at);
      setValue('shopeefood_reference', data.shopeefood_reference);
      setValue('shopeefood_last_sync_at', data.shopeefood_last_sync_at);
      setValue('o2o_reference', data.o2o_reference);
      setValue('o2o_last_sync_at', data.o2o_last_sync_at);
      setValue('deliveroo_reference', data.deliveroo_reference);
      setValue('deliveroo_last_sync_at', data.deliveroo_last_sync_at);
    }
  }, [data]);

  const onFinish = async (val) => {
    setLoading(true);
    const payload = {
      ...val,
      id: id
    }

    await updateFaIntegration(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success("FA Integration Updated Successfully!");
        setVisible(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message);
    })
  };

  const onConfirm = (val2) => {
    let val = {
      ...getValues(),
      ...val2
    };
    onFinish(val);
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" onFinish={handleSubmit2(onConfirm)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Update FA Integration Details
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <InputPassword
                isRequired={true}
                fieldname="password"
                label="Please enter your password to proceed"
                control={control2}
                initValue=""
                iProps={{ size: 'large' }}
                rules={{required: "Password cannot be blank"}}
                validate={errors2?.password && 'error'}
                validMessage={errors2?.password && errors2?.password.message}
              />
            </Col>
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('password', null);
            }}>Cancel</Button></Col>
            {
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('password', '');
    },
  };

  return (
    <>
      <Form layout="vertical" scrollToFirstError>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
        </Row>
        {
          allowedEdit &&
          <Row gutter={[10, 10]} className="mt-1">
            <Col span={24} className='text-right'>
              <Button size="large" htmlType='button' className='green-btn attendance-success' onClick={() => {
                setVisible(true);
                setValue('password', null);
              }}>Save</Button>
            </Col>
          </Row>
        }
      </Form>
      <Popup {...popup} />
    </>
  )
}