import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const addUser = (payload) => {
  return axios.post(`${externalBaseUrl}/users/create`, payload);
};

export const editUser = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update`, payload);
};

export const editUserOC = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-quota`, payload);
};

export const removeUser = (payload) => {
  return axios.post(`${externalBaseUrl}/users/update-status`, payload);
};

export const bulkRemoveUsers = (payload) => {
  return axios.post(`${externalBaseUrl}/users/bulk-update-status`, payload);
};

export const getSingleRole = (id) => {
  return axios.post(`${externalBaseUrl}/roles/get`, { id: id });
};

export const addUserRole = (payload) => {
  return axios.post(`${externalBaseUrl}/roles/create`, payload);
};

export const updateUserRole = (payload) => {
  return axios.post(`${externalBaseUrl}/roles/update`, payload);
};

export const deleteUserRole = (payload) => {
  return axios.post(`${externalBaseUrl}/roles/update-status`, payload);
};

export const bulkDeleteUserRole = (payload) => {
  return axios.post(`${externalBaseUrl}/roles/bulk-update-status`, payload);
};

export const addPosUser = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-users/create`, payload);
};

export const editPosUser = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-users/update`, payload);
};

export const editPosUserOC = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-users/update-quota`, payload);
};

export const removePosUser = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-users/update-status`, payload);
};

export const bulkRemovePosUsers = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-users/bulk-update-status`, payload);
};

export const getPosSingleRole = (id) => {
  return axios.post(`${externalBaseUrl}/pos-roles/get`, { id: id });
};

export const addPosUserRole = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-roles/create`, payload);
};

export const updatePosUserRole = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-roles/update`, payload);
};

export const deletePosUserRole = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-roles/update-status`, payload);
};

export const bulkDeletePosUserRole = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-roles/bulk-update-status`, payload);
};

export const addCurrency = (payload) => {
  return axios.post(`${externalBaseUrl}/currencies/create`, payload);
};

export const editCurrency = (payload) => {
  return axios.post(`${externalBaseUrl}/currencies/update`, payload);
};

export const removeCurrency = (payload) => {
  return axios.post(`${externalBaseUrl}/currencies/update-status`, payload);
};

export const bulkRemoveCurrencies = (payload) => {
  return axios.post(`${externalBaseUrl}/currencies/bulk-update-status`, payload);
};

export const addPaymentTerm = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-terms/create`, payload);
};

export const editPaymentTerm = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-terms/update`, payload);
};

export const removePaymentTerm = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-terms/update-status`, payload);
};

export const bulkRemovePaymentTerms = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-terms/bulk-update-status`, payload);
};

export const addRegion = (payload) => {
  return axios.post(`${externalBaseUrl}/regions/create`, payload);
};

export const editRegion = (payload) => {
  return axios.post(`${externalBaseUrl}/regions/update`, payload);
};

export const removeRegion = (payload) => {
  return axios.post(`${externalBaseUrl}/regions/update-status`, payload);
};

export const bulkRemoveRegions = (payload) => {
  return axios.post(`${externalBaseUrl}/regions/bulk-update-status`, payload);
};

export const addTaxCondition = (payload) => {
  return axios.post(`${externalBaseUrl}/tax-conditions/create`, payload);
};

export const editTaxCondition = (payload) => {
  return axios.post(`${externalBaseUrl}/tax-conditions/update`, payload);
};

export const removeTaxCondition = (payload) => {
  return axios.post(`${externalBaseUrl}/tax-conditions/update-status`, payload);
};

export const bulkRemoveTaxConditions = (payload) => {
  return axios.post(`${externalBaseUrl}/tax-conditions/bulk-update-status`, payload);
};

export const addTcItem = (payload) => {
  return axios.post(`${externalBaseUrl}/tax-conditions/tax-condition-item-create`, payload);
};

export const editTcItem = (payload) => {
  return axios.post(`${externalBaseUrl}/tax-conditions/tax-condition-item-update`, payload);
};

export const removeTcItem = (payload) => {
  return axios.post(`${externalBaseUrl}/tax-conditions/tax-condition-item-update-status`, payload);
};

export const addReason = (payload) => {
  return axios.post(`${externalBaseUrl}/reasons/create`, payload);
};

export const editReason = (payload) => {
  return axios.post(`${externalBaseUrl}/reasons/update`, payload);
};

export const removeReason = (payload) => {
  return axios.post(`${externalBaseUrl}/reasons/update-status`, payload);
};

export const bulkRemoveReasons = (payload) => {
  return axios.post(`${externalBaseUrl}/reasons/bulk-update-status`, payload);
};

export const addRemark = (payload) => {
  return axios.post(`${externalBaseUrl}/remarks/create`, payload);
};

export const editRemark = (payload) => {
  return axios.post(`${externalBaseUrl}/remarks/update`, payload);
};

export const removeRemark = (payload) => {
  return axios.post(`${externalBaseUrl}/remarks/update-status`, payload);
};

export const bulkRemoveRemarks = (payload) => {
  return axios.post(`${externalBaseUrl}/remarks/bulk-update-status`, payload);
};

export const addRemarkType = (payload) => {
  return axios.post(`${externalBaseUrl}/remark-types/create`, payload);
};

export const editRemarkType = (payload) => {
  return axios.post(`${externalBaseUrl}/remark-types/update`, payload);
};

export const removeRemarkType = (payload) => {
  return axios.post(`${externalBaseUrl}/remark-types/update-status`, payload);
};

export const bulkRemoveRemarkTypes = (payload) => {
  return axios.post(`${externalBaseUrl}/remark-types/bulk-update-status`, payload);
};

export const addRtItem = (payload) => {
  return axios.post(`${externalBaseUrl}/remark-types/remark-type-item-create`, payload);
};

export const editRtItem = (payload) => {
  return axios.post(`${externalBaseUrl}/remark-types/remark-type-item-update`, payload);
};

export const removeRtItem = (payload) => {
  return axios.post(`${externalBaseUrl}/remark-types/remark-type-item-update-status`, payload);
};

export const bulkRemoveRtItems = (payload) => {
  return axios.post(`${externalBaseUrl}/remark-types/remark-type-item-bulk-update-status`, payload);
};

export const removeTax = (payload) => {
  return axios.post(`${externalBaseUrl}/taxes/update-status`, payload);
};

export const addPaymentMethodType = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/create`, payload);
};

export const editPaymentMethodType = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/update`, payload);
};

export const removePaymentMethodType = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/update-status`, payload);
};

export const bulkRemovePaymentMethodTypes = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/bulk-update-status`, payload);
};

export const updatePaymentMethodTypesOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/update-position`, payload);
};

export const addPaymentMethod = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/payment-method-create`, payload);
};

export const editPaymentMethod = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/payment-method-update`, payload);
};

export const removePaymentMethod = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/payment-method-update-status`, payload);
};

export const bulkRemovePaymentMethods = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/payment-method-bulk-update-status`, payload);
};

export const updatePaymentMethodsOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/payment-method-types/payment-method-update-position`, payload);
};

export const editOrganization = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/update`, payload);
};

export const deleteOrganization = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/update-status`, payload);
};

export const editPosUserPassword = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-users/update-login-password`, payload);
};

export const addKitchenQueueAlert = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/kitchen-queue-alert-add`, payload);
};

export const editKitchenQueueAlert = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/kitchen-queue-alert-update`, payload);
};

export const removeKitchenQueueAlert = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/kitchen-queue-alert-update-status`, payload);
};

export const addAd = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/ad-add`, payload);
};

export const editAd = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/ad-update`, payload);
};

export const removeAd = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/ad-update-status`, payload);
};

export const updateAdOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/ad-update-order`, payload);
};

export const addMerchant = (payload) => {
  return axios.post(`${externalBaseUrl}/merchants/create`, payload);
};

export const editMerchant = (payload) => {
  return axios.post(`${externalBaseUrl}/merchants/update`, payload);
};

export const removeMerchant = (payload) => {
  return axios.post(`${externalBaseUrl}/merchants/update-status`, payload);
};

export const bulkRemoveMerchants = (payload) => {
  return axios.post(`${externalBaseUrl}/merchants/bulk-update-status`, payload);
};

export const revokeApiKey = (payload) => {
  return axios.post(`${externalBaseUrl}/merchants/revoke-api-key`, payload);
};

export const addOrderTypeGroup = (payload) => {
  return axios.post(`${externalBaseUrl}/order-type-groups/create`, payload);
};

export const editOrderTypeGroup = (payload) => {
  return axios.post(`${externalBaseUrl}/order-type-groups/update`, payload);
};

export const removeOrderTypeGroup = (payload) => {
  return axios.post(`${externalBaseUrl}/order-type-groups/update-status`, payload);
};

export const bulkRemoveOrderTypeGroups = (payload) => {
  return axios.post(`${externalBaseUrl}/order-type-groups/bulk-update-status`, payload);
};

export const bulkCreateLocation = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-users/bulk-create-location`, payload);
};

export const removeLocation = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-users/update-location-status`, payload);
};

export const bulkRemoveLocation = (payload) => {
  return axios.post(`${externalBaseUrl}/pos-users/bulk-update-location-status`, payload);
};

export const addEntityUser = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/user-create`, payload);
}

export const updateEntityUser = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/user-update`, payload);
}

export const removeEntityUser = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/user-update-status`, payload);
}

export const bulkRemoveEntityUsers = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/user-bulk-update-status`, payload);
}

export const syncNetsuite = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/sync-netsuite`, payload);
}

export const retrySyncNetsuite = (payload) => {
  return axios.post(`${externalBaseUrl}/organizations/retry-sync-netsuite`, payload);
}