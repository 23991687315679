import React, { useEffect, useState } from 'react';
import { Row, Col, Button, message, Space } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStockAdjustmentList } from '../ducks/actions'
import { removeStockAdjustment, bulkRemoveStockAdjustments } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import dayjs from 'dayjs';
import { getListQueryItems } from 'Modules/Application/ducks/actions';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.inventory.stockAdjustments);
  const meta = useSelector((state) => state.inventory.stockAdjustmentsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const allowedEdit = allowed([AllRoles.INVENTORY.STOCK_ADJUSTMENT], 'write');
  const allowedDelete = allowed([AllRoles.INVENTORY.STOCK_ADJUSTMENT], 'delete');
  const [outlets, setOutlets] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const globalListQuery = useSelector((state) => state.global.listQueryItems);

  const colName = [
    {
      title: 'SA No.',
      dataIndex: 'reference',
      key: 'reference',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'SA Date',
      dataIndex: 'date',
      key: 'date',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Outlet Code',
      dataIndex: 'location_code',
      key: 'location_code',
      className: 'enable-click',
      sorter: true,
      render: (text, record) =>
        <span>{text} {record?.location_desc}</span>
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Created By',
      dataIndex: 'created_by_name',
      key: 'created_by_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: 'Posting Date & Time',
      dataIndex: 'posted_at',
      key: 'posted_at',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Posted" ? "c-success" : text === "Pending" ? "c-pending" : "c-danger"}>
            {text}
          </span>
          {" "}
          {
            // allowed([Roles.USERS], 'write') &&
            (text === "Pending" || text === "Posted") &&
            allowedEdit &&
            <Space size={10} >
              {
                (text === "Pending" && record.item_count > 0) &&
                <>
                  {"("}
                    <a className='pointer' onClick={() => onAction(record.id, "post")}>
                      Post
                    </a>
                  {")"}
                </>
              }
              {/* <a className='pointer c-danger' onClick={() => onAction(record.id, "cancel")}>
                Cancel
              </a> */}
            </Space>
          }
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="Purchase Order"
          btnAction1={() => navigate(`/inventory/stock-adjustment/${record.id}`)}
          recordId={record.id}
          onRemove={(record?.status === "Pending" && allowedDelete) && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  const addNew = () => navigate('/inventory/stock-adjustment/add');

  const btnList = [
    {
      title: 'Are you sure to remove the selected Stock Adjustment(s)?',
      text: 'Bulk Remove',
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: '+ New Stock Adjustment',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.stockAdjListQuery) {
      dispatch(getStockAdjustmentList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('stockAdjListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.stockAdjListQuery
      dispatch(getStockAdjustmentList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  useEffect(() => {
    if(meta) {
      if('locations' in meta) {
        let temp = [];
        meta?.locations?.map((item, index) => {
          if(index === 0) {
            temp.push({
              label: "All Outlets",
              value: ""
            })
          }
          temp.push({
            label: `${item.code} ${item.desc}`,
            value: item.id
          })
        });
        setOutlets(temp);
      }

      if ("statuses" in meta) {
        let temp2 = [];
        meta.statuses?.map((x, ind) => {
          if (ind === 0) {
            temp2.push({
              label: "All Status",
              value: ""
            });
          }
          temp2.push({
            label: x,
            value: x
          })
        });
        setStatuses(temp2);
      }
    }
  }, [meta])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.status !== 'Pending'
    }),
  };

  const onAction = async (poId, status) => {
    props.setLoading(true);

    const payload = {
      adjustment_id: poId,
      status_event: status
    }

    await removeStockAdjustment(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Stock Adjustment status has been updated successfully!");
        setTimeout(() => dispatch(getStockAdjustmentList(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('stockAdjListQuery', page, limit, '', '', searchVal, props.setLoading))
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (poId) => {
    props.setLoading(true);

    const payload = {
      adjustment_id: poId,
      status_event: 'remove'
    }

    await removeStockAdjustment(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Stock Adjustment has been removed successfully!");
        setTimeout(() => dispatch(getStockAdjustmentList(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('stockAdjListQuery', page, limit, '', '', searchVal, props.setLoading))
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      adjustments: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveStockAdjustments(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Stock Adjustments has been removed successfully!");
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getStockAdjustmentList(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('stockAdjListQuery', 1, limit, '', '', null, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getStockAdjustmentList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('stockAdjListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getStockAdjustmentList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('stockAdjListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        reference: search?.reference,
        status: search.status.value,
        outlet: search?.outlet?.value,
        date: search.date ? dayjs(search.date).format("YYYY-MM-DD") : "",
      };
      setSearchVal(searching);
      dispatch(getStockAdjustmentList(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('stockAdjListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getStockAdjustmentList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('stockAdjListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
          navigate(`/inventory/stock-adjustment/${record.id}`)
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={"Stock Adjustments"} btnList={allowedEdit && btnList} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.stockAdjListQuery?.searchValue}
            onRow={onClickRow}
            // listClass="nopad"
            ListData={orders?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            onChange={onTableChange}
            pagination={{
              total: orders?.total_count,
              current: page,
              pageSize: limit
            }}
            field1={statuses}
            field2={outlets}
          />
        </Col>
      </Row>
    </>
  )
}