import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

export default (props) => {
  const { data, mode, meta } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [isUserBorne, setIsUserBorne] = useState(false);

  const formFields = [
    {
      name: 'voucher_type',
      label: 'Voucher Type',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'code',
      label: 'Voucher Code',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'from',
      label: 'Effective Date',
      placeholder: 'Please select',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'to',
      label: 'Expiry Date',
      placeholder: 'Please select',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'discount_amount',
      label: 'Voucher Value',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'discount_type',
      label: 'Voucher Value Type',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'min_spend_amount',
      label: 'Minimum Spend Amount',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'quantity',
      label: 'Quantity',
      placeholder: 'Please state',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true,
      tips: "Enter 0 if it's for voucher import"
    },
    {
      name: 'is_user_borne',
      label: 'User-Borne Voucher',
      placeholder: 'Please state',
      type: 'switch',
      twocol: false,
      colWidth: '0 1 100%',
      static: true
    },
    {
      name: 'user_type',
      label: 'User Type',
      hidden: !isUserBorne,
      placeholder: 'Please select',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true,
      class: 'SentanceCase'
    },
    {
      name: 'no_of_usage',
      label: 'No. Of Usage',
      hidden: !isUserBorne,
      placeholder: 'Please select',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true,
      class: 'SentanceCase'
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      // setValue('code', data.code);
      setValue('code', data.code);
      setValue('from', data.from);
      setValue('to', data.to);
      setValue('status', data.status);
      setValue('voucher_type', `${data.voucher_type_code} (${data.voucher_type_desc})`);
      setValue('discount_amount', data.discount_amount);
      setValue('discount_type', data.discount_type);
      setValue('quantity', data.quantity);
      setValue('min_spend_amount', data.min_spend_amount);
      setValue('is_user_borne', data.is_user_borne === "1");
      setValue('no_of_usage', data?.no_of_uses ? meta?.no_of_usages?.find(x => x.value === data?.no_of_uses)?.label : '');
      setValue('user_type', data?.user_type ? meta?.user_types?.find(x => x.value === data?.user_type)?.label : '');
      setIsUserBorne(data.is_user_borne === "1");
    }
  }, [data]);

  return (
    <Form layout='vertical'>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
      </Row>
    </Form>
  )
}