import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Card } from 'antd';
import { InputCheckbox, SelectField, DateField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import DateRangeDropdown from 'Modules/Application/components/DateRangeDropdown';

export default (props) => {

  const { control, handleSubmit, setValue, getValues } = useForm();
  const [checkedCount, setCheckedCount] = useState(0);

  useEffect(() => {
    if (props) {
      if (!getValues().start_date) {
        setValue('start_date', dayjs());
        setValue('dateRange', { label: 'Today', value: 'today' });
      }
      if (!getValues().end_date) {
        setValue('end_date', dayjs());
      }
    }
  }, [props])

  const onSubmit = (val) => {
    val["checkedCount"] = checkedCount;
    props.onSearch(val);
  }

  const clearProps = () => {
    setValue("outlets", "");
    setValue("statistics", "");
    setValue("tender", "");
    setValue("discount", "");
    setValue("tax", "");
    setValue("order_type", "");
    setValue("cash_management", "");
    setValue("session", "");
    setValue("voided_sales", "");
    setValue("category", "");
    setValue("category_2", "");
    setValue("category_3", "");
    setValue("brand", "");
    setValue("brand_group", "");
    setValue("season", "");
    setValue("class", "");
    setValue("department", "");
    setValue("sales_category", "");
    setValue("sales_brand", "");
    setValue("sales_class", "");
    setCheckedCount(0);
    props.onSearch({});
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <SelectField
            fieldname='outlets'
            label=''
            class='m-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Outlet', isMulti: true }}
            selectOption={props.field1}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='non_sales'
            label=''
            class='mb-0 w-100 default-select'
            initValue={{ label: "Non Sales: All", value: "all" }}
            control={control}
            selectOption={[{
              label: "Non Sales: All",
              value: "all"
            }, {
              label: "Non Sales: No",
              value: "no"
            }, {
              label: "Non Sales: Yes",
              value: "yes"
            }]}
            iProps={{ placeholder: 'Is Non Sales' }}
          />
        </Col>
        <DateRangeDropdown control={control} onSetValue={setValue} placeholderValue='Business Date' isRequired={false} />
      </Row>
      <Row className='w-100 mt-1' gutter={[10, 10]}>
        <Card bordered={false} className='w-100 shadow-card'>
          <Row gutter={[10, 10]}>
            {/* those with d-none = temporary hide */}
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`statistics`}
                label="Statistics"
                class="mb-0 wauto"
                control={control}
                initValue={false}
                onChange={(e) => {
                  let temp = checkedCount;
                  if(e) {
                    temp += 5
                  }else{
                    temp -= 5
                  }
                  setCheckedCount(temp);
                }}
              />
            </Col>
            <Col span={12}>
              <InputCheckbox
                fieldname={`tender`}
                label="Tender"
                class="mb-0 wauto"
                control={control}
                initValue={false}
                onChange={(e) => {
                  let temp = checkedCount;
                  if (e) {
                    temp += 7
                  } else {
                    temp -= 7
                  }
                  setCheckedCount(temp);
                }}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`discount`}
                label="Discount"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`tax`}
                label="Tax"
                class="mb-0 wauto"
                control={control}
                initValue={false}
                onChange={(e) => {
                  let temp = checkedCount;
                  if (e) {
                    temp += 1
                  } else {
                    temp -= 1
                  }
                  setCheckedCount(temp);
                }}
              />
            </Col>
            <Col span={12}>
              <InputCheckbox
                fieldname={`order_type`}
                label="Order Type"
                class="mb-0 wauto"
                control={control}
                initValue={false}
                onChange={(e) => {
                  let temp = checkedCount;
                  if (e) {
                    temp += 9
                  } else {
                    temp -= 9
                  }
                  setCheckedCount(temp);
                }}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`cash_management`}
                label="Cash Management"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`session`}
                label="Session"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`voided_sales`}
                label="Voided Sales"
                class="mb-0 wauto"
                control={control}
                initValue={false}
                onChange={(e) => {
                  let temp = checkedCount;
                  if (e) {
                    temp += 2
                  } else {
                    temp -= 2
                  }
                  setCheckedCount(temp);
                }}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`category`}
                label="Category"
                class="mb-0 wauto"
                control={control}
                initValue={false}
                onChange={(e) => {
                  let temp = checkedCount;
                  if (e) {
                    temp += 42
                  } else {
                    temp -= 42
                  }
                  setCheckedCount(temp);
                }}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`category_2`}
                label="Category 2"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`category_3`}
                label="Category 3"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`brand`}
                label="Brand"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`brand_group`}
                label="Brand Group"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`season`}
                label="Season"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`class`}
                label="Class"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`department`}
                label="Department"
                class="mb-0 wauto"
                control={control}
                initValue={false}
                onChange={(e) => {
                  let temp = checkedCount;
                  if (e) {
                    temp += 6
                  } else {
                    temp -= 6
                  }
                  setCheckedCount(temp);
                }}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`sales_category`}
                label="Sales Category"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`sales_brand`}
                label="Sales Brand"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
            <Col span={4} className='d-none'>
              <InputCheckbox
                fieldname={`sales_class`}
                label="Sales Class"
                class="mb-0 wauto"
                control={control}
                initValue={false}
              />
            </Col>
          </Row>
        </Card>
      </Row>
      <Row className='w-100 mt-1' gutter={[10, 10]} justify={'end'}>
        <Col flex="70px">
          <Button className='w-100' danger size='large' type='primary' htmlType='button' onClick={() => clearProps()}>Clear</Button>
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>Search</Button>
        </Col>
      </Row>
    </Form>
  )
}