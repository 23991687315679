import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { getVoucherTypeDetail } from "../../ducks/actions";
import { editVoucherType } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { control, handleSubmit, formState: { errors }, setValue } = useForm();
  const data = useSelector((state) => state.pos.voucherType);
  const meta = useSelector((state) => state.pos.vtOption);
  const editable = data?.status === "pending" && allowed([AllRoles.POS.VOUCHER_TYPE], 'write')

  useEffect(() => {
    dispatch(getVoucherTypeDetail(id, props.setLoading));
  }, []);

  useEffect(() => {
    if(data && 'id' in data) {
      setValue('code', data.code);
      setValue('desc', data.desc);
      setValue('apply_method', data.apply_method ? {label: data.apply_method, value: data.apply_method} : '');
      setValue('apply_reference', data.apply_reference);
      setValue('value', data.value);
      setValue('value_type', data.value_type ? { label: data.value_type, value: data.value_type } : '');
      setValue('counter_reference', data.counter_reference);
      setValue('counter_digit_number', data.counter_digit_number);
    }
  }, [data])

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      apply_method: val.apply_method?.value,
      value_type: val?.value_type?.value,
      voucher_type_id: id
    }
    await editVoucherType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Voucher Type updated successfully!");
        setTimeout(() => navigate("/pos/voucher-type"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>Edit Voucher Type - {data?.code}</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                {
                  editable &&
                  <Button size="large" htmlType='submit' className='green-btn'>Save</Button>
                }
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/pos/voucher-type')}>{'<'} Back</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <InputForm
          control={control}
          errors={errors}
          meta={meta}
          editable={editable}
        />
      </Row>
    </Form>
  )
}