import React, { useEffect, useState, Fragment } from 'react';
import { Popconfirm, Form, Row, Col, Space, Typography, Spin, Table, Button, Collapse, message } from 'antd';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { SelectField, InputField, SwitchField } from 'Atoms/FormElement';
import ActionButton from 'Molecules/ActionButton';
import { addProductPart, editProductPart, removeProductPart, bulkRemoveProductParts, updateProductPartsOrder, duplicateProductPart } from '../../../../../../ducks/services'
import DndListCard from 'Molecules/DndListCard';
import FormGroup from 'Molecules/FormGroup';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { 
    id, bomId, item, reloadApi, index, onParentEdit, onParentRemove, meta, isCombo, allowedEdit, allowedDelete,
    partGroups, isParentSemi
  } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const { control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rec, setRecord] = useState();
  const [visible, setVisible] = useState(false);
  const [duplicateVisible, setDuplicateVisible] = useState(false);
  const [load, setLoading] = useState(false);
  const [hasDirty, setHasDirty] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSemiProduct, setIsSemiProduct] = useState(false);
  const [isNewGroup, setIsNewGroup] = useState(false);

  const formFields = [
    {
      type: 'switch',
      name: 'new_group',
      label: 'Create New Group?',
      req: false,
      twocol: false,
      colWidth: '0 1 100%',
      alignEnd: false, // move form to top
      labelPosition: 'top',
      static: !allowedEdit,
      onChange: (e) => {
        setIsNewGroup(e);
      }
    },
    {
      name: 'part_group',
      label: 'Group',
      req: !isNewGroup,
      placeholder: 'Please select',
      type: 'select',
      options: partGroups?.filter(x => x.id !== item.id)?.map(x => ({ label: `Group No.: ${x.position} - ${x.name}`, value: x.id })),
      twocol: false,
      colWidth: '0 1 100%',
      class: 'default-select',
      reqmessage: 'Group is Required',
      hidden: isNewGroup
    },
    {
      name: 'position',
      label: 'Poisition',
      req: isNewGroup,
      placeholder: 'Poisition',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Poisition is Required',
      hidden: !isNewGroup
    },
    {
      name: 'name',
      label: 'Name',
      req: isNewGroup,
      placeholder: 'Name..',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: 'Name is Required',
      hidden: !isNewGroup
    }
  ]

  const tableColumns = [
    {
      title: 'Child Item',
      dataIndex: 'product_code',
      key: 'product_code',
      className: 'enable-click',
      render: (text, record) => {
        let prod = meta?.products?.find(x => x.id === record.product_id);
        const preferredName = record?.preferred_name ? record?.preferred_name : `${text} ${record.product_name}`
        return (
          <span>
            {preferredName} {prod?.remark ? "(" + prod.remark + ")" : ""}
          </span> 
        )
      }
    },
    // {
    //   title: 'Combo Short Description',
    //   dataIndex: 'product_name',
    //   key: 'product_name',
    // },
    // {
    //   title: 'Combo Selling Price Mode',
    //   dataIndex: 'price_mode',
    //   key: 'price_mode',
    //   className: 'enable-click',
    //   hidden: !isCombo
    // },
    {
      title: 'Yield Percentage %',
      dataIndex: 'yield_perc',
      key: 'yield_perc',
      className: 'enable-click',
      hidden: isCombo,
      align: 'right',
      render: (text, record) => record?.is_semi_product === "1" ? "-" : text
    },
    {
      title: 'Recipe Rate',
      dataIndex: 'recipe_rate',
      key: 'recipe_rate',
      className: 'enable-click',
      hidden: isCombo,
      align: 'right',
      render: (text, record) => record?.is_semi_product === "1" ? "-" : text
    },
    {
      title: 'Item Quantity',
      dataIndex: 'qty',
      className: 'enable-click',
      key: 'qty',
      align: 'right'
    },
    {
      title: 'Combo Selling Price',
      dataIndex: 'price',
      key: 'price',
      hidden: !isCombo,
      className: 'enable-click',
      align: 'right'
    },
    {
      title: 'UOM',
      dataIndex: 'uom_code',
      key: 'uom_code',
      className: 'enable-click',
      hidden: isCombo,
      render: (text, record) => record?.is_semi_product === "1" ? "-" : text
    },
    {
      title: 'Is Ingredient?',
      dataIndex: 'is_ingredient',
      key: 'is_ingredient',
      className: 'enable-click',
      hidden: isCombo || isParentSemi,
      render: (text, record) => record?.is_semi_product === "1" ? text === "1" ? "Yes" : "No" : "-"
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (text, record) =>
        <ActionButton
          title="Child Item"
          btnAction1={() => { 
            setVisible(true);
            setRecord(record);
            setValue('group', record.part_group_position);
            setValue('position', record.position);
            let prod = meta?.products?.find(x => x.id === record.product_id);
            setValue('product_code', { label: `${record.product_code} ${record.product_name} ${prod?.remark ? "(" + prod?.remark + ")" : ""}`, value: record.product_id });
            setValue('quantity', record.qty);
            setValue('price_mode', { label: record.price_mode, value: record.price_mode });
            setValue('uom', { label: record.uom_code, value: record.uom_id });
            setValue('price', record.price);
            setValue('recipe_qty', record.recipe_rate);
            setValue('yield_perc', record.yield_perc);
            setValue('preferred_name', record.preferred_name);
            setValue('is_ingredient', record.is_ingredient === "1");
            setSelectedItem(record.product_id);
            setIsSemiProduct(record.is_semi_product === '1');
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  useEffect(() => {
    // if(item && 'parts' in item) {
      setData(item?.parts);
    // }
  }, [item]);

  const onFinish = async (val) => {
    setLoading(true);
    const payload = {
      bom_id: bomId,
      part_group_id: item?.id,
      position: rec?.id ? rec?.position : data?.length + 1, // should only be sending for creation
      product_id: val?.product_code?.value,
      qty: val?.quantity,
      is_ingredient: isSemiProduct ? val?.is_ingredient ? "1" : "0" : "0",
      // price_mode: val?.price_mode?.value,
      price: val?.price,
      uom_id: val?.uom?.value,
      yield_perc: val?.yield_perc,
      recipe_rate: val?.recipe_qty,
      preferred_name: val?.preferred_name,
      part_id: rec?.id,
      parent_id: id
    }

    rec?.id ?
      await editProductPart(payload).then(res => {
        setLoading(false);
        if (res.data.code === 200) {
          message.success("Child Item updated successfully.");
          setVisible(false);
          setRecord();
          setValue('group', '');
          setValue('position', '');
          setValue('product_code', '');
          setValue('quantity', '');
          setValue('price_mode', '');
          setValue('price', '');
          setSelectedItem();
          setIsSemiProduct(false);
          setTimeout(() => reloadApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoading(false);
        message.error(e.message ?? "Something went wrong");
      }) :
      await addProductPart(payload).then(res => {
        setLoading(false);
        if (res.data.code === 200) {
          message.success("Child Item added successfully.");
          setVisible(false);
          setValue('group', '');
          setValue('position', '');
          setValue('product_code', '');
          setValue('quantity', '');
          setValue('price_mode', '');
          setValue('price', '');
          setSelectedItem();
          setIsSemiProduct(false);
          setTimeout(() => reloadApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        setLoading(false);
        message.error(e.message ?? "Something went wrong");
      });
  };

  const onFinishDuplicate = async (val) => {
    setLoading(true);
    const payload = {
      selected_part_group_id: isNewGroup ? "0" : val?.part_group?.value,
      name: val?.name,
      position: val?.position,
      parts: JSON.stringify(selectedRowKeys),
      part_group_id: item?.id,
      product_id: id,
      bom_id: bomId,
    }

    await duplicateProductPart(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success(`Child Items has been duplicated to ${isNewGroup ? "new " : ""}group successfully.`);
        setDuplicateVisible(false);
        rowSelection.onChange([]);
        setIsNewGroup(false);
        setValue2('new_group', false);
        setValue2('position', '');
        setValue2('name', '');
        setValue2('part_group', '');
        setTimeout(() => reloadApi(), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onUpdateList = async (datalist) => {
    props.setLoading(true);

    const payload = {
      product_id: id,
      parts: JSON.stringify(datalist?.map((d, i) => ({id: d.id, position: i + 1})))
    }
    await updateProductPartsOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Product Group Order updated successfully.");
        setVisible(false);
        setTimeout(() => reloadApi(), 500);
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const onRemove = async (ppId) => {
    props.setLoading(true);

    const payload = {
      product_id: id,
      bom_id: bomId,
      part_id: ppId,
      status_event: 'remove'
    }

    await removeProductPart(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success("Child Item has been removed successfully!");
        setTimeout(() => reloadApi(), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  };

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      parts: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveProductParts(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success("Child Items has been removed successfully!");
        setTimeout(() => reloadApi(), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? "Something went wrong");
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {rec ? 'Edit' : 'Add'} Child Item
          </Title>
          <Row gutter={[10,10]}>
            <Col span={12}>
              <InputField
                isRequired={visible}
                fieldname='group'
                label='Group No.'
                class='mb-0 w-100'
                initValue={item?.position}
                control={control}
                iProps={{ placeholder: 'Please select', disabled: true }}
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={visible}
                fieldname='position'
                label='Sort Sequence'
                class='mb-0 w-100'
                initValue={rec ? rec?.position : item?.parts?.length + 1}
                control={control}
                iProps={{ placeholder: 'Please select', type: 'number', disabled: true }}
                // rules={{
                //   required: visible ? 'Sort Sequence is required' : false,
                // }}
                validate={errors.position && 'error'}
                validMessage={errors.position && errors.position.message}
              />
            </Col>
            <Col span={isCombo ? 12 : 24}>
              <SelectField
                isRequired={visible}
                fieldname='product_code'
                label='Child Item'
                class='mb-0 w-100 default-select'
                initValue={rec ? { label: rec?.product_code, value: rec?.product_id } : ''}
                control={control}
                iProps={{ placeholder: 'Please select', isDisabled: !allowedEdit }}
                selectOption={meta?.products?.map(x => ({ ...x, label: `${x.code} ${x.name} ${x.remark ? "(" + x.remark + ")" : ""}`, value: x.id  }))}
                onChange={(e) => {
                  if(!isCombo) {
                    setSelectedItem(e.value);
                    let baseUom = e.product_uoms?.find(x => x.is_base_uom === "1");
                    setValue('uom', baseUom ? { label: baseUom?.code, value: baseUom?.id } : '');
                  }
                  if(e.is_semi_product === "1") {
                    setIsSemiProduct(true)
                    setValue("is_ingredient", true);
                  } else {
                    setIsSemiProduct(false)
                    setValue("is_ingredient", false);
                  }
                }}
                rules={{ required: visible ? 'Required' : false }}
                validate={errors.product_code && 'error'}
                validMessage={errors.product_code && errors.product_code.message}
              />
            </Col>
            {isCombo && <Col span={12}>
              <InputField
                isRequired={false}
                fieldname='preferred_name'
                label='Preferred Name'
                class='mb-0 w-100'
                initValue={rec?.preferred_name}
                control={control}
                validate={errors.preferred_name && 'error'}
                validMessage={errors.preferred_name && errors.preferred_name.message}
              />
              <span className='c-gray text-bold'>This name will overwrite the existing product name</span>
            </Col>}
            {
              (isCombo || isSemiProduct) &&
              <Col span={12}>
                <InputField
                  isRequired={visible}
                  fieldname='quantity'
                  label='Item Quantity'
                  class='mb-0 w-100'
                  initValue={rec?.qty}
                  control={control}
                  iProps={{ placeholder: 'Please select', type: 'number', disabled: !allowedEdit, step: "any" }}
                  rules={{
                    required: visible ? 'Item Quantity is required' : false,
                  }}
                  validate={errors.quantity && 'error'}
                  validMessage={errors.quantity && errors.quantity.message}
                />
              </Col>
            }
            {
              isCombo ?
              <>
                  {/* <Col span={12}>
                    <SelectField
                      isRequired={visible && isCombo}
                      fieldname='price_mode'
                      label='Combo Selling Price Mode'
                      class={`mb-0 w-100 default-select`}
                      initValue={rec?.price_mode ? { label: rec?.price_mode, value: rec?.price_mode } : ''}
                      control={control}
                      iProps={{ placeholder: 'Please select' }}
                      selectOption={meta?.price_modes?.map(x => ({ label: x, value: x }))}
                      rules={{ required: visible && isCombo ? 'Required' : false }}
                      validate={errors.price_mode && 'error'}
                      validMessage={errors.price_mode && errors.price_mode.message}
                    />
                  </Col> */}
                  <Col span={12}>
                    <InputField
                      isRequired={visible && isCombo}
                      fieldname='price'
                      label='Combo Selling Price'
                      class='mb-0 w-100'
                      initValue={rec?.qty}
                      control={control}
                      iProps={{ placeholder: 'Please select', disabled: !allowedEdit }}
                      rules={{
                        required: visible && isCombo ? 'Selling Price is required' : false,
                      }}
                      validate={errors.price && 'error'}
                      validMessage={errors.price && errors.price.message}
                    />
                  </Col>
              </>
              :
              <>
                {isSemiProduct ?
                  <Col span={12}>
                    <SwitchField
                      labelPosition={'top'}
                      fieldname={"is_ingredient"}
                      label={"Is Ingredient?"}
                      control={control}
                      iProps={{ size: 'large' }}
                      initValue={''}
                    />
                  </Col>
                  :
                  <>
                    <Col span={24}>
                      <InputField
                        isRequired={visible}
                        fieldname='yield_perc'
                        label='Yield Percentage'
                        class='mb-0 w-100'
                        initValue={rec?.yield_perc}
                        control={control}
                        iProps={{ placeholder: 'Please enter', suffix: "%", disabled: !allowedEdit }}
                        rules={{
                          required: visible ? 'Yield Percentage is required' : false,
                        }}
                        validate={errors.yield_perc && 'error'}
                        validMessage={errors.yield_perc && errors.yield_perc.message}
                        onChange={(e) => {
                          let a = parseInt(e.target.value);
                          let b = parseInt(getValues('recipe_qty'))
                          setValue('quantity', (b / (a / 100)).toFixed(2))
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <InputField
                        fieldname='recipe_qty'
                        label='Recipe Rate'
                        class='mb-0 w-100'
                        initValue={rec?.recipe_rate}
                        control={control}
                        iProps={{ placeholder: 'Recipe Rate' }}
                        rules={{
                          pattern: {
                            value: /^\d*\.?\d+$/,
                            message: 'Only numbers or decimals are allowed.',
                          },
                        }}
                        onChange={(e) => {
                          let a = parseFloat(e.target.value);
                          let b = parseInt(getValues('yield_perc'))
                          setValue('quantity', (a / (b / 100)).toFixed(2))
                        }}
                        validate={errors.recipe_qty && 'error'}
                        validMessage={errors.recipe_qty && errors.recipe_qty.message}
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        isRequired={visible}
                        fieldname='quantity'
                        label='Item Quantity'
                        class='mb-0 w-100'
                        initValue={rec?.qty}
                        control={control}
                        iProps={{ placeholder: 'Auto Computed', type: 'number', disabled: true }}
                      />
                    </Col>
                    <Col span={12}>
                      <SelectField
                        isRequired={visible && !isCombo}
                        fieldname='uom'
                        label='Unit of Measurement (UOM)'
                        class={`mb-0 w-100 default-select`}
                        initValue={rec?.uom_id ? { label: rec?.uom_code, value: rec?.uom_id } : ''}
                        control={control}
                        iProps={{ placeholder: 'Please select', isDisabled: !allowedEdit }}
                        selectOption={meta?.products?.find(item => item.id === selectedItem)?.product_uoms.map(item => ({ label: item.code, value: item.id }))}
                        rules={{ required: visible && !isCombo ? 'Required' : false }}
                        validate={errors.uom && 'error'}
                        validMessage={errors.uom && errors.uom.message}
                      />
                    </Col>
                  </>
                }
              </>
            }
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('group', '');
              setValue('position', '');
              setValue('product_code', '');
              setValue('quantity', '');
              setValue('price_mode', '');
              setValue('price', '');
              setValue('uom', '');
              setValue('recipe_qty', '');
              setSelectedItem();
              rec && setRecord();
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 1000,
    onCancel: () => {
      setVisible(false);
      setValue('group', '');
      setValue('position', '');
      setValue('product_code', '');
      setValue('quantity', '');
      setValue('price_mode', '');
      setValue('price', '');
      setValue('uom', '');
      setValue('recipe_qty', '');
      setRecord();
      setSelectedItem();
    },
  };

  const popup2 = {
    closable: false,
    visibility: duplicateVisible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit2(onFinishDuplicate)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            Duplicate To Group
          </Title>
          <Row gutter={[10,10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control2} errors={errors2} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setDuplicateVisible(false);
              setValue2('new_group', false);
              setValue2('part_group', '');
              setValue2('position', '');
              setValue2('name', '');
              setIsNewGroup(false);
            }}>Cancel</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>Confirm</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 1000,
    onCancel: () => {
      setDuplicateVisible(false);
      setValue2('new_group', '');
      setValue2('part_group', '');
      setValue2('position', '');
      setValue2('name', '');
      setIsNewGroup(false);
    },
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const partGroupHeader = (item) => {
    return (
      <Row gutter={20} justify={'space-between'} align={'middle'}>
        <Col flex="auto"><Title level={5} className="m-0 c-primary">Group No.: {item.position} - {item.name}</Title></Col>
        <Col flex="300px">
          <Space size={10}>
            {
              selectedRowKeys?.length > 0 && allowedEdit &&
              <>
                <Button
                  size="large"
                  type={'primary'}
                  className='b-success'
                  onClick={(e) => {
                    e.stopPropagation();
                    setDuplicateVisible(true);
                  }}
                >
                  Duplicate
                </Button>
                <Popconfirm
                  title={"Are you sure to remove selected Part(s)"}
                  onConfirm={onBulkRemove}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    size="large"
                    type={'primary'}
                    danger
                    onClick={(e) => { e.stopPropagation(); }}
                  >
                    Bulk Remove
                  </Button>
                </Popconfirm>
              </>
            }
            {
              hasDirty && allowedEdit &&
              <Button type='primary' htmlType='button' className='b-success attendance-success' onClick={(e) => {e.stopPropagation(); onUpdateList(data)}}>
                Update Listing
              </Button>
            }
            {
              allowedEdit &&
              <>
                <Button type='link' onClick={(e) => {
                  e.stopPropagation(); setVisible(true); setRecord();
                  setSelectedItem();
                  setValue('group', item.position);
                  setValue('position', item?.parts?.length + 1);
                  setValue('product_code', '');
                  setValue('quantity', '');
                  setValue('price_mode', '');
                  setValue('price', '');
                  setValue('uom', '');
                  setValue('recipe_qty', '');
                  setValue('yield_perc', 100);
                  setIsSemiProduct(false);
                }}>
                  + Add Child Item
                </Button>
                <Button type='link' onClick={(e) => { e.stopPropagation(); onParentEdit(item) }}>
                  Edit
                </Button>
              </>
            }
            {
              allowedDelete &&
              <Button type='link' danger onClick={(e) => { e.stopPropagation(); onParentRemove(item.id) }}>
                Remove
              </Button>
            }
          </Space>
        </Col>
      </Row>
    );
  }

  const items = [
    {
      label: partGroupHeader(item),
      key: `${index + 1}`,
      children: <DndListCard
        setHasDirty={setHasDirty}
        setDataList={setData}
        list={data}
        ListData={data.map(x => ({ ...x, key: x.id }))}
        ListCol={tableColumns?.filter(x => !x.hidden)}
        showHeader={data.length > 0}
        pagination={false}
        rowSelection={rowSelection}
        className='partlist'
      />
    }
  ];

  return (
    <>
      <Col span={24}>
        {/* expandIconPosition={'right'} */}
        <Collapse defaultActiveKey={['1']} items={items} accordion={true} className="clickRow" bordered={false} />
      </Col>
      <Popup {...popup} />
      <Popup {...popup2} />
    </>
  );
}